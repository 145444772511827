export default {
    "language": "Português",
    // 导航
    "zdy": {
        "tjsq": "enviar inscrição",
        "czsl": "Quantidade de recarga",
        "enterczsl": "Insira o valor da recarga",
        "qbdz": "endereço da carteira",
        "enterqbdz": "Por favor insira o endereço da carteira",
        "sftjmcj": "Se deve enviar o preço de venda do pedido",
        "sftjmrj": "Se deve enviar o preço de compra do pedido",
        "proloss": "Obtenha lucro e pare a perda",
    },
    "header": {
        "home": "primeira página",
        "markets": "Citações",
        "fiat": "Negociação de moeda fiduciária",
        "c2c": "Transação C2C",
        "exchange": "Negociação de criptomoeda",
        "lever": "negociação de contrato",
        "micro": "negociação de segundo contrato",
        "myshop": "minha loja",
        "assets": "meus bens",
        "help": "Centro de ajuda",
        "login": "Conecte-se",
        "sign": "registro",
        "accountSet": "configurações de Conta",
        "tradeLog": "Log de transações",
        "receiveSet": "Configurações de pagamento",
        "identify": "Autenticação",
        "jchange": "Câmbio monetário",
        "logout": "sair",
        "themedark": "Modo noturno",
        "themeday": "modo diurno",
    },
    "frame": {
        "a": "Você tem um novo status de pedido de retirada, clique para visualizá-lo",
        "b": "Cancelar",
        "c": "Claro",
        "con": "Caros usuários, pois há muitos membros negociando no centro comercial. ",
    },
    // 登录注册
    "lg": {
        "login": "Conecte-se",
        "register": "registro",
    },
    // 首页
    "home": {
        "vol": "Quantidade 24H",
        "c01": "A plataforma líder mundial de negociação de ativos digitais",
        "c02": "Fornecer serviços seguros e confiáveis ​​de negociação e gerenciamento de ativos digitais para milhões de usuários em mais de 130 países ao redor do mundo",
        "c03": "Seguro e confiável",
        "c04": "5 anos de experiência em serviços financeiros de ativos digitais",
        "c05": "Arquitetura distribuída profissional e sistema de ataque anti-DDOS",
        "c06": "Layout ecológico global",
        "c07": "Centros de serviços comerciais localizados foram estabelecidos em muitos países",
        "c08": "Crie um ecossistema blockchain que integre múltiplas formas de negócios",
        "c09": "Usuário primeiro",
        "c10": "Estabeleça um mecanismo de compensação avançado",
        "c11": "Estabelecer um fundo de proteção ao investidor",
        "c12": "Negociação de terminal multiplataforma a qualquer hora e em qualquer lugar",
        "c13": "Abrange diversas plataformas, incluindo iOS, Android e Windows, com suporte a funções comerciais completas",
        "sweep": "Digitalize o código para baixar",
        "android": "Android",
        "pinput": "Por favor insira seu e-mail ou número de celular",
        "atrade": "Registre uma conta WealthNest Global Station e comece sua jornada comercial",
        "download": "Baixar aplicativo",
        "ios": "Baixar iOS",
        "androidDownload": "Baixar Android",
        companyoverview1: "Seguro",
        companyoverview2: "5 anos de experiência em serviços financeiros de ativos digitais Arquitetura distribuída profissional e sistema anti-ataque DDoS.",
        companyoverview3: "Confiável",
        companyoverview4: "Rápido"
    },
    // 底部
    "foo": {
        "about": "sobre nós",
        "support": "Suporte ao usuário",
        "contact": "Contate-nos",
        "qq": "Atendimento ao cliente QQ",
        "email": "Email de contato",
    },
    // 行情
    "market": {
        "handicap": "Deficiente",
        "exchange": "moedas",
        "selfmarket": "Opcional",
        "allmarket": "Todas as citações",
        "symbol": "par de negociação",
        "newprice": "Preço mais recente",
        "change": "Aumentar",
        "highprice": "preço mais alto",
        "lowprice": "menor preço",
        "vol": "Quantidade 24H",
    },
    // 公共交易
    "td": {
        "buy": "Comprar",
        "sell": "vender",
        "currency": "Moeda",
        "num": "quantidade",
        "time": "tempo",
        "limit": "limite",
        "price": "preço unitário",
        "method": "Forma de pagamento",
        "total": "montante fixo",
        "inwant": "Por favor insira o desejado",
        "do": "operar",
        "more": "Carregue mais",
        "nomore": "não mais",
        "nodata": "Sem dados",
        "trade": "troca",
        "buynum": "Quantidade de compra",
        "sellout": "vender",
        "buyin": "Comprar",
        "all": "todos",
        "allbuy": "compre tudo",
        "allsell": "vender tudo",
        "buytotal": "Por favor insira o valor total que deseja comprar",
        "selltotal": "Por favor insira o valor total que deseja vender",
        "buyallnum": "Informe a quantidade que deseja comprar",
        "sellallnum": "Por favor insira a quantidade que deseja vender",
        "tradeTotal": "valor total da transação",
        "doceil": "Cancelar automaticamente após segundos",
        "place": "Fazer um pedido",
        "waitpay": "Pagamento Pendente",
        "paycode": "código de pagamento",
        "finished": "concluído",
        "ceiled": "Cancelado",
        "payed": "Já pago",
        "nofinish": "desfeito",
        "buyer": "comprador",
        "seller": "vendedor",
        "callWay": "Informações de contato",
        "placeTime": "hora do pedido",
        "renum": "Número de referência",
        "canceil": "Cancelar",
        "confirm": "confirme",
        "pwd": "Por favor insira a senha da transação",
        "coincode": "Unidade monetária",
        "pcoin": "Selecione a unidade monetária",
        "setTradePwd": "Definir senha de transação?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Registro de pedido",
        "fatdLog": "Registros de transações de moeda legal",
        "shoper": "Comerciante",
        "faLog": "Registros de transações de moeda legal",
        "tdType": "Tipo de transação",
        "odStatus": "Status do pedido",
        "pwaitPay": "Aguarde o pagamento do comprador",
        "odFinish": "Encomenda completa",
        "odCeil": "Pedido cancelado",
        "odPay": "O pagamento foi efetuado, verifique as informações de pagamento com atenção",
        "tdCeil": "cancelar o acordo",
        "pCeil": "Se você já pagou ao vendedor, não cancele a transação.",
        "paySure": "Confirmação de pagamento",
        "pdopay": "Por favor, confirme que você pagou ao vendedor",
        "badClick": "Cliques maliciosos congelarão diretamente a conta",
        "receivePay": "Confirmação de pagamento",
        "payReceive": "Por favor, confirme que o comprador pagou a você",
        "receivePays": "Confirme o pagamento recebido",
        // LegalPay
        "payPlease": "por favor pague",
        "tdPrice": "Preço unitário da transação",
        "tdNum": "Número de transações",
        "payinfo": "informação de pagamento",
        "banknum": "Conta bancária",
        "realName": "nome real",
        "shoperZname": "Filial mercantil",
        "shoperNum": "conta de comerciante",
        "odCeil": "cancelar pedido",
        "imPay": "Eu paguei, clique para confirmar",
        // LegalSeller
        "register_time": "Hora do registro",
        "odtotal": "Ordem de montagem",
        "odmonth": "Encomenda concluída no dia 30",
        "odfinish": "Ordem completa",
        "odrate": "Taxa de realizaçao",
        "submit": "liberar",
        "phone": "celular",
        "mysell": "meu à venda",
        "mybuy": "Minha compra",
        "realAuth": "Verificado",
        "advAuth": "Certificação avançada",
        "linebuy": "compre online",
        "linesell": "à venda on-line",
        "tipPay": "Entre em contato com o comerciante dentro de 24 horas para pagar. Se exceder 24 horas, será automaticamente cancelado.",
        "notlow": "Não posso ir abaixo do mínimo",
        "nothigh": "Não é possível exceder o limite máximo",
        "notnum": "Não pode exceder a quantidade máxima",
        // LegalShopDetail
        "type": "tipo",
        "status": "estado",
        "seeOrder": "verificar pedido",
        "withdraw": "retirar",
        "abnormal": "anormal",
        "lowershelf": "Retirado das prateleiras",
        "protection": "Proteção de direitos em andamento",
        "apply": "Solicite proteção de direitos",
        "psType": "Por favor escolha o tipo",
        "pselect": "por favor escolha",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "cartão do banco",
        "minNum": "Volume mínimo de transação",
        "maxNum": "volume máximo de negociação",
        "wantBuy": "Quero comprar",
        "pnums": "Por favor insira a quantidade",
        "pmin": "Insira o volume mínimo de transação",
        "pmax": "Insira o volume máximo de transações",
        "pnot": "O volume máximo de transações não pode ser inferior ao volume mínimo de transações",
        // MyLegalShops
        "myshops": "minha loja",
        "name": "nome",
        "fiatSub": "Moeda legal",
        "shoper_balance": "Saldo do comerciante",
        "enterShop": "entrar em uma loja",
        // shop_legal_pay_detail
        "payedWait": "O pagamento foi efetuado, aguardando verificação e confirmação",
        "yes": "sim",
        "no": "não",
        "sd": "Bloquear saldo",
        "journal": "Registro de saldo",
        "business": "Comerciante",
        "user": "do utilizador",
        "backdone": "Retirado",
        "busbalance": "Saldo do comerciante",
        "userbalance": "Saldo do usuário",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C publicado por mim",
        "myTrade": "C2C da minha transação",
        "and": "certo",
        "balance": "Equilíbrio",
        "buynum": "Volume de compra",
        "sellnum": "Volume de vendas",
        "bankTransfer": "Transferência bancária",
        "payim": "Deve pagar pessoalmente",
        "pbuyNum": "Por favor insira a quantidade de compra",
        "pprice": "Insira o preço unitário",
        "payway": "Selecione a forma de pagamento",
        "psellNum": "Por favor insira a quantidade de venda",
        "suerePlace": "Confirmar pedido?",
        "detail": "Detalhes",
        "payinfo": "informação de pagamento",
        "Payee": "Beneficiário",
        "total": "montante fixo",
        "name": "Nome",
        "cardnum": "número do cartão",
        "receivePay": "Confirme o pagamento recebido",
        "ceilConfirm": "Confirmar para cancelar a transação?",
        "paySeller": "Confirme que você pagou ao vendedor. Cliques maliciosos terão sua conta congelada.",
        "payBuyer": "Por favor, confirme que você recebeu informações de pagamento do comprador",
        "account": "conta",
        "sellerPay": "Por favor, confirme se o comprador pagou a você?",
        "payattion": "Por favor, pague ao vendedor o mais rápido possível após a confirmação do pagamento. Cliques maliciosos congelarão diretamente a conta.",
        "log": "pedido concluído c2c",
    },
    // 合约
    "lever": {
        "nowentrust": "Ordem atual",
        "hisentrust": "comissão histórica",
        "lvchi": "Posições contratuais",
        "all": "todos",
        "or": "ou",
        "gotrade": "Comece a negociar",
        "tdrecord": "Registro de transação",
        "dealed": "Acordo feito",
        "notdeal": "Nenhuma transação",
        "loading": "carregando...",
        "entotal": "Montante total confiado",
        "canuse": "Disponível",
        "std": "transação de preço de mercado",
        "xtd": "limitar o comércio",
        "pprice": "Por favor insira o preço",
        "phand": "Insira o tamanho do lote de compra",
        "phandsell": "Insira o tamanho do lote de venda",
        "pwd": "senha",
        "ppwd": "Por favor insira a senha da transação",
        "psw": "senha de transação",
        "equal": "igual",
        "times": "vezes",
        "timed": "múltiplo",
        "hand": "mão",
        "hands": "número de etapas",
        "ptimes": "Selecione vários",
        "phands": "Selecione o tamanho do lote",
        "contractVal": "Valor de mercado do contrato",
        "bail": "margem",
        "canBail": "Margem disponível",
        "charge": "Taxa de serviço de transação",
        "domore": "Comprar (operar comprado)",
        "doshort": "Vender (curto)",
        "dmore": "Vá longe",
        "dshort": "curto",
        "sureOd": "Confirmar pedido",
        "noless": "O tamanho do lote não pode ser inferior a",
        "nomore": "O tamanho do lote não pode ser superior",
        "tdnum": "Volume de negócios",
        "risk": "taxa de risco",
        "allloss": "Lucro total e perda de posições",
        "onehouse": "Fechar uma posição com um clique",
        "type": "tipo",
        "entrustPrice": "Preço do pedido",
        "openPrice": "preço de abertura",
        "nowPrice": "preço atual",
        "styPrice": "Preço de lucro",
        "stsPrice": "Preço de parada de perda",
        "openTime": "Tempo de abertura",
        "closeTime": "Horário de encerramento",
        "rate": "taxa de manuseio",
        "nightFee": "Taxa noturna",
        "loss": "Lucros e perdas",
        "setloss": "Definir take-profit e stop loss",
        "expectProfit": "lucro esperado",
        "expectLoss": "perda esperada",
        "allClose": "Fechar todas as posições",
        "moreClose": "Feche apenas pedidos longos",
        "nullClose": "Feche apenas pedidos curtos",
        "sureClose": "Confirmar para fechar a posição?",
        "thanZearo": "O valor definido deve ser maior que 0",
        "listin": "pedido pendente",
        "tdin": "em transação",
        "closein": "Fechamento de posição",
        "closed": "Posição fechada",
        "revoked": "Revogado",
        "revokeOrder": "Tem certeza de que deseja cancelar o pedido?",
        "ping": "Fechar posição",
        "revoke": "Cancelar pedido",
        "sureping": "Tem certeza de fechar a posição?",
        "thanzone": "O valor definido deve ser maior que 0",
    },
    // 币币
    "cuy": {
        "direction": "direção",
        "total": "total",
        "price": "preço",
        "sell": "Vender",
        "buy": "comprar",
        "allStation": "Negociação em todo o site",
        "buyPrice": "preço de compra",
        "buynum": "Volume de compra",
        "sellPrice": "Preço de venda",
        "sellnum": "Volume de vendas",
        "tdPrice": "Volume de transações",
        "or": "ou",
        "tdStart": "Comece a negociar",
        "pbPrice": "Por favor insira o preço de compra",
        "pbNum": "Por favor insira o valor da compra",
        "psPrice": "Por favor insira o preço de venda",
        "psNum": "Por favor insira o valor da venda",
        "fixPrice": "limitar o comércio",
        "Mtrans": "transação de preço de mercado",
        "available": "Disponível",
        "revoke": "Cancelar",
        "loading": "carregando",
        "confirmCancel": "Você tem certeza que deseja cancelar?",
        "confirm": "Claro",
        "cancel": "Cancelar",
        "nomore": "Não há mais dados",
        "evelPrice": "preço médio de transação",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Quer confirmar a transferência?",
        "assets": "ativos",
        "currency_account": "Conta de moeda",
        "lever_account": "Conta de contrato",
        "fince_account": "Conta Fiat",
        "change_account": "conta de transação",
        "micro_account": "conta do segundo contrato",
        "miscroAccount": "conta do segundo contrato",
        "assets_hua": "Transferência de fundos",
        "all_assets": "Total de ativos convertidos",
        "currency": "Moeda",
        "canuse": "Disponível",
        "frezz": "congelar",
        "conversion": "convertido",
        "charging": "Depositar moedas",
        "withdraw": "Retirar moedas",
        "address_bind": "Vincule o endereço de retirada",
        "address_withdraw": "Gerenciamento de endereço de retirada de moedas",
        "record": "Detalhes",
        "tbrecord": "Registro de retirada de moedas",
        "address_charge": "Endereço de depósito",
        "address_width": "Endereço de retirada",
        "copy": "cópia de",
        "ercode": "Código QR",
        "reminder": "Dicas gentis",
        "notcharge": "Por favor, não deposite quaisquer fundos no endereço acima.",
        "ornone": ", caso contrário os ativos não serão recuperados",
        "support": "Os depósitos suportam apenas o método de envio simples. Os depósitos usando outros métodos (enviar todos) não podem ser creditados temporariamente.",
        "minnum": "Valor mínimo de retirada",
        "ratenum": "taxa de manuseio",
        "havenum": "Quantidade de chegada",
        "from": "de",
        "to": "a",
        "transfer": "transferir",
        "tfnum": "Quantidade de transferência",
        "ptfnum": "Por favor insira o mínimo",
        "dtfnum": "O número de transferências",
        "canbalance": "Saldo disponível",
        "sureConfirm": "Confirmar transferência",
        "moneyRecord": "recordes financeiros",
        "pNum": "Por favor insira o valor da transferência",
        "leverUse": "Contas de contrato estão disponíveis",
        "legalUse": "Contas Fiat estão disponíveis",
        "changeUselg": "A conta Coin está disponível",
        "changeUse": "Contas de negociação disponíveis",
        "microUse": "Conta de contrato de segundos disponível",
        "look": "Verificar",
        "chargeRecord": "Registro de depósito",
        "status": "status de rastreamento",
        "noopen": "Esta função ainda não está disponível",
        "enterAdderses": "Por favor, insira o endereço de retirada",
        "enterNum": "Por favor insira o valor do saque",
        "lessMin": "O valor de retirada inserido é menor que o valor mínimo",
        "tips01": "Por favor, não deposite quaisquer outros bens no endereço acima, caso contrário os bens não serão recuperados.",
        "tips02": "Os depósitos suportam apenas o método de envio simples. Os depósitos usando outros métodos (enviar todos) não podem ser creditados temporariamente.",
        "beizhu": "Observação",
        "pbeizhu": "Insira observações",
        "add": "adicionar à",
        "addressList": "lista de endereços",
        "delete": "excluir",
        "norec": "Não há registros",
        "selectCurrency": "Selecione a moeda",
        "chainType": "tipo de corrente",
        "commissionLibrary": "biblioteca de comissão",
    },
    // 账户设置
    "set": {
        "mycode2": "meu código de autorização",
        "copycode2": "Copiar código de autorização",
        "uaccont": "Nível de segurança da sua conta:",
        "strong": "poderoso",
        "middle": "meio",
        "weak": "fraco",
        "min": "Baixo",
        "heigh": "alto",
        "complete": "Preencha mais informações para garantir a segurança da conta",
        "mycode": "meu código de convite",
        "copycode": "Copiar código de convite",
        "bindphone": "Vincular celular",
        "bindemail": "Vincular e-mail",
        "loginpwd": "senha de login",
        "noBind": "Não vinculado",
        "binds": "vinculativo",
        "bind": "Ir para vinculação",
        "binded": "Vinculado",
        "net": "As contas da Internet correm o risco de serem roubadas. É recomendável que você altere sua senha regularmente para proteger a segurança de sua conta.",
        "reset": "Rever",
        "setPsw": "Defina uma senha de transação para garantir a segurança da transação.",
        "goSet": "Vá para as configurações",
        "bindAccount": "Vincular conta de câmbio",
        "duihuan": "Vincular conta para resgatar pontos",
        "copysuccess": "Copiado com sucesso",
        "copyfail": "Falha na cópia",
        "recopy": "Copie novamente",
        "account": "Conta",
        "detail": "Detalhes",
        "val": "Quantia",
        "forgetPsw": "esqueça a senha",
        "code": "Código de verificação",
        "getCode": "obter código de verificação",
        "setPsw": "configurar senha",
        "enterPsw": "Por favor insira a senha",
        "pswFalse": "As senhas inseridas duas vezes são inconsistentes",
        "enterPswagain": "Por favor insira a senha novamente",
        "jsetPsw": "Definir senha de transação",
        "jenterPsw": "Por favor insira a senha da transação",
        "jenterPswagain": "Por favor insira a senha da transação novamente",
        "enterAccount": "Por favor insira o nome de usuário",
        "enterCode": "insira o código de verificação",
        "accountFalse": "O telemóvel ou conta de e-mail que introduziu não cumpre as regras!",
        "second": "segundos",
        "sendCode": "Envie o código de verificação",
        "sendsucc": "Enviado com sucesso",
        "enterPswTwo": "Por favor digite sua senha duas vezes",
        "rest": "Restante",
        "yes": "sim",
        "no": "não",
        "yorn": "Se deve definir uma senha de transação",
        "authenticate": "Por favor, realize a verificação de identidade",
    },
    // 身份认证
    "auth": {
        "identity": "Autenticação",
        "name": "Nome",
        "idcard": "EU IA",
        "upimg": "Faça upload de fotos de identificação, a primeira é a da frente, a segunda é a de trás e a terceira é a foto da frente contendo o documento de identidade.",
        "submit": "enviar",
        "auditing": "sob revisão...",
        "certified": "verificado!",
        "pname": "Por favor digite seu nome",
        "pidcard": "Por favor insira seu número de identificação",
        "pimg": "Por favor, carregue uma foto do seu documento de identidade!",
    },
    // 收款方式
    "seting": {
        "pmethod": "Forma de pagamento",
        "truename": "nome real",
        "opening": "nome do banco",
        "bank": "Número do cartão bancário",
        "alipay": "Conta alipay",
        "wename": "Apelido do WeChat",
        "wechat": "Conta WeChat",
        "pname": "por favor digite seu nome verdadeiro",
        "popen": "Por favor insira o nome do banco onde a conta é aberta",
        "pcard": "Insira o número do cartão bancário",
        "palipay": "Por favor, insira o número da sua conta Alipay",
        "pwname": "Por favor, insira seu apelido do WeChat",
        "pwaccount": "Por favor, insira sua conta WeChat",
        "pimg1": "Código de pagamento WeChat",
        "pimg2": "Código de pagamento Alipay",
    },
    // 帮助中心
    "news": {
        "seeall": "ver tudo",
        "helpcenter": "Central de Anúncios",
        "back": "retornar",
    },
    //登录
    "login": {
        "welcome": "Faça login por favor",
        "phoneLogin": "Faça login com telefone",
        "emailLogin": "Login por e-mail",
        "account": "conta",
        "psw": "senha",
        "login": "Conecte-se",
        "nouser": "Ainda não é usuário WealthNest?",
        "goRegister": "Cadastre-se agora e comece a negociar na plataforma líder mundial de negociação de ativos digitais.",
        "registerFree": "registro gratuito",
        "psw6": "A senha não pode ter menos de seis caracteres",
    },
    "register": {
        "register": "registro",
        "phoneRegister": "Registre seu telefone",
        "emailRegister": "Registro de email",
        "country": "nacionalidade",
        "enterPhone": "Por favor insira o número de telefone",
        "enterEmail": "por favor insira seu e-mail",
        "psw16": "A senha deve ter entre 6 e 16 caracteres",
        "inviteCode": "Código de Convite",
        "sel": "Obrigatório",
        "sel2": "Opcional",
        "read": "eu li e concordo",
        "xieyi": "\"Termo de Acordo do Usuário\"",
        "tips01": "As informações de nacionalidade não podem ser modificadas após o registro, portanto, certifique-se de escolhê-las com sinceridade.",
        "tips02": "Os e-mails de verificação podem ser identificados erroneamente como spam, portanto, verifique-os com atenção.",
        "tips03": "Por favor, guarde seu número de conta WealthNest e senha de login corretamente.",
        "tips04": "Não use a mesma senha de login de outros sites.",
        "emailFalse": "O e-mail que você digitou não está de acordo com as regras",
    },
    // 积分兑换
    "jc": {
        "title": "JC troca plataforma BEAU de ativos digitais",
        "balance": "Equilíbrio",
        "cannum": "Quantidade JC disponível",
        "new": "Preço mais recente",
        "exnum": "Quantidade de troca",
        "pnum": "Por favor insira a quantidade JC",
        "canex": "Quantidade de BEAU que podem ser resgatados",
        "exnow": "Resgatar agora",
        "record": "Registro de troca",
        "exprice": "preço de troca",
        "extime": "Tempo de resgate",
        "not": "Não há pontos suficientes disponíveis",
        "usenum": "Usar quantidade JC",
        "out": "desistir",
        "gotd": "vá negociar",
    },
    "coin": {
        "manage": "gestão financeira",
        "total": "depósito total",
        "bao": "depositar tesouro",
        "mybao": "Meu depósito",
        "profit": "Receita estimada",
        "cuntime": "Tempo de depósito de moedas",
        "endtine": "Data de validade",
    },
    "team": {
        "myteam": "meu time",
        "ztnum": "Número de referências diretas",
        "activenum": "Número de membros ativos da equipe",
        "profit": "renda",
        "back": "Desconto",
        "cblog": "Registro de depósito",
        "bblog": "registro de moeda",
    },
    // k线
    "kline": {
        "text1": "compartilhamento de tempo",
        "text2": "1 minuto",
        "text3": "5 minutos",
        "text4": "15 minutos",
        "text5": "30 minutos",
        "text6": "1 hora",
        "text7": "1 dia",
        "text8": "1 semana",
        "text9": "Janeiro",
    },
    // 秒合约
    "miscro": {
        "trade": "em transação",
        "buyPrice": "preço de compra",
        "finshPrice": "preço final",
        "loss": "Lucros e perdas estimados",
        "times": "Contagem regressiva",
        "mode": "modelo de negociação",
        "num": "Quantidade de posição aberta",
        "rate": "Lucratividade",
        "up": "Comprar",
        "down": "Comprar ou vender",
        "openNum": "Por favor insira a quantidade de abertura",
        "success": "encomendado com sucesso!",
        "c2c": "Conta C2C",
        "complaint": "Sugestões",
        "reply": "Escreva sua dúvida e entraremos em contato o mais breve possível...",
        "complaintList": "Lista de reclamações e sugestões",
        "complaintReply": "responder:",
        "complaintDescription": "Por favor insira uma descrição",
        "resetpwd": "alterar a senha",
        "foundedOn": "Construídas em",
        "commonProblem": "problema comum",
        "statement": "explicar",
        "about": "Sobre WealthNest",
        "everyone": "Todo mundo é um CEO",
        "program": "Programa de parceria global WealthNest",
        "program2": "Programa de parceria",
        "myMine": "Meu meu:",
        "friend": "Meus amigos mineiros:",
        "myRank": "Meu nível:",
        "accumulated": "Comissão cumulativa:",
        "copyLinks": "Copiar link da promoção",
        "moneyVein": "Transformando conexões em dinheiro",
        "example": "Exemplo de comissão",
        "enterQuantity": "Por favor insira a quantidade",
        "contractBalance": "Saldo da conta do contrato",
        "submitRepeatedly": "O envio está em andamento, por favor não envie repetidamente",
        "alipayCode": "Código de pagamento Alipay",
        "wechatCode": "Código de pagamento WeChat",
        "realPrice": "preço em tempo real",
        "currencyExchange": "Resgatar moeda",
        "currencyExchangeIn": "Resgatar moeda",
        "cashableBalance": "Saldo disponível para resgate:",
        "minimumCashable": "Valor mínimo de resgate:",
        "maximumCashable": "Saque máximo:",
        "automaticallys": "Após clicar em Resgatar, ele será concluído automaticamente e não poderá ser devolvido.",
        "match": "intercâmbio",
        "title": "Troca de ativos",
        "holdAssets": "detenção de ativos",
        "dangerousCurrency": "Mantenha moedas de risco",
        "convertibleQuantity": "Quantidade resgatável",
        "currencyExchanges": "Taxa de câmbio monetário:",
        "insuranceCurrency": "Moeda do seguro",
        "insuranceType": "tipo de seguro",
        "contractAsset": "Valor do seguro de ativos do contrato",
        "warehouses": "Número de armazéns de seguros",
        "availableAssets": "Ativos disponíveis:",
        "insuredAssets": "Bens segurados:",
        "insuredAssets1": "Ativos segurados",
        "insuranceAssets": "Ativos de seguros:",
        "purchaseInsurance": "Assinatura de seguro",
        "insuranceClaims": "sinistros de seguro",
        "insuranceCancellation": "Cancelamento de seguro",
        "coinWallet": "Carteira de moedas de seguro",
        "bmbWallet": "Carteira HAP",
        "cumulativeCoins": "Moedas acumuladas:",
        "availableQuantity": "Quantidade Disponível:",
        "rawCurrency": "Registro de moeda",
        "contractInsurance": "contratar seguro",
        "tenThousand": "Dez mil",
        "runningLow": "Saldo insuficiente",
        "purchase": "A quantidade inserida não está de acordo com as regras e seu limite de compra está dentro",
        "reach": "a",
        "between": "entre",
        "onlyEnter": "Você só pode entrar",
        "integersBetween": "número inteiro entre",
        "notReturned": "Ao clicar para subscrever o seguro, considera-se que compreendeu detalhadamente o contrato de seguro e concordou que o depósito do seguro entrará em vigor automaticamente e não poderá ser devolvido.",
        "settled": "Quando a perda dos bens segurados impossibilitar a realização de uma encomenda, serão efectuadas reclamações de seguros",
        "profitable": "vezes, quando o ativo dá lucro",
        "terminated": ", o seguro será automaticamente rescindido.",
        "automatically": "Quando os bens segurados perderem 50%, você deverá solicitar um sinistro, caso contrário não poderá negociar. Quando o lucro for de 100%, o seguro será automaticamente rescindido. O armazém de seguros só poderá pagar duas indenizações. dia. Se ultrapassar duas vezes, a indenização será paga em t+1.",
        "termination": "Os bens segurados só poderão ser resgatados após a rescisão do contrato de seguro. Após a rescisão do contrato, será considerado incumprimento do contrato e a posição do seguro será automaticamente compensada.",
        "just": "apenas",
        "back": "oposto",
        "lowestNumber": "A quantidade mínima é",
        "confirmExchange": "Confirmar resgate?",
        "contractValuation": "Avaliação de ativos de conta de contrato",
        "secondValuation": "Avaliação de ativos da conta do segundo contrato",
        "falshValuation": "Avaliação instantânea de ativos de conta de câmbio",
        "c2cValuation": "Avaliação de ativos de conta C2C",
        "recordWithdrawal": "Registros de depósitos e retiradas",
        "category": "categoria",
        "safetyCenter": "Centro de Segurança",
        "safeText1": "Usado para receber mensagens SMS de verificação ao registrar-se, alterar senhas e configurar segurança",
        "safeText2": "As contas da Internet correm o risco de serem roubadas. É recomendável que você altere sua senha regularmente para proteger a segurança de sua conta.",
        "flashTrading": "Transação instantânea",
        "assetCenter": "Centro de Ativos",
        "promotionCode": "Meu código promocional",
        "loginAgain": "O login expirou, faça login novamente",
        "text10": "Preços em tempo real baseados em HAP",
        "text11": "Código de autorização",
    }
}
