export default {
    "language": "Español",
    // 导航
    "zdy": {
        "tjsq": "presentar la solicitud",
        "czsl": "Cantidad de recarga",
        "enterczsl": "Por favor ingresa el monto de la recarga",
        "qbdz": "dirección de billetera",
        "enterqbdz": "Por favor ingresa la dirección de la billetera",
        "sftjmcj": "Si se debe enviar el precio de venta del pedido",
        "sftjmrj": "Si se debe enviar el precio de compra del pedido",
        "proloss": "Obtenga ganancias y detenga las pérdidas",
    },
    "header": {
        "home": "página delantera",
        "markets": "Citas",
        "fiat": "Comercio de divisas fiduciarias",
        "c2c": "transacción C2C",
        "exchange": "Comercio de criptomonedas",
        "lever": "comercio por contrato",
        "micro": "segundo contrato comercial",
        "myshop": "mi tienda",
        "assets": "mis bienes",
        "help": "Centro de ayuda",
        "login": "Acceso",
        "sign": "registro",
        "accountSet": "Configuraciones de la cuenta",
        "tradeLog": "registro de transacciones",
        "receiveSet": "Configuraciones de pago",
        "identify": "Autenticación",
        "jchange": "Cambio de divisas",
        "logout": "desconectar",
        "themedark": "Modo nocturno",
        "themeday": "modo diurno",
    },
    "frame": {
        "a": "Tiene un nuevo estado de orden de retiro, haga clic para verlo",
        "b": "Cancelar",
        "c": "Seguro",
        "con": "Estimados usuarios, porque hay muchos miembros negociando en el centro comercial. ",
    },
    // 登录注册
    "lg": {
        "login": "Acceso",
        "register": "registro",
    },
    // 首页
    "home": {
        "vol": "cantidad 24H",
        "c01": "La plataforma de comercio de activos digitales líder en el mundo",
        "c02": "Proporcionar servicios de gestión de activos y comercio de activos digitales seguros y confiables a millones de usuarios en más de 130 países de todo el mundo.",
        "c03": "Seguro y confiable",
        "c04": "5 años de experiencia en servicios financieros de activos digitales.",
        "c05": "Arquitectura distribuida profesional y sistema de ataques anti-DDOS",
        "c06": "Diseño ecológico global",
        "c07": "Se han establecido centros de servicios comerciales localizados en muchos países.",
        "c08": "Cree un ecosistema blockchain que integre múltiples formas comerciales",
        "c09": "Usuario primero",
        "c10": "Establecer un mecanismo de compensación avanzado",
        "c11": "Establecer un fondo de protección de inversores",
        "c12": "Comercio de terminales multiplataforma en cualquier momento y en cualquier lugar",
        "c13": "Cubre múltiples plataformas, incluidas iOS, Android y Windows, y admite funciones comerciales completas.",
        "sweep": "Escanear código para descargar",
        "android": "Androide",
        "pinput": "Por favor introduce tu correo electrónico o número de teléfono móvil",
        "atrade": "Registre una cuenta WealthNest Global Station y comience su viaje comercial",
        "download": "Descargar aplicación",
        "ios": "descargar iOS",
        "androidDownload": "descarga de Android",
        companyoverview1: "Seguro",
        companyoverview2: "5 años de experiencia en servicios financieros de activos digitales Arquitectura distribuida profesional y sistema anti ataques DDoS.",
        companyoverview3: "Confiable",
        companyoverview4: "Rápido"
    },
    // 底部
    "foo": {
        "about": "sobre nosotros",
        "support": "Soporte al usuario",
        "contact": "Contáctenos",
        "qq": "QQ de atención al cliente",
        "email": "Email de contacto",
    },
    // 行情
    "market": {
        "handicap": "Desventaja",
        "exchange": "monedas",
        "selfmarket": "Opcional",
        "allmarket": "Todas las citas",
        "symbol": "par comercial",
        "newprice": "Último precio",
        "change": "Aumentar",
        "highprice": "precio más alto",
        "lowprice": "el precio más bajo",
        "vol": "cantidad 24H",
    },
    // 公共交易
    "td": {
        "buy": "Comprar",
        "sell": "vender",
        "currency": "Divisa",
        "num": "cantidad",
        "time": "tiempo",
        "limit": "límite",
        "price": "precio unitario",
        "method": "método de pago",
        "total": "Suma global",
        "inwant": "Por favor ingrese su deseado",
        "do": "funcionar",
        "more": "carga más",
        "nomore": "no más",
        "nodata": "Sin datos",
        "trade": "comercio",
        "buynum": "Cantidad de compra",
        "sellout": "vender",
        "buyin": "Comprar",
        "all": "todo",
        "allbuy": "comprar todo",
        "allsell": "vender todo",
        "buytotal": "Por favor ingresa el monto total que deseas comprar",
        "selltotal": "Por favor ingresa el monto total que deseas vender",
        "buyallnum": "Por favor ingresa la cantidad que deseas comprar",
        "sellallnum": "Por favor ingresa la cantidad que deseas vender",
        "tradeTotal": "monto total de la transacción",
        "doceil": "Cancelar automáticamente después de segundos",
        "place": "hacer un pedido",
        "waitpay": "Pago pendiente",
        "paycode": "codigo de pago",
        "finished": "terminado",
        "ceiled": "Cancelado",
        "payed": "Ya pagado",
        "nofinish": "deshecho",
        "buyer": "comprador",
        "seller": "vendedor",
        "callWay": "Información del contacto",
        "placeTime": "tiempo de la orden",
        "renum": "Numero de referencia",
        "canceil": "Cancelar",
        "confirm": "confirmar",
        "pwd": "Por favor ingrese la contraseña de la transacción",
        "coincode": "Unidad monetaria",
        "pcoin": "Por favor seleccione unidad monetaria",
        "setTradePwd": "¿Establecer contraseña de transacción?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Registro de pedido",
        "fatdLog": "Registros de transacciones en moneda legal",
        "shoper": "Comerciante",
        "faLog": "Registros de transacciones en moneda legal",
        "tdType": "tipo de transacción",
        "odStatus": "Estado del pedido",
        "pwaitPay": "Espere el pago del comprador.",
        "odFinish": "Pedido completado",
        "odCeil": "Orden cancelada",
        "odPay": "El pago se ha realizado, verifique cuidadosamente la información de pago.",
        "tdCeil": "cancelar el trato",
        "pCeil": "Si ya le pagó al vendedor, no cancele la transacción.",
        "paySure": "Confirmación de pago",
        "pdopay": "Confirma que has pagado al vendedor.",
        "badClick": "Los clics maliciosos congelarán directamente la cuenta.",
        "receivePay": "Confirmación de pago",
        "payReceive": "Por favor confirma que el comprador te ha pagado",
        "receivePays": "Confirmar pago recibido",
        // LegalPay
        "payPlease": "por favor pague",
        "tdPrice": "Precio unitario de transacción",
        "tdNum": "Número de transacciones",
        "payinfo": "información de pago",
        "banknum": "Cuenta bancaria",
        "realName": "nombre real",
        "shoperZname": "sucursal mercantil",
        "shoperNum": "cuenta comercial",
        "odCeil": "cancelar orden",
        "imPay": "He pagado, haga clic para confirmar",
        // LegalSeller
        "register_time": "Hora de registro",
        "odtotal": "Orden de montaje",
        "odmonth": "Pedido finalizado el día 30",
        "odfinish": "Orden completa",
        "odrate": "Tasa de finalización",
        "submit": "liberar",
        "phone": "Teléfono móvil",
        "mysell": "mi en venta",
        "mybuy": "Mi compra",
        "realAuth": "Verificado",
        "advAuth": "Certificación avanzada",
        "linebuy": "comprar en linea",
        "linesell": "a la venta en línea",
        "tipPay": "Comuníquese con el comerciante dentro de las 24 horas para pagar. Si excede las 24 horas, se cancelará automáticamente.",
        "notlow": "No puedo bajar del mínimo",
        "nothigh": "No se puede exceder el límite máximo",
        "notnum": "No puede exceder la cantidad máxima",
        // LegalShopDetail
        "type": "tipo",
        "status": "estado",
        "seeOrder": "revisar orden",
        "withdraw": "retirar",
        "abnormal": "anormal",
        "lowershelf": "Eliminado de los estantes",
        "protection": "Protección de derechos en curso",
        "apply": "Solicitar protección de derechos",
        "psType": "Por favor elige el tipo",
        "pselect": "por favor elige",
        "alipay": "alipay",
        "wechat": "WeChat",
        "bank": "tarjeta bancaria",
        "minNum": "Volumen mínimo de transacciones",
        "maxNum": "volumen máximo de operaciones",
        "wantBuy": "Quiero comprar",
        "pnums": "Por favor ingrese la cantidad",
        "pmin": "Por favor ingrese el volumen mínimo de transacción",
        "pmax": "Por favor ingrese el volumen máximo de transacción",
        "pnot": "El volumen máximo de transacciones no puede ser inferior al volumen mínimo de transacciones.",
        // MyLegalShops
        "myshops": "mi tienda",
        "name": "nombre",
        "fiatSub": "moneda de curso legal",
        "shoper_balance": "Saldo mercantil",
        "enterShop": "entrar a una tienda",
        // shop_legal_pay_detail
        "payedWait": "El pago se ha realizado, esperando verificación y confirmación.",
        "yes": "Sí",
        "no": "No",
        "sd": "Bloquear saldo",
        "journal": "Registro de saldo",
        "business": "Comerciante",
        "user": "usuario",
        "backdone": "Retirado",
        "busbalance": "Saldo mercantil",
        "userbalance": "Saldo de usuario",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C publicado por mi",
        "myTrade": "C2C de mi transacción",
        "and": "bien",
        "balance": "Balance",
        "buynum": "Volumen de compra",
        "sellnum": "Volumen de ventas",
        "bankTransfer": "Transferencia bancaria",
        "payim": "Debe pagar en persona",
        "pbuyNum": "Por favor ingrese la cantidad de compra",
        "pprice": "Por favor ingrese el precio unitario",
        "payway": "Por favor seleccione forma de pago",
        "psellNum": "Por favor ingrese la cantidad de venta",
        "suerePlace": "¿Confirmar pedido?",
        "detail": "Detalles",
        "payinfo": "información de pago",
        "Payee": "Tenedor",
        "total": "Suma global",
        "name": "Nombre",
        "cardnum": "número de tarjeta",
        "receivePay": "Confirmar pago recibido",
        "ceilConfirm": "¿Confirmar para cancelar la transacción?",
        "paySeller": "Confirme que ha pagado al vendedor. Los clics maliciosos congelarán su cuenta.",
        "payBuyer": "Confirme que ha recibido la información de pago del comprador.",
        "account": "cuenta",
        "sellerPay": "¿Confirma que el comprador le ha pagado?",
        "payattion": "Pague al vendedor lo antes posible después de que se confirme el pago. Los clics maliciosos congelarán directamente la cuenta.",
        "log": "pedido completado c2c",
    },
    // 合约
    "lever": {
        "nowentrust": "Orden actual",
        "hisentrust": "comisión histórica",
        "lvchi": "Posiciones contractuales",
        "all": "todo",
        "or": "o",
        "gotrade": "Comienza a negociar",
        "tdrecord": "Registro de la transacción",
        "dealed": "Trato hecho",
        "notdeal": "Sin transacción",
        "loading": "cargando...",
        "entotal": "Monto total confiado",
        "canuse": "Disponible",
        "std": "transacción a precio de mercado",
        "xtd": "limitar el comercio",
        "pprice": "Por favor ingrese el precio",
        "phand": "Por favor ingrese el tamaño del lote de compra",
        "phandsell": "Por favor ingrese el tamaño del lote de venta",
        "pwd": "contraseña",
        "ppwd": "Por favor ingrese la contraseña de la transacción",
        "psw": "Clave de transacción",
        "equal": "igual",
        "times": "veces",
        "timed": "múltiple",
        "hand": "mano",
        "hands": "numero de pasos",
        "ptimes": "Por favor seleccione varios",
        "phands": "Por favor seleccione el tamaño del lote",
        "contractVal": "Valor de mercado del contrato",
        "bail": "margen",
        "canBail": "Margen Disponible",
        "charge": "Tarifa de servicio de transacción",
        "domore": "Comprar (ir largo)",
        "doshort": "Vender (corto)",
        "dmore": "Ve largo",
        "dshort": "corto",
        "sureOd": "Confirmar pedido",
        "noless": "El tamaño del lote no puede ser menor que",
        "nomore": "El tamaño del lote no puede ser mayor que",
        "tdnum": "Volumen de comercio",
        "risk": "tasa de riesgo",
        "allloss": "Ganancias y pérdidas totales de posiciones.",
        "onehouse": "Cerrar una posición con un clic",
        "type": "tipo",
        "entrustPrice": "Precio del pedido",
        "openPrice": "precio de apertura",
        "nowPrice": "precio actual",
        "styPrice": "Precio de toma de ganancias",
        "stsPrice": "Precio de parada de pérdidas",
        "openTime": "Hora de apertura",
        "closeTime": "hora de cierre",
        "rate": "tasa de tramitación",
        "nightFee": "Tarifa nocturna",
        "loss": "Ganancia y perdida",
        "setloss": "Establecer toma de ganancias y detener pérdidas",
        "expectProfit": "ganancia esperada",
        "expectLoss": "pérdida esperada",
        "allClose": "Cerrar todas las posiciones",
        "moreClose": "Solo cerrar pedidos largos",
        "nullClose": "Sólo cerrar órdenes cortas",
        "sureClose": "¿Confirmar para cerrar la posición?",
        "thanZearo": "El valor establecido debe ser mayor que 0.",
        "listin": "orden pendiente",
        "tdin": "en transacción",
        "closein": "Cierre de posición",
        "closed": "Posición cerrada",
        "revoked": "Revocado",
        "revokeOrder": "¿Estás seguro de que deseas cancelar el pedido?",
        "ping": "Posición cercana",
        "revoke": "Cancelar orden",
        "sureping": "¿Estás seguro de cerrar la posición?",
        "thanzone": "El valor establecido debe ser mayor que 0.",
    },
    // 币币
    "cuy": {
        "direction": "dirección",
        "total": "total",
        "price": "precio",
        "sell": "Vender",
        "buy": "compra",
        "allStation": "Comercio en todo el sitio",
        "buyPrice": "precio de compra",
        "buynum": "Volumen de compra",
        "sellPrice": "Precio de venta",
        "sellnum": "Volumen de ventas",
        "tdPrice": "Volumen de transacciones",
        "or": "o",
        "tdStart": "Comienza a negociar",
        "pbPrice": "Por favor ingrese el precio de compra",
        "pbNum": "Por favor ingrese el monto de la compra",
        "psPrice": "Por favor ingrese el precio de venta",
        "psNum": "Por favor ingrese el monto de venta",
        "fixPrice": "limitar el comercio",
        "Mtrans": "transacción a precio de mercado",
        "available": "Disponible",
        "revoke": "Cancelar",
        "loading": "cargando",
        "confirmCancel": "¿Estas seguro que quieres cancelar?",
        "confirm": "Seguro",
        "cancel": "Cancelar",
        "nomore": "No más datos",
        "evelPrice": "precio medio de transacción",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "¿Quieres confirmar la transferencia?",
        "assets": "activos",
        "currency_account": "cuenta de monedas",
        "lever_account": "cuenta de contrato",
        "fince_account": "cuenta fiduciaria",
        "change_account": "cuenta de transacciones",
        "micro_account": "segunda cuenta de contrato",
        "miscroAccount": "segunda cuenta de contrato",
        "assets_hua": "Transferencia de fondos",
        "all_assets": "Activos totales convertidos",
        "currency": "Divisa",
        "canuse": "Disponible",
        "frezz": "congelar",
        "conversion": "convertido",
        "charging": "depositar monedas",
        "withdraw": "retirar monedas",
        "address_bind": "Vincular la dirección de retiro",
        "address_withdraw": "Gestión de direcciones de retiro de monedas",
        "record": "Detalles",
        "tbrecord": "Registro de retiro de monedas",
        "address_charge": "Dirección de depósito",
        "address_width": "dirección de retiro",
        "copy": "Copiar",
        "ercode": "Código QR",
        "reminder": "Consejos amables",
        "notcharge": "Por favor no deposite ningún fondo en la dirección anterior.",
        "ornone": ", de lo contrario los bienes no serán recuperados",
        "support": "Los depósitos solo admiten el método de envío simple. Los depósitos que utilizan otros métodos (enviar todo) no se pueden acreditar temporalmente.",
        "minnum": "Monto mínimo de retiro",
        "ratenum": "tasa de tramitación",
        "havenum": "Cantidad de llegada",
        "from": "de",
        "to": "a",
        "transfer": "transferir",
        "tfnum": "Cantidad de transferencia",
        "ptfnum": "Por favor introduce el mínimo",
        "dtfnum": "El número de transferencias",
        "canbalance": "Saldo disponible",
        "sureConfirm": "Confirmar transferencia",
        "moneyRecord": "registros financieros",
        "pNum": "Por favor ingrese el monto de la transferencia",
        "leverUse": "Las cuentas de contrato están disponibles",
        "legalUse": "Las cuentas Fiat están disponibles",
        "changeUselg": "La cuenta de monedas está disponible",
        "changeUse": "Cuentas comerciales disponibles",
        "microUse": "Cuenta de segundo contrato disponible",
        "look": "Controlar",
        "chargeRecord": "Registro de depósito",
        "status": "estado de seguimiento",
        "noopen": "Esta función aún no está disponible",
        "enterAdderses": "Por favor ingrese la dirección de retiro",
        "enterNum": "Por favor ingrese el monto del retiro",
        "lessMin": "El monto de retiro ingresado es menor que el valor mínimo",
        "tips01": "No deposite ningún otro activo en la dirección anterior; de lo contrario, los activos no se recuperarán.",
        "tips02": "Los depósitos solo admiten el método de envío simple. Los depósitos que utilizan otros métodos (enviar todo) no se pueden acreditar temporalmente.",
        "beizhu": "Observación",
        "pbeizhu": "Por favor ingrese comentarios",
        "add": "añadir",
        "addressList": "lista de direcciones",
        "delete": "borrar",
        "norec": "No hay registros",
        "selectCurrency": "Por favor seleccione moneda",
        "chainType": "tipo de cadena",
        "commissionLibrary": "biblioteca de la comisión",
    },
    // 账户设置
    "set": {
        "mycode2": "mi código de autorización",
        "copycode2": "Copiar código de autorización",
        "uaccont": "Nivel de seguridad de su cuenta:",
        "strong": "poderoso",
        "middle": "medio",
        "weak": "débil",
        "min": "Bajo",
        "heigh": "alto",
        "complete": "Complete más información para garantizar la seguridad de la cuenta",
        "mycode": "mi código de invitación",
        "copycode": "Copiar código de invitación",
        "bindphone": "vincular teléfono móvil",
        "bindemail": "Vincular correo electrónico",
        "loginpwd": "contraseña de inicio de sesión",
        "noBind": "No unido",
        "binds": "vinculante",
        "bind": "Ir a encuadernación",
        "binded": "Atado",
        "net": "Las cuentas de Internet corren el riesgo de ser robadas. Se recomienda que cambie su contraseña periódicamente para proteger la seguridad de su cuenta.",
        "reset": "Revisar",
        "setPsw": "Establezca una contraseña de transacción para garantizar la seguridad de la transacción.",
        "goSet": "Ir a la configuración",
        "bindAccount": "Vincular cuenta de cambio",
        "duihuan": "Vincular cuenta para canjear puntos",
        "copysuccess": "Copiado exitosamente",
        "copyfail": "Copia fallida",
        "recopy": "Por favor copie de nuevo",
        "account": "Cuenta",
        "detail": "Detalles",
        "val": "Cantidad",
        "forgetPsw": "olvida la contraseña",
        "code": "Código de verificación",
        "getCode": "obtener código de verificación",
        "setPsw": "configurar la clave",
        "enterPsw": "Por favor, ingrese contraseña",
        "pswFalse": "Las contraseñas ingresadas dos veces son inconsistentes",
        "enterPswagain": "Por favor ingrese la contraseña nuevamente",
        "jsetPsw": "Establecer contraseña de transacción",
        "jenterPsw": "Por favor ingrese la contraseña de la transacción",
        "jenterPswagain": "Por favor ingrese la contraseña de la transacción nuevamente",
        "enterAccount": "Por favor ingrese el nombre de usuario",
        "enterCode": "por favor ingrese el código de verificación",
        "accountFalse": "¡El teléfono móvil o la cuenta de correo electrónico que ingresaste no cumple con las reglas!",
        "second": "segundos",
        "sendCode": "Enviar el código de verificación",
        "sendsucc": "Enviado correctamente",
        "enterPswTwo": "Por favor ingrese su contraseña dos veces",
        "rest": "Restante",
        "yes": "Sí",
        "no": "No",
        "yorn": "Si se debe establecer una contraseña de transacción",
        "authenticate": "Por favor realice la verificación de identidad",
    },
    // 身份认证
    "auth": {
        "identity": "Autenticación",
        "name": "Nombre",
        "idcard": "IDENTIFICACIÓN",
        "upimg": "Cargue fotografías de identificación, la primera es la del frente, la segunda es la de atrás y la tercera es la foto del frente con la identificación.",
        "submit": "entregar",
        "auditing": "bajo revisión...",
        "certified": "verificado!",
        "pname": "Por favor escribe tu nombre",
        "pidcard": "Por favor ingrese su número de identificación",
        "pimg": "¡Sube una foto de tu identificación!",
    },
    // 收款方式
    "seting": {
        "pmethod": "método de pago",
        "truename": "nombre real",
        "opening": "Nombre del banco",
        "bank": "número de tarjeta bancaria",
        "alipay": "cuenta alipay",
        "wename": "Apodo de WeChat",
        "wechat": "cuenta wechat",
        "pname": "por favor ingresa tu nombre real",
        "popen": "Por favor ingrese el nombre del banco donde se abre la cuenta",
        "pcard": "Por favor introduzca el número de tarjeta bancaria",
        "palipay": "Por favor ingrese su número de cuenta Alipay",
        "pwname": "Por favor ingrese su apodo de WeChat",
        "pwaccount": "Por favor ingresa tu cuenta WeChat",
        "pimg1": "código de pago wechat",
        "pimg2": "código de pago alipay",
    },
    // 帮助中心
    "news": {
        "seeall": "ver todo",
        "helpcenter": "Centro de anuncios",
        "back": "devolver",
    },
    //登录
    "login": {
        "welcome": "Ingresa, por favor",
        "phoneLogin": "Iniciar sesión con el teléfono",
        "emailLogin": "Iniciar sesión por correo electrónico",
        "account": "cuenta",
        "psw": "contraseña",
        "login": "Acceso",
        "nouser": "¿Aún no eres usuario de WealthNest?",
        "goRegister": "Regístrese ahora y comience a operar en la plataforma de comercio de activos digitales líder en el mundo.",
        "registerFree": "Registro gratis",
        "psw6": "La contraseña no puede tener menos de seis caracteres.",
    },
    "register": {
        "register": "registro",
        "phoneRegister": "Registra tu teléfono",
        "emailRegister": "registro de email",
        "country": "nacionalidad",
        "enterPhone": "Por favor ingrese el número de teléfono",
        "enterEmail": "por favor ingrese su correo electrónico",
        "psw16": "La contraseña debe tener entre 6 y 16 caracteres.",
        "inviteCode": "Código de invitación",
        "sel": "Requerido",
        "sel2": "Opcional",
        "read": "He leído y estoy de acuerdo",
        "xieyi": "\"Acuerdo del Usuario\"",
        "tips01": "La información de nacionalidad no se puede modificar después del registro, así que asegúrese de elegirla con sinceridad.",
        "tips02": "Los correos electrónicos de verificación pueden identificarse erróneamente como spam, así que revíselos con atención.",
        "tips03": "Mantenga correctamente su número de cuenta WealthNest y su contraseña de inicio de sesión.",
        "tips04": "No utilice la misma contraseña de inicio de sesión que otros sitios web.",
        "emailFalse": "El correo electrónico que ingresaste no cumple con las reglas",
    },
    // 积分兑换
    "jc": {
        "title": "Plataforma BEAU de activos digitales de intercambio JC",
        "balance": "Balance",
        "cannum": "Cantidad JC disponible",
        "new": "Último precio",
        "exnum": "Cantidad de cambio",
        "pnum": "Por favor ingrese la cantidad JC",
        "canex": "Cantidad de BEAU que se puede canjear",
        "exnow": "Canjear ahora",
        "record": "Registro de intercambio",
        "exprice": "precio de cambio",
        "extime": "tiempo de redención",
        "not": "No hay suficientes puntos disponibles",
        "usenum": "Usar cantidad JC",
        "out": "abandonar",
        "gotd": "ir Comercio",
    },
    "coin": {
        "manage": "gestión financiera",
        "total": "deposito total",
        "bao": "depositar tesoro",
        "mybao": "mi deposito",
        "profit": "Ingresos estimados",
        "cuntime": "Tiempo de depósito de monedas",
        "endtine": "Fecha de caducidad",
    },
    "team": {
        "myteam": "mi equipo",
        "ztnum": "Número de referencias directas",
        "activenum": "Número de miembros activos del equipo.",
        "profit": "ingreso",
        "back": "Reembolso",
        "cblog": "Registro de depósito",
        "bblog": "registro de monedas",
    },
    // k线
    "kline": {
        "text1": "tiempo compartido",
        "text2": "1 minuto",
        "text3": "5 minutos",
        "text4": "15 minutos",
        "text5": "30 minutos",
        "text6": "1 hora",
        "text7": "1 día",
        "text8": "1 semana",
        "text9": "Enero",
    },
    // 秒合约
    "miscro": {
        "trade": "en transacción",
        "buyPrice": "precio de compra",
        "finshPrice": "precio final",
        "loss": "Ganancias y pérdidas estimadas",
        "times": "cuenta regresiva",
        "mode": "modelo comercial",
        "num": "Cantidad de posiciones abiertas",
        "rate": "Rentabilidad",
        "up": "Comprar",
        "down": "comprar o vender",
        "openNum": "Por favor ingrese la cantidad de apertura",
        "success": "ordenado exitosamente!",
        "c2c": "cuenta C2C",
        "complaint": "Sugerencias",
        "reply": "Por favor escriba su pregunta y nos comunicaremos con usted lo antes posible...",
        "complaintList": "Listado de quejas y sugerencias.",
        "complaintReply": "responder:",
        "complaintDescription": "Por favor ingresa una descripción",
        "resetpwd": "cambiar la contraseña",
        "foundedOn": "Construido en",
        "commonProblem": "problema comun",
        "statement": "explicar",
        "about": "Acerca de WealthNest",
        "everyone": "Todo el mundo es un director ejecutivo",
        "program": "Programa de socios globales WealthNest",
        "program2": "Programa de socios",
        "myMine": "Mi mina:",
        "friend": "Mis amigos mineros:",
        "myRank": "Mi nivel:",
        "accumulated": "Comisión acumulada:",
        "copyLinks": "Copiar enlace de promoción",
        "moneyVein": "Convertir conexiones en dinero",
        "example": "Ejemplo de comisión",
        "enterQuantity": "Por favor ingrese la cantidad",
        "contractBalance": "Saldo de la cuenta del contrato",
        "submitRepeatedly": "El envío está en progreso, no lo envíe repetidamente",
        "alipayCode": "código de pago alipay",
        "wechatCode": "código de pago wechat",
        "realPrice": "precio en tiempo real",
        "currencyExchange": "Canjear moneda",
        "currencyExchangeIn": "Canjear moneda",
        "cashableBalance": "Saldo disponible para canje:",
        "minimumCashable": "Importe mínimo de reembolso:",
        "maximumCashable": "Retiro máximo:",
        "automaticallys": "Después de hacer clic en Canjear, se completará automáticamente y no se podrá devolver.",
        "match": "intercambio",
        "title": "Intercambio de activos",
        "holdAssets": "tenencia de activos",
        "dangerousCurrency": "Mantener monedas de riesgo",
        "convertibleQuantity": "Cantidad canjeable",
        "currencyExchanges": "Tasa de cambio de moneda:",
        "insuranceCurrency": "Moneda del seguro",
        "insuranceType": "tipo de seguro",
        "contractAsset": "Monto del seguro de activos del contrato",
        "warehouses": "Número de almacenes de seguros",
        "availableAssets": "Activos disponibles:",
        "insuredAssets": "Bienes asegurados:",
        "insuredAssets1": "Bienes asegurados",
        "insuranceAssets": "Activos de seguros:",
        "purchaseInsurance": "Suscripción de seguro",
        "insuranceClaims": "reclamos de seguro",
        "insuranceCancellation": "Cancelación de seguro",
        "coinWallet": "Monedero de seguro",
        "bmbWallet": "billetera HAP",
        "cumulativeCoins": "Monedas acumuladas:",
        "availableQuantity": "Cantidad disponible:",
        "rawCurrency": "Registro de monedas",
        "contractInsurance": "contrato de seguro",
        "tenThousand": "Diez mil",
        "runningLow": "Saldo insuficiente",
        "purchase": "La cantidad que ingresó no cumple con las reglas y su límite de compra está dentro",
        "reach": "a",
        "between": "entre",
        "onlyEnter": "Sólo puedes entrar",
        "integersBetween": "número entero entre",
        "notReturned": "Al hacer clic para suscribirse al seguro, se considera que ha comprendido el contrato de seguro en detalle y ha aceptado que el almacén del seguro entrará en vigor automáticamente y no podrá ser devuelto.",
        "settled": "Cuando la pérdida de los bienes asegurados imposibilite la realización de un pedido, se realizarán reclamaciones al seguro.",
        "profitable": "momentos en los que el activo obtiene beneficios",
        "terminated": ", el seguro quedará automáticamente rescindido.",
        "automatically": "Cuando los activos asegurados pierdan el 50%, deberá solicitar un reclamo de seguro; de lo contrario, no podrá comerciar. Cuando la ganancia sea del 100%, el seguro se rescindirá automáticamente. El almacén de seguros solo puede pagar dos compensaciones por año. día si se excede dos veces, la compensación se pagará en t+1.",
        "termination": "Una vez rescindido el contrato de seguro, los bienes asegurados pueden rescatarse. Una vez rescindido el contrato, se considera incumplimiento de contrato y el depósito del seguro se liquida automáticamente.",
        "just": "justo",
        "back": "opuesto",
        "lowestNumber": "La cantidad mínima es",
        "confirmExchange": "¿Confirmar el canje?",
        "contractValuation": "Valoración de activos de cuentas de contrato",
        "secondValuation": "Valoración de activos de la cuenta del segundo contrato",
        "falshValuation": "Valoración instantánea de activos de cuentas de cambio",
        "c2cValuation": "Valoración de activos de cuentas C2C",
        "recordWithdrawal": "Registros de depósitos y retiros",
        "category": "categoría",
        "safetyCenter": "Centro de Seguridad",
        "safeText1": "Se utiliza para recibir mensajes SMS de verificación al registrarse, cambiar contraseñas y configurar la seguridad.",
        "safeText2": "Las cuentas de Internet corren el riesgo de ser robadas. Se recomienda que cambie su contraseña periódicamente para proteger la seguridad de su cuenta.",
        "flashTrading": "Transacción flash",
        "assetCenter": "Centro de activos",
        "promotionCode": "Mi código de promoción",
        "loginAgain": "El inicio de sesión expiró, inicie sesión nuevamente",
        "text10": "Precios en tiempo real basados ​​en HAP",
        "text11": "Código de Autorización",
    }
}
