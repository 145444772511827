export default {
    "language": "Türkçe",
    // 导航
    "zdy": {
        "tjsq": "başvuruyu Gönder",
        "czsl": "Şarj miktarı",
        "enterczsl": "Lütfen yükleme tutarını girin",
        "qbdz": "cüzdan adresi",
        "enterqbdz": "Lütfen cüzdan adresini girin",
        "sftjmcj": "Sipariş satış fiyatının gönderilip gönderilmeyeceği",
        "sftjmrj": "Siparişin satın alma fiyatının gönderilip gönderilmeyeceği",
        "proloss": "Kâr alın ve zararı durdurun",
    },
    "header": {
        "home": "ön Sayfa",
        "markets": "Alıntılar",
        "fiat": "Fiat döviz ticareti",
        "c2c": "C2C işlemi",
        "exchange": "Kripto para ticareti",
        "lever": "sözleşme ticareti",
        "micro": "ikinci sözleşme ticareti",
        "myshop": "Benim dükkanım",
        "assets": "varlıklarım",
        "help": "Yardım Merkezi",
        "login": "Giriş yapmak",
        "sign": "kayıt olmak",
        "accountSet": "hesap ayarları",
        "tradeLog": "işlem günlüğü",
        "receiveSet": "Ödeme ayarları",
        "identify": "Kimlik doğrulama",
        "jchange": "Döviz değişimi",
        "logout": "oturumu Kapat",
        "themedark": "Gece modu",
        "themeday": "gündüz modu",
    },
    "frame": {
        "a": "Yeni bir para çekme talimatı durumunuz var, görüntülemek için lütfen tıklayın",
        "b": "İptal etmek",
        "c": "Elbette",
        "con": "Değerli kullanıcılar, çünkü ticaret merkezinde işlem yapan çok sayıda üye bulunmaktadır. ",
    },
    // 登录注册
    "lg": {
        "login": "Giriş yapmak",
        "register": "kayıt olmak",
    },
    // 首页
    "home": {
        "vol": "24 saat miktar",
        "c01": "Dünyanın önde gelen dijital varlık alım satım platformu",
        "c02": "Dünya çapında 130'dan fazla ülkede milyonlarca kullanıcıya güvenli ve güvenilir dijital varlık ticareti ve varlık yönetimi hizmetleri sağlayın",
        "c03": "Güvenli ve güvenilir",
        "c04": "Dijital varlık finansal hizmetlerinde 5 yıllık deneyim",
        "c05": "Profesyonel dağıtılmış mimari ve DDOS karşıtı saldırı sistemi",
        "c06": "Küresel ekolojik düzen",
        "c07": "Birçok ülkede yerel ticaret hizmet merkezleri kuruldu",
        "c08": "Birden fazla iş formunu entegre eden bir blockchain ekosistemi oluşturun",
        "c09": "Önce kullanıcı",
        "c10": "Gelişmiş bir tazminat mekanizması oluşturun",
        "c11": "Yatırımcı koruma fonu kurun",
        "c12": "Çok platformlu terminal ticareti her zaman, her yerde",
        "c13": "Tüm iş işlevlerini destekleyen iOS, Android ve Windows dahil olmak üzere birden fazla platformu kapsar",
        "sweep": "İndirmek için kodu tarayın",
        "android": "Android",
        "pinput": "Lütfen e-postanızı veya cep telefonu numaranızı girin",
        "atrade": "Bir WealthNest Global Station hesabı kaydedin ve ticaret yolculuğunuza başlayın",
        "download": "İndir uygulaması",
        "ios": "iOS indirme",
        "androidDownload": "Android indirme",
        companyoverview1: "Güvenli",
        companyoverview2: "Dijital varlık finansal hizmetlerinde 5 yıllık deneyim Profesyonel dağıtılmış mimari ve anti DDoS Saldırı Sistemi.",
        companyoverview3: "Güvenilir",
        companyoverview4: "Hızlı"
    },
    // 底部
    "foo": {
        "about": "Hakkımızda",
        "support": "Kullanıcı desteği",
        "contact": "bize Ulaşın",
        "qq": "Müşteri hizmetleri QQ",
        "email": "İletişim E-posta",
    },
    // 行情
    "market": {
        "handicap": "Handikap",
        "exchange": "paralar",
        "selfmarket": "İsteğe bağlı",
        "allmarket": "Tüm alıntılar",
        "symbol": "ticaret çifti",
        "newprice": "Son fiyat",
        "change": "Arttırmak",
        "highprice": "En yüksek fiyat",
        "lowprice": "en düşük fiyat",
        "vol": "24 saat miktar",
    },
    // 公共交易
    "td": {
        "buy": "Satın almak",
        "sell": "satmak",
        "currency": "Para birimi",
        "num": "miktar",
        "time": "zaman",
        "limit": "sınır",
        "price": "birim fiyat",
        "method": "ödeme yöntemi",
        "total": "Toptan",
        "inwant": "Lütfen istediğinizi girin",
        "do": "işletmek",
        "more": "daha fazla yükle",
        "nomore": "daha fazla yok",
        "nodata": "Veri yok",
        "trade": "ticaret",
        "buynum": "Satın alma miktarı",
        "sellout": "satmak",
        "buyin": "Satın almak",
        "all": "Tümü",
        "allbuy": "hepsini al",
        "allsell": "hepsini sat",
        "buytotal": "Lütfen satın almak istediğiniz toplam tutarı giriniz",
        "selltotal": "Lütfen satmak istediğiniz toplam tutarı giriniz",
        "buyallnum": "Lütfen satın almak istediğiniz miktarı giriniz",
        "sellallnum": "Lütfen satmak istediğiniz adeti giriniz",
        "tradeTotal": "toplam işlem tutarı",
        "doceil": "Saniyeler sonra otomatik olarak iptal et",
        "place": "Sipariş vermek",
        "waitpay": "Bekleyen ödeme",
        "paycode": "ödeme kodu",
        "finished": "tamamlanmış",
        "ceiled": "İptal edildi",
        "payed": "Zaten ödendi",
        "nofinish": "yapılmamış",
        "buyer": "alıcı",
        "seller": "satıcı",
        "callWay": "İletişim bilgileri",
        "placeTime": "sipariş zamanı",
        "renum": "Referans Numarası",
        "canceil": "İptal etmek",
        "confirm": "onaylamak",
        "pwd": "Lütfen işlem şifresini giriniz",
        "coincode": "Para Birimi",
        "pcoin": "Lütfen para birimini seçin",
        "setTradePwd": "İşlem şifresi ayarlansın mı?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Sipariş kaydı",
        "fatdLog": "Yasal para birimi işlem kayıtları",
        "shoper": "Tüccar",
        "faLog": "Yasal para birimi işlem kayıtları",
        "tdType": "işlem tipi",
        "odStatus": "Sipariş durumu",
        "pwaitPay": "Lütfen alıcının ödemesini bekleyin",
        "odFinish": "Sipariş tamamlandı",
        "odCeil": "Sipariş iptal edildi",
        "odPay": "Ödeme yapıldı, lütfen ödeme bilgilerini dikkatlice kontrol edin",
        "tdCeil": "anlaşmayı iptal et",
        "pCeil": "Satıcıya zaten ödeme yaptıysanız lütfen işlemi iptal etmeyin.",
        "paySure": "Ödeme onaylama",
        "pdopay": "Lütfen satıcıya ödeme yaptığınızı onaylayın",
        "badClick": "Kötü niyetli tıklamalar doğrudan hesabı dondurur",
        "receivePay": "Ödeme onaylama",
        "payReceive": "Lütfen alıcının size ödeme yaptığını onaylayın",
        "receivePays": "Ödemenin alındığını onaylayın",
        // LegalPay
        "payPlease": "lütfen öde",
        "tdPrice": "İşlem birim fiyatı",
        "tdNum": "İşlem sayısı",
        "payinfo": "Ödeme bilgisi",
        "banknum": "Banka hesabı",
        "realName": "gerçek isim",
        "shoperZname": "Tüccar şubesi",
        "shoperNum": "satıcı hesabı",
        "odCeil": "siparişi iptal et",
        "imPay": "Ödeme yaptım, onaylamak için tıklayın",
        // LegalSeller
        "register_time": "Kayıt zamanı",
        "odtotal": "Montaj sırası",
        "odmonth": "Sipariş ayın 30'unda tamamlandı",
        "odfinish": "Siparişi tamamla",
        "odrate": "Tamamlama oranı",
        "submit": "serbest bırakmak",
        "phone": "cep telefonu",
        "mysell": "satılık benim",
        "mybuy": "satın alma işlemim",
        "realAuth": "Doğrulandı",
        "advAuth": "Gelişmiş sertifikasyon",
        "linebuy": "çevrim içi satın al",
        "linesell": "internette satılık",
        "tipPay": "Ödeme yapmak için lütfen 24 saat içinde satıcıyla iletişime geçin. 24 saati aşarsa otomatik olarak iptal edilecektir.",
        "notlow": "Minimumun altına inilemiyor",
        "nothigh": "Maksimum limit aşılamaz",
        "notnum": "Maksimum miktar aşılamaz",
        // LegalShopDetail
        "type": "tip",
        "status": "durum",
        "seeOrder": "sipariş kontrolü",
        "withdraw": "geri çekilmek",
        "abnormal": "anormal",
        "lowershelf": "Raflardan kaldırıldı",
        "protection": "Hakların korunması devam ediyor",
        "apply": "Hakların korunması için başvuruda bulunun",
        "psType": "Lütfen türünü seçin",
        "pselect": "lütfen seç",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "banka kartı",
        "minNum": "Minimum işlem hacmi",
        "maxNum": "maksimum işlem hacmi",
        "wantBuy": "Satın almak istiyorum",
        "pnums": "Lütfen miktarı girin",
        "pmin": "Lütfen minimum işlem hacmini girin",
        "pmax": "Lütfen maksimum işlem hacmini girin",
        "pnot": "Maksimum işlem hacmi, minimum işlem hacminden az olamaz",
        // MyLegalShops
        "myshops": "Benim dükkanım",
        "name": "isim",
        "fiatSub": "Yasal para birimi",
        "shoper_balance": "Satıcı bakiyesi",
        "enterShop": "bir mağazaya girmek",
        // shop_legal_pay_detail
        "payedWait": "Ödeme yapıldı, doğrulama ve onay bekleniyor",
        "yes": "Evet",
        "no": "HAYIR",
        "sd": "Bakiyeyi kilitle",
        "journal": "Bakiye günlüğü",
        "business": "Tüccar",
        "user": "kullanıcı",
        "backdone": "Geri çekildi",
        "busbalance": "Satıcı bakiyesi",
        "userbalance": "Kullanıcı bakiyesi",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "Benim tarafımdan yayınlanan C2C",
        "myTrade": "İşlemimin C2C'si",
        "and": "Sağ",
        "balance": "Denge",
        "buynum": "Satın alma hacmi",
        "sellnum": "Satış hacmi",
        "bankTransfer": "Banka transferi",
        "payim": "Şahsen ödeme yapmalı",
        "pbuyNum": "Lütfen satın alma miktarını girin",
        "pprice": "Lütfen birim fiyatını giriniz",
        "payway": "Lütfen ödeme şeklini seçin",
        "psellNum": "Lütfen satış miktarını giriniz",
        "suerePlace": "Sipariş onaylamak?",
        "detail": "Detaylar",
        "payinfo": "Ödeme bilgisi",
        "Payee": "Alacaklı",
        "total": "Toptan",
        "name": "İsim",
        "cardnum": "kart numarası",
        "receivePay": "Ödemenin alındığını onaylayın",
        "ceilConfirm": "İşlemi iptal etmeyi onaylıyor musunuz?",
        "paySeller": "Lütfen satıcıya ödeme yaptığınızı doğrulayın. Kötü amaçlı tıklamalar hesabınızın dondurulmasına neden olur.",
        "payBuyer": "Lütfen alıcıdan ödeme bilgilerini aldığınızı onaylayın",
        "account": "hesap",
        "sellerPay": "Lütfen alıcının size ödeme yaptığını doğrulayın.",
        "payattion": "Lütfen ödeme onaylandıktan sonra mümkün olan en kısa sürede satıcıya ödeme yapın. Kötü niyetli tıklamalar doğrudan hesabı donduracaktır.",
        "log": "c2c siparişi tamamladı",
    },
    // 合约
    "lever": {
        "nowentrust": "Şuan ki sipariş",
        "hisentrust": "tarihi komisyon",
        "lvchi": "Sözleşme pozisyonları",
        "all": "Tümü",
        "or": "veya",
        "gotrade": "Ticarete başla",
        "tdrecord": "İşlem kaydı",
        "dealed": "Anlaşma yapıldı",
        "notdeal": "İşlem yok",
        "loading": "Yükleniyor...",
        "entotal": "Emanet edilen toplam tutar",
        "canuse": "Mevcut",
        "std": "piyasa fiyatı işlemi",
        "xtd": "ticareti sınırlamak",
        "pprice": "Lütfen fiyatı girin",
        "phand": "Lütfen satın alma lot boyutunu girin",
        "phandsell": "Lütfen satış parti büyüklüğünü girin",
        "pwd": "şifre",
        "ppwd": "Lütfen işlem şifresini giriniz",
        "psw": "İşlem Şifresi",
        "equal": "eşit",
        "times": "kez",
        "timed": "çoklu",
        "hand": "el",
        "hands": "adım sayısı",
        "ptimes": "Lütfen birden fazla seçin",
        "phands": "Lütfen parti büyüklüğünü seçin",
        "contractVal": "Sözleşme piyasa değeri",
        "bail": "marj",
        "canBail": "Kullanılabilir Marj",
        "charge": "İşlem hizmet ücreti",
        "domore": "Satın al (uzun git)",
        "doshort": "Sat (kısa)",
        "dmore": "Uzun git",
        "dshort": "kısa",
        "sureOd": "Sipariş onaylamak",
        "noless": "Parti büyüklüğü bundan az olamaz",
        "nomore": "Parti büyüklüğü şundan yüksek olamaz:",
        "tdnum": "Işlem hacmi",
        "risk": "risk oranı",
        "allloss": "Pozisyonların toplam kar ve zararı",
        "onehouse": "Tek tıklamayla pozisyon kapatma",
        "type": "tip",
        "entrustPrice": "Sipariş fiyatı",
        "openPrice": "açılış fiyatı",
        "nowPrice": "Mevcut fiyat",
        "styPrice": "Kâr fiyatını al",
        "stsPrice": "Zararı durdurma fiyatı",
        "openTime": "Açılış zamanı",
        "closeTime": "Kapanış saati",
        "rate": "Hizmet bedeli",
        "nightFee": "Gecelik ücret",
        "loss": "Kar ve zarar",
        "setloss": "Kâr almayı ve zararı durdurmayı ayarlayın",
        "expectProfit": "beklenen kar",
        "expectLoss": "beklenen kayıp",
        "allClose": "Tüm pozisyonları kapat",
        "moreClose": "Yalnızca uzun siparişleri kapatın",
        "nullClose": "Yalnızca kısa siparişleri kapatın",
        "sureClose": "Pozisyonu kapatmayı onaylıyor musunuz?",
        "thanZearo": "Ayarlanan değer 0'dan büyük olmalıdır",
        "listin": "bekleyen sipariş",
        "tdin": "işlemde",
        "closein": "Pozisyon kapanışı",
        "closed": "Pozisyon kapalı",
        "revoked": "İptal edildi",
        "revokeOrder": "Siparişi iptal etmek istediğinizden emin misiniz?",
        "ping": "Konumu kapat",
        "revoke": "Siparişi iptal et",
        "sureping": "Pozisyonu kapatacağınızdan emin misiniz?",
        "thanzone": "Ayarlanan değer 0'dan büyük olmalıdır",
    },
    // 币币
    "cuy": {
        "direction": "yön",
        "total": "Toplam",
        "price": "fiyat",
        "sell": "Satmak",
        "buy": "satın almak",
        "allStation": "Tüm site ticareti",
        "buyPrice": "Alış fiyatı",
        "buynum": "Satın alma hacmi",
        "sellPrice": "Satış fiyatı",
        "sellnum": "Satış hacmi",
        "tdPrice": "İşlem hacmi",
        "or": "veya",
        "tdStart": "Ticarete başla",
        "pbPrice": "Lütfen satın alma fiyatını girin",
        "pbNum": "Lütfen satın alma tutarını girin",
        "psPrice": "Lütfen satış fiyatını giriniz",
        "psNum": "Lütfen satış tutarını giriniz",
        "fixPrice": "ticareti sınırlamak",
        "Mtrans": "piyasa fiyatı işlemi",
        "available": "Mevcut",
        "revoke": "İptal etmek",
        "loading": "Yükleniyor",
        "confirmCancel": "İptal etmek istediğinden emin misin?",
        "confirm": "Elbette",
        "cancel": "İptal etmek",
        "nomore": "Daha fazla veri yok",
        "evelPrice": "ortalama işlem fiyatı",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Transferi onaylamak istiyor musunuz?",
        "assets": "varlıklar",
        "currency_account": "Para hesabı",
        "lever_account": "Sözleşme hesabı",
        "fince_account": "Fiat hesabı",
        "change_account": "işlem hesabı",
        "micro_account": "ikinci sözleşme hesabı",
        "miscroAccount": "ikinci sözleşme hesabı",
        "assets_hua": "Fon Transferi",
        "all_assets": "Dönüştürülen toplam varlıklar",
        "currency": "Para birimi",
        "canuse": "Mevcut",
        "frezz": "donmak",
        "conversion": "dönüştürülmüş",
        "charging": "Para yatırma",
        "withdraw": "Para çekme",
        "address_bind": "Para çekme adresini bağlayın",
        "address_withdraw": "Coin çekme adresi yönetimi",
        "record": "Detaylar",
        "tbrecord": "Para çekme kaydı",
        "address_charge": "Para yatırma adresi",
        "address_width": "Para çekme adresi",
        "copy": "kopyala",
        "ercode": "QR kod",
        "reminder": "Nazik ipuçları",
        "notcharge": "Lütfen yukarıdaki adrese para yatırmayın.",
        "ornone": "aksi takdirde varlıklar geri alınamaz",
        "support": "Para yatırma işlemleri yalnızca basit gönderme yöntemini destekler. Diğer yöntemleri kullanan para yatırma işlemleri (hepsini gönder) geçici olarak aktarılamaz. Lütfen anlayın.",
        "minnum": "Minimum para çekme tutarı",
        "ratenum": "Hizmet bedeli",
        "havenum": "Varış miktarı",
        "from": "",
        "to": "'dan",
        "transfer": "Aktar",
        "tfnum": "Transfer miktarı",
        "ptfnum": "Lütfen minimum tutarı girin",
        "dtfnum": "Transfer sayısı",
        "canbalance": "Kalan bakiye",
        "sureConfirm": "Aktarımı onayla",
        "moneyRecord": "Finansal kayıtlar",
        "pNum": "Lütfen transfer tutarını girin",
        "leverUse": "Sözleşme hesapları mevcut",
        "legalUse": "Fiat hesapları mevcut",
        "changeUselg": "Coin hesabı mevcut",
        "changeUse": "Ticaret hesapları mevcut",
        "microUse": "Saniyelik sözleşme hesabı mevcut",
        "look": "Kontrol etmek",
        "chargeRecord": "Mevduat kaydı",
        "status": "izleme durumu",
        "noopen": "Bu işlev henüz kullanılamıyor",
        "enterAdderses": "Lütfen para çekme adresini girin",
        "enterNum": "Lütfen çekim tutarını giriniz",
        "lessMin": "Girilen para çekme tutarı minimum değerden az",
        "tips01": "Lütfen yukarıdaki adrese başka varlık yatırmayın, aksi takdirde varlıklar geri alınmayacaktır.",
        "tips02": "Para yatırma işlemleri yalnızca basit gönderme yöntemini destekler. Diğer yöntemleri kullanan para yatırma işlemleri (hepsini gönder) geçici olarak aktarılamaz. Lütfen anlayın.",
        "beizhu": "Açıklama",
        "pbeizhu": "Lütfen açıklamalarınızı girin",
        "add": "a ekle",
        "addressList": "adres listesi",
        "delete": "silmek",
        "norec": "Kayıt yok",
        "selectCurrency": "Lütfen para birimini seçin",
        "chainType": "zincir tipi",
        "commissionLibrary": "komisyon kütüphanesi",
    },
    // 账户设置
    "set": {
        "mycode2": "yetki kodum",
        "copycode2": "Yetkilendirme kodunu kopyala",
        "uaccont": "Hesabınızın güvenlik düzeyi:",
        "strong": "güçlü",
        "middle": "orta",
        "weak": "zayıf",
        "min": "Düşük",
        "heigh": "yüksek",
        "complete": "Hesap güvenliğini sağlamak için daha fazla bilgi tamamlayın",
        "mycode": "davetiye kodum",
        "copycode": "Davetiye kodunu kopyala",
        "bindphone": "Cep telefonunu bağla",
        "bindemail": "E-postayı bağla",
        "loginpwd": "giriş şifresi",
        "noBind": "Bağlı değil",
        "binds": "bağlayıcı",
        "bind": "Bağlamaya git",
        "binded": "Ciltli",
        "net": "İnternet hesaplarınızın çalınma riski bulunmaktadır. Hesabınızın güvenliğini korumak için şifrenizi düzenli olarak değiştirmeniz tavsiye edilir.",
        "reset": "Tekrar düzeltme yapmak",
        "setPsw": "İşlem güvenliğini sağlamak için bir işlem şifresi belirleyin.",
        "goSet": "Ayarlara git",
        "bindAccount": "Takas hesabını bağla",
        "duihuan": "Puanları kullanmak için hesabı bağlayın",
        "copysuccess": "Başarıyla kopyalandı",
        "copyfail": "Kopyalama başarısız oldu",
        "recopy": "Lütfen tekrar kopyalayın",
        "account": "Hesap",
        "detail": "Detaylar",
        "val": "Miktar",
        "forgetPsw": "şifreyi unut",
        "code": "Doğrulama kodu",
        "getCode": "doğrulama kodunu al",
        "setPsw": "şifreyi belirle",
        "enterPsw": "Lütfen şifre giriniz",
        "pswFalse": "İki kez girilen şifreler tutarsız",
        "enterPswagain": "Lütfen şifreyi tekrar girin",
        "jsetPsw": "İşlem şifresini ayarla",
        "jenterPsw": "Lütfen işlem şifresini giriniz",
        "jenterPswagain": "Lütfen işlem şifresini tekrar giriniz",
        "enterAccount": "Lütfen kullanıcı adını girin",
        "enterCode": "lütfen doğrulama kodunu girin",
        "accountFalse": "Girdiğiniz cep telefonu veya e-posta hesabı kurallara uymuyor!",
        "second": "saniye",
        "sendCode": "Doğrulama kodunu gönder",
        "sendsucc": "Başarıyla gönderildi",
        "enterPswTwo": "Lütfen şifrenizi iki kez girin",
        "rest": "Geriye kalan",
        "yes": "Evet",
        "no": "HAYIR",
        "yorn": "İşlem şifresi belirlenip belirlenmeyeceği",
        "authenticate": "Lütfen kimlik doğrulama işlemini gerçekleştirin",
    },
    // 身份认证
    "auth": {
        "identity": "Kimlik doğrulama",
        "name": "İsim",
        "idcard": "İD",
        "upimg": "Lütfen kimlik fotoğraflarını yükleyiniz; birincisi ön, ikincisi arka, üçüncüsü ise önden kimlik içeren fotoğraftır.",
        "submit": "göndermek",
        "auditing": "inceleme altında...",
        "certified": "doğrulandı!",
        "pname": "Lütfen adınızı yazın",
        "pidcard": "Lütfen kimlik numaranızı giriniz",
        "pimg": "Lütfen kimliğinizin bir resmini yükleyin!",
    },
    // 收款方式
    "seting": {
        "pmethod": "ödeme yöntemi",
        "truename": "gerçek isim",
        "opening": "banka adı",
        "bank": "Banka kartı numarası",
        "alipay": "Alipay hesabı",
        "wename": "WeChat takma adı",
        "wechat": "WeChat hesabı",
        "pname": "lütfen gerçek adınızı girin",
        "popen": "Lütfen hesabın açıldığı bankanın adını girin",
        "pcard": "Lütfen banka kartı numarasını girin",
        "palipay": "Lütfen Alipay hesap numaranızı girin",
        "pwname": "Lütfen WeChat takma adınızı girin",
        "pwaccount": "Lütfen WeChat hesabınızı girin",
        "pimg1": "WeChat ödeme kodu",
        "pimg2": "Alipay ödeme kodu",
    },
    // 帮助中心
    "news": {
        "seeall": "hepsini gör",
        "helpcenter": "Duyuru Merkezi",
        "back": "geri dönmek",
    },
    //登录
    "login": {
        "welcome": "Lütfen giriş yapın",
        "phoneLogin": "Telefonla giriş yapın",
        "emailLogin": "E-posta Girişi",
        "account": "hesap",
        "psw": "şifre",
        "login": "Giriş yapmak",
        "nouser": "Henüz WealthNest kullanıcısı değil misiniz?",
        "goRegister": "Şimdi kaydolun ve dünyanın önde gelen dijital varlık alım satım platformunda işlem yapmaya başlayın.",
        "registerFree": "ücretsiz kayıt",
        "psw6": "Şifre altı karakterden az olamaz",
    },
    "register": {
        "register": "kayıt olmak",
        "phoneRegister": "Telefonunuzu kaydedin",
        "emailRegister": "e-posta kaydı",
        "country": "milliyet",
        "enterPhone": "Lütfen telefon numarasını girin",
        "enterEmail": "lütfen e-posta adresinizi girin",
        "psw16": "Şifre 6-16 karakter arasında olmalıdır",
        "inviteCode": "Davet Kodu",
        "sel": "Gerekli",
        "sel2": "İsteğe bağlı",
        "read": "okudum ve kabul ediyorum",
        "xieyi": "\"Kullanıcı Sözleşmesi\"",
        "tips01": "Uyruk bilgileri kayıttan sonra değiştirilemez, bu nedenle bilgileri doğru seçtiğinizden emin olun.",
        "tips02": "Doğrulama e-postaları yanlışlıkla spam olarak tanımlanabilir; bu nedenle lütfen bunları dikkatlice kontrol edin.",
        "tips03": "Lütfen WealthNest hesap numaranızı ve giriş şifrenizi doğru şekilde saklayın.",
        "tips04": "Diğer web sitelerindekiyle aynı giriş şifresini kullanmayın.",
        "emailFalse": "Girdiğiniz e-posta kurallara uymuyor",
    },
    // 积分兑换
    "jc": {
        "title": "JC dijital varlık değişimi BEAU platformu",
        "balance": "Denge",
        "cannum": "Mevcut JC miktarı",
        "new": "Son fiyat",
        "exnum": "Değişim miktarı",
        "pnum": "Lütfen JC miktarını girin",
        "canex": "Kullanılabilecek BEAU miktarı",
        "exnow": "Şimdi kullan",
        "record": "Değişim kaydı",
        "exprice": "döviz fiyatı",
        "extime": "Ödeme süresi",
        "not": "Yeterli puan mevcut değil",
        "usenum": "JC miktarını kullan",
        "out": "çıkış yapmak",
        "gotd": "ticarete git",
    },
    "coin": {
        "manage": "finansal Yönetim",
        "total": "toplam mevduat",
        "bao": "hazine yatırmak",
        "mybao": "Depozitom",
        "profit": "Tahmini gelir",
        "cuntime": "Para yatırma süresi",
        "endtine": "Son kullanma tarihi",
    },
    "team": {
        "myteam": "benim takımım",
        "ztnum": "Doğrudan yönlendirmelerin sayısı",
        "activenum": "Ekibin aktif üye sayısı",
        "profit": "gelir",
        "back": "İndirim",
        "cblog": "Mevduat kaydı",
        "bblog": "madeni para kaydı",
    },
    // k线
    "kline": {
        "text1": "zaman paylaşımı",
        "text2": "1 dakika",
        "text3": "5 dakika",
        "text4": "15 dakika",
        "text5": "30 dakika",
        "text6": "1 saat",
        "text7": "1 gün",
        "text8": "1 hafta",
        "text9": "Ocak",
    },
    // 秒合约
    "miscro": {
        "trade": "işlemde",
        "buyPrice": "Satınalma fiyatı",
        "finshPrice": "son fiyat",
        "loss": "Tahmini kar ve zarar",
        "times": "geri sayım",
        "mode": "ticaret modeli",
        "num": "Açık pozisyon miktarı",
        "rate": "karlılık",
        "up": "Hepsini satın al",
        "down": "Satın al veya sat",
        "openNum": "Lütfen açılış miktarını giriniz",
        "success": "başarıyla sipariş edildi!",
        "c2c": "C2C hesabı",
        "complaint": "Öneriler",
        "reply": "Lütfen sorunuzu yazın, en kısa sürede size geri dönüş yapacağız...",
        "complaintList": "Şikayet öneri listesi",
        "complaintReply": "cevap vermek:",
        "complaintDescription": "Lütfen bir açıklama girin",
        "resetpwd": "şifre değiştir",
        "foundedOn": "Dahili",
        "commonProblem": "ortak sorun",
        "statement": "açıklamak",
        "about": "WealthNest Hakkında",
        "everyone": "Herkes CEO'dur",
        "program": "WealthNest Küresel Ortak Programı",
        "program2": "Ortak Programı",
        "myMine": "Benim madenim:",
        "friend": "Madencilik arkadaşlarım:",
        "myRank": "Benim seviyem:",
        "accumulated": "Kümülatif komisyon:",
        "copyLinks": "Promosyon bağlantısını kopyala",
        "moneyVein": "Bağlantıları paraya dönüştürmek",
        "example": "Komisyon örneği",
        "enterQuantity": "Lütfen miktarı girin",
        "contractBalance": "Sözleşme hesap bakiyesi",
        "submitRepeatedly": "Gönderim devam ediyor, lütfen tekrar tekrar gönderim yapmayın",
        "alipayCode": "Alipay ödeme kodu",
        "wechatCode": "WeChat ödeme kodu",
        "realPrice": "gerçek zamanlı fiyat",
        "currencyExchange": "Para birimini kullan",
        "currencyExchangeIn": "Para birimini kullan",
        "cashableBalance": "Kullanılabilecek bakiye:",
        "minimumCashable": "Minimum ödeme tutarı:",
        "maximumCashable": "Maksimum para çekme:",
        "automaticallys": "Kullan'a tıkladıktan sonra işlem otomatik olarak tamamlanacak ve iade edilemez.",
        "match": "değişme",
        "title": "Varlık değişimi",
        "holdAssets": "varlıkları tutmak",
        "dangerousCurrency": "Riskli paraları tutun",
        "convertibleQuantity": "Kullanılabilir miktar",
        "currencyExchanges": "Döviz kuru:",
        "insuranceCurrency": "Sigorta para birimi",
        "insuranceType": "sigorta türü",
        "contractAsset": "Sözleşme varlık sigortası tutarı",
        "warehouses": "Sigorta depo sayısı",
        "availableAssets": "Mevcut varlıklar:",
        "insuredAssets": "Sigortalı varlıklar:",
        "insuredAssets1": "Sigortalı varlıklar",
        "insuranceAssets": "Sigorta varlıkları:",
        "purchaseInsurance": "Sigorta aboneliği",
        "insuranceClaims": "sigorta talepleri",
        "insuranceCancellation": "Sigorta iptali",
        "coinWallet": "Sigorta bozuk para cüzdanı",
        "bmbWallet": "HAP cüzdanı",
        "cumulativeCoins": "Birikmiş paralar:",
        "availableQuantity": "Mevcut miktar:",
        "rawCurrency": "Madeni para kaydı",
        "contractInsurance": "sözleşme sigortası",
        "tenThousand": "On bin",
        "runningLow": "Yetersiz bakiye",
        "purchase": "Girdiğiniz miktar kurallara uymuyor ve satın alma limitiniz dahilinde",
        "reach": "'dan",
        "between": "arasında",
        "onlyEnter": "Sadece girebilirsiniz",
        "integersBetween": "arasındaki tamsayı",
        "notReturned": "Sigortaya abone olmak için tıkladığınızda sigorta sözleşmesini detaylı olarak anlamış ve sigorta deposunun otomatik olarak yürürlüğe gireceğini ve iade edilemeyeceğini kabul etmiş sayılırsınız.",
        "settled": "Sigortalı varlıkların kaybı sipariş verilmesini imkansız hale getirdiğinde sigorta talepleri yapılacaktır",
        "profitable": "varlığın kâr sağladığı zamanlar",
        "terminated": "sigortası otomatik olarak sona erecektir.",
        "automatically": "Sigortalı varlıkların %50'sini kaybettiğinde sigorta talebinde bulunmalısınız, aksi takdirde ticaret yapamazsınız. Kâr %100 olduğunda sigorta otomatik olarak feshedilir. Sigorta deposunun yalnızca iki tazminat ödemesine izin verilir. İki katı aşarsanız tazminat t+1 tarihinde ödenecektir.",
        "termination": "Sigorta sözleşmesinin feshedilmesinden sonra sigortalı varlıklar itfa edilebilir. Sözleşmenin feshedilmesinden sonra bu durum sözleşmenin ihlali olarak kabul edilir ve sigorta deposu otomatik olarak temizlenir.",
        "just": "Sadece",
        "back": "zıt",
        "lowestNumber": "Asgari miktar:",
        "confirmExchange": "Kullanım onaylansın mı?",
        "contractValuation": "Sözleşme hesabı varlık değerlemesi",
        "secondValuation": "İkinci sözleşme hesabı varlık değerlemesi",
        "falshValuation": "Anlık döviz hesabı varlık değerlemesi",
        "c2cValuation": "C2C hesabı varlık değerlemesi",
        "recordWithdrawal": "Para yatırma ve çekme kayıtları",
        "category": "kategori",
        "safetyCenter": "Güvenlik Merkezi",
        "safeText1": "Kayıt olurken, şifreleri değiştirirken ve güvenliği ayarlarken doğrulama SMS mesajlarını almak için kullanılır",
        "safeText2": "İnternet hesaplarınızın çalınma riski bulunmaktadır. Hesabınızın güvenliğini korumak için şifrenizi düzenli olarak değiştirmeniz tavsiye edilir.",
        "flashTrading": "Flaş işlemi",
        "assetCenter": "Varlık Merkezi",
        "promotionCode": "Promosyon kodum",
        "loginAgain": "Oturum açma süresi WealthNestu, lütfen tekrar oturum açın",
        "text10": "HAP tabanlı gerçek zamanlı fiyatlar",
        "text11": "Yetki Kodu",
    }
}
