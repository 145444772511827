<template>
    <div class="account-main wrap"   style="width:100%;background-color: #102030;">
        <ul class="whites log_ul">
            <li class="flex ft14">
                <span class="flex1 tc">ID</span>
                <span class="flex1 tc">{{$t('ctc.account')}}</span>
                <span class="flex1 tc">{{$t('fat.register_time')}}</span>
            </li>
            <div class="li_wrap scroll">
                <li class="flex ft12 around" v-for="(item,index) in TeamList" :key="index">
                    <span class="flex1 tc">{{ item.id }}</span>
                    <span class="flex1 tc">{{ item.account_number }}</span>
                    <span class="flex1 tc">{{item.create_date}}</span>
                </li>
                <p class="tc more" @click="getMore">{{more}}</p>
            </div>
        </ul>
       
    </div>
</template>
<script>
export default {
    data(){
        return {
            more:this.$t('td.more'),
            page:1,
            limit:20,
            TeamList:[],
            isLoaded:false
        }
    },
    mounted(){
        this.init();
    },
    methods:{
        init(){
            var i = layer.load();
            this.$ajax({
                url: '/api/getTeam',
                method:'get',
                data:{limit:this.limit,page:this.page},  
                headers: {'Authorization':  localStorage.getItem('token')},    
            }).then(res=>{
                layer.close(i);
                if(res.data.type=="ok"){
                    this.TeamList = this.TeamList.concat(res.data.message||[]);
                    if(res.data.message.length == 0){
                        this.more = this.$t('td.nodata')
                        this.isLoaded=true;
                    }
                    if(res.data.message.length<this.limit){
                        this.more = this.$t('td.nomore');
                        this.isLoaded=true;
                    }else if(res.data.message.length==this.limit){
                        this.more = this.$t('td.more');
                        this.isLoaded=false;
                    }
                }else{
                    layer.msg(res.data.message)
                }
            }).catch(error=>{
                // console.log(error)
            })
        },
        getMore(){
            if(this.isLoaded)return;
            this.more = '加载中...'
            this.page+=1;
            this.init();
        }
        
    }
}
</script>
<style scoped>
    .wrap{
        overflow: auto;
    }
    .li_wrap{
        max-height: 600px;
        overflow-y: auto;
    }
   .log_ul li{
       padding: 15px 0;
   }
   .log_ul>li:first-child{
      border-bottom: 1px solid #303b4b;
   }
   .log_ul>li:first-child:hover{
       background: #22252b;
   }
   .log_ul li:hover{
       background: #61688a;
   }
   .more{
        color: #999; 
        font-size: 12px;
        margin-top: 10px;
        cursor: pointer;
   }
   ::-webkit-scrollbar{
       background-color: #22252b;
   }
</style>