export default {
    "language": "Italiano",
    // 导航
    "zdy": {
        "tjsq": "presentare domanda",
        "czsl": "Quantità di ricarica",
        "enterczsl": "Inserisci l'importo della ricarica",
        "qbdz": "indirizzo del portafoglio",
        "enterqbdz": "Inserisci l'indirizzo del portafoglio",
        "sftjmcj": "Se inviare il prezzo di vendita dell'ordine",
        "sftjmrj": "Se inviare il prezzo di acquisto dell'ordine",
        "proloss": "Prendi profitto e ferma le perdite",
    },
    "header": {
        "home": "prima pagina",
        "markets": "Citazioni",
        "fiat": "Commercio di valuta fiat",
        "c2c": "Transazione C2C",
        "exchange": "Commercio di criptovaluta",
        "lever": "negoziazione a contratto",
        "micro": "secondo contratto di negoziazione",
        "myshop": "il mio negozio",
        "assets": "i miei beni",
        "help": "Centro assistenza",
        "login": "Login",
        "sign": "Registrati",
        "accountSet": "impostazioni dell'account",
        "tradeLog": "registro delle transazioni",
        "receiveSet": "Impostazioni di pagamento",
        "identify": "Autenticazione",
        "jchange": "Cambio di valuta",
        "logout": "disconnessione",
        "themedark": "Modalità notturna",
        "themeday": "modalità giorno",
    },
    "frame": {
        "a": "Hai un nuovo stato dell'ordine di prelievo, fai clic per visualizzarlo",
        "b": "Annulla",
        "c": "Sicuro",
        "con": "Cari utenti, perché ci sono molti membri che commerciano nel centro commerciale. ",
    },
    // 登录注册
    "lg": {
        "login": "Login",
        "register": "Registrati",
    },
    // 首页
    "home": {
        "vol": "Quantità 24 ore",
        "c01": "La piattaforma di trading di asset digitali leader a livello mondiale",
        "c02": "Fornisci servizi di trading e gestione patrimoniale sicuri e affidabili a milioni di utenti in più di 130 paesi in tutto il mondo",
        "c03": "Sicuro e affidabile",
        "c04": "5 anni di esperienza nei servizi finanziari per asset digitali",
        "c05": "Architettura distribuita professionale e sistema di attacco anti-DDOS",
        "c06": "Disposizione ecologica globale",
        "c07": "In molti paesi sono stati istituiti centri di servizi commerciali localizzati",
        "c08": "Crea un ecosistema blockchain che integri più forme di business",
        "c09": "Prima l'utente",
        "c10": "Stabilire un meccanismo di compensazione avanzato",
        "c11": "Istituire un fondo di protezione degli investitori",
        "c12": "Trading tramite terminale multipiattaforma, sempre e ovunque",
        "c13": "Copre più piattaforme tra cui iOS, Android e Windows, supportando funzioni aziendali complete",
        "sweep": "Scansiona il codice per scaricarlo",
        "android": "Androide",
        "pinput": "Inserisci la tua email o il numero di cellulare",
        "atrade": "Registra un conto WealthNest Global Station e inizia il tuo viaggio nel trading",
        "download": "Scarica l'APP",
        "ios": "Scarica iOS",
        "androidDownload": "Scarica Android",
        companyoverview1: "Sicuro",
        companyoverview2: "5 anni di esperienza nei servizi finanziari per asset digitali Architettura distribuita professionale e sistema anti-attacco DDoS.",
        companyoverview3: "Affidabile",
        companyoverview4: "Veloce"
    },
    // 底部
    "foo": {
        "about": "chi siamo",
        "support": "Supporto utente",
        "contact": "Contattaci",
        "qq": "Servizio clienti QQ",
        "email": "Contatto email",
    },
    // 行情
    "market": {
        "handicap": "Handicap",
        "exchange": "monete",
        "selfmarket": "Opzionale",
        "allmarket": "Tutte le citazioni",
        "symbol": "coppia commerciale",
        "newprice": "Ultimo prezzo",
        "change": "Aumento",
        "highprice": "il prezzo più alto",
        "lowprice": "prezzo più basso",
        "vol": "Quantità 24 ore",
    },
    // 公共交易
    "td": {
        "buy": "Acquistare",
        "sell": "vendere",
        "currency": "Valuta",
        "num": "quantità",
        "time": "tempo",
        "limit": "limite",
        "price": "prezzo unitario",
        "method": "metodo di pagamento",
        "total": "forfettario",
        "inwant": "Inserisci quello desiderato",
        "do": "operare",
        "more": "caricare di più",
        "nomore": "non più",
        "nodata": "Nessun dato",
        "trade": "commercio",
        "buynum": "Quantità d'acquisto",
        "sellout": "vendere",
        "buyin": "Acquistare",
        "all": "Tutto",
        "allbuy": "compra tutto",
        "allsell": "vendere tutto",
        "buytotal": "Inserisci l'importo totale che desideri acquistare",
        "selltotal": "Inserisci l'importo totale che desideri vendere",
        "buyallnum": "Inserisci la quantità che desideri acquistare",
        "sellallnum": "Inserisci la quantità che desideri vendere",
        "tradeTotal": "importo totale della transazione",
        "doceil": "Annulla automaticamente dopo secondi",
        "place": "Effettua un ordine",
        "waitpay": "In attesa di Pagamento",
        "paycode": "codice di pagamento",
        "finished": "completato",
        "ceiled": "Annullato",
        "payed": "Già pagato",
        "nofinish": "annullato",
        "buyer": "acquirente",
        "seller": "venditore",
        "callWay": "Informazioni sui contatti",
        "placeTime": "ora dell'ordine",
        "renum": "N. di riferimento",
        "canceil": "Annulla",
        "confirm": "Confermare",
        "pwd": "Inserisci la password della transazione",
        "coincode": "Unità monetaria",
        "pcoin": "Seleziona l'unità monetaria",
        "setTradePwd": "Impostare la password della transazione?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Registro dell'ordine",
        "fatdLog": "Registri delle transazioni in valuta legale",
        "shoper": "Mercante",
        "faLog": "Registri delle transazioni in valuta legale",
        "tdType": "Tipo di transazione",
        "odStatus": "Lo stato dell'ordine",
        "pwaitPay": "Si prega di attendere il pagamento da parte dell'acquirente",
        "odFinish": "ordine completato",
        "odCeil": "Ordine annullato",
        "odPay": "Il pagamento è stato effettuato, controlla attentamente le informazioni di pagamento",
        "tdCeil": "annullare l'accordo",
        "pCeil": "Se hai già pagato il venditore, non annullare la transazione.",
        "paySure": "Conferma di pagamento",
        "pdopay": "Conferma di aver pagato il venditore",
        "badClick": "I clic dannosi bloccheranno direttamente l'account",
        "receivePay": "Conferma di pagamento",
        "payReceive": "Conferma che l'acquirente ti ha pagato",
        "receivePays": "Conferma il pagamento ricevuto",
        // LegalPay
        "payPlease": "per favore paga",
        "tdPrice": "Prezzo unitario della transazione",
        "tdNum": "Numero di transazioni",
        "payinfo": "informazioni sul pagamento",
        "banknum": "Conto bancario",
        "realName": "nome vero e proprio",
        "shoperZname": "Ramo mercantile",
        "shoperNum": "conto commerciante",
        "odCeil": "Annulla Ordine",
        "imPay": "Ho pagato, clicca per confermare",
        // LegalSeller
        "register_time": "Orario di registrazione",
        "odtotal": "Ordine di assemblaggio",
        "odmonth": "Ordine completato il 30",
        "odfinish": "Completa l'ordine",
        "odrate": "Tasso di completamento",
        "submit": "pubblicazione",
        "phone": "cellulare",
        "mysell": "il mio in vendita",
        "mybuy": "Il mio acquisto",
        "realAuth": "Verificato",
        "advAuth": "Certificazione avanzata",
        "linebuy": "comprare on line",
        "linesell": "in vendita online",
        "tipPay": "Contatta il commerciante entro 24 ore per pagare. Se supera le 24 ore, verrà automaticamente annullato.",
        "notlow": "Non è possibile scendere sotto il minimo",
        "nothigh": "Non è possibile superare il limite massimo",
        "notnum": "Non può superare la quantità massima",
        // LegalShopDetail
        "type": "tipo",
        "status": "stato",
        "seeOrder": "controllare l'ordine",
        "withdraw": "ritirare",
        "abnormal": "anormale",
        "lowershelf": "Rimosso dagli scaffali",
        "protection": "Tutela dei diritti in corso",
        "apply": "Richiedi la tutela dei diritti",
        "psType": "Scegli il tipo",
        "pselect": "si prega di scegliere",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "carta bancaria",
        "minNum": "Volume minimo delle transazioni",
        "maxNum": "volume massimo di scambi",
        "wantBuy": "Voglio comprare",
        "pnums": "Inserisci la quantità",
        "pmin": "Inserisci il volume minimo della transazione",
        "pmax": "Inserisci il volume massimo della transazione",
        "pnot": "Il volume massimo delle transazioni non può essere inferiore al volume minimo delle transazioni",
        // MyLegalShops
        "myshops": "il mio negozio",
        "name": "nome",
        "fiatSub": "Moneta legale",
        "shoper_balance": "Saldo del commerciante",
        "enterShop": "entrare in un negozio",
        // shop_legal_pay_detail
        "payedWait": "Il pagamento è stato effettuato, in attesa di verifica e conferma",
        "yes": "SÌ",
        "no": "NO",
        "sd": "Blocca il saldo",
        "journal": "Registro del saldo",
        "business": "Mercante",
        "user": "utente",
        "backdone": "Ritirato",
        "busbalance": "Saldo del commerciante",
        "userbalance": "Saldo utente",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C pubblicato da me",
        "myTrade": "C2C della mia transazione",
        "and": "Giusto",
        "balance": "Bilancia",
        "buynum": "Volume d'acquisto",
        "sellnum": "Volume di vendita",
        "bankTransfer": "Trasferimento bancario",
        "payim": "Deve pagare di persona",
        "pbuyNum": "Inserisci la quantità di acquisto",
        "pprice": "Inserisci il prezzo unitario",
        "payway": "Seleziona la modalità di pagamento",
        "psellNum": "Inserisci la quantità di vendita",
        "suerePlace": "Confermare l'ordine?",
        "detail": "Dettagli",
        "payinfo": "informazioni sul pagamento",
        "Payee": "Beneficiario",
        "total": "forfettario",
        "name": "Nome",
        "cardnum": "numero di carta",
        "receivePay": "Conferma il pagamento ricevuto",
        "ceilConfirm": "Confermare per annullare la transazione?",
        "paySeller": "Conferma di aver pagato il venditore I clic dannosi bloccheranno il tuo account.",
        "payBuyer": "Conferma di aver ricevuto le informazioni di pagamento dall'acquirente",
        "account": "account",
        "sellerPay": "Per favore, confermi che l'acquirente ti ha pagato?",
        "payattion": "Ti preghiamo di pagare il venditore il prima possibile dopo la conferma del pagamento. I clic dannosi bloccheranno direttamente l'account.",
        "log": "c2c ordine completato",
    },
    // 合约
    "lever": {
        "nowentrust": "Ordine attuale",
        "hisentrust": "commissione storica",
        "lvchi": "Posizioni contrattuali",
        "all": "Tutto",
        "or": "O",
        "gotrade": "Inizia a fare trading",
        "tdrecord": "Registro delle transazioni",
        "dealed": "Affare fatto",
        "notdeal": "Nessuna transazione",
        "loading": "caricamento...",
        "entotal": "Importo totale affidato",
        "canuse": "Disponibile",
        "std": "transazione a prezzo di mercato",
        "xtd": "limitare il commercio",
        "pprice": "Inserisci il prezzo",
        "phand": "Inserisci la dimensione del lotto di acquisto",
        "phandsell": "Inserisci la dimensione del lotto di vendita",
        "pwd": "parola d'ordine",
        "ppwd": "Inserisci la password della transazione",
        "psw": "password della transazione",
        "equal": "pari",
        "times": "volte",
        "timed": "multiplo",
        "hand": "mano",
        "hands": "numero di passi",
        "ptimes": "Seleziona più elementi",
        "phands": "Seleziona la dimensione del lotto",
        "contractVal": "Valore di mercato del contratto",
        "bail": "margine",
        "canBail": "Margine disponibile",
        "charge": "Commissione per il servizio di transazione",
        "domore": "Acquista (vai lungo)",
        "doshort": "Vendere (breve)",
        "dmore": "Vai a lungo",
        "dshort": "corto",
        "sureOd": "Confermare l'ordine",
        "noless": "La dimensione del lotto non può essere inferiore a",
        "nomore": "La dimensione del lotto non può essere superiore a",
        "tdnum": "Volume degli scambi",
        "risk": "tasso di rischio",
        "allloss": "Profitto e perdita totale delle posizioni",
        "onehouse": "Chiudi una posizione con un clic",
        "type": "tipo",
        "entrustPrice": "Prezzo dell'ordine",
        "openPrice": "prezzo di apertura",
        "nowPrice": "prezzo attuale",
        "styPrice": "Prendi il prezzo di profitto",
        "stsPrice": "Prezzo di stop loss",
        "openTime": "Orario di apertura",
        "closeTime": "Orario di chiusura",
        "rate": "commissione di gestione",
        "nightFee": "Tariffa notturna",
        "loss": "Profitti e perdite",
        "setloss": "Imposta il take profit e lo stop loss",
        "expectProfit": "profitto atteso",
        "expectLoss": "perdita attesa",
        "allClose": "Chiudi tutte le posizioni",
        "moreClose": "Chiudi solo gli ordini lunghi",
        "nullClose": "Chiudi solo gli ordini brevi",
        "sureClose": "Confermare per chiudere la posizione?",
        "thanZearo": "Il valore impostato deve essere maggiore di 0",
        "listin": "ordine in attesa",
        "tdin": "nella transazione",
        "closein": "Chiusura della posizione",
        "closed": "Posizione chiusa",
        "revoked": "Revocato",
        "revokeOrder": "Sei sicuro di voler annullare l'ordine?",
        "ping": "Posizione chiusa",
        "revoke": "Annulla Ordine",
        "sureping": "Sei sicuro di chiudere la posizione?",
        "thanzone": "Il valore impostato deve essere maggiore di 0",
    },
    // 币币
    "cuy": {
        "direction": "direzione",
        "total": "totale",
        "price": "prezzo",
        "sell": "Vendere",
        "buy": "acquistare",
        "allStation": "Commercio dell'intero sito",
        "buyPrice": "prezzo di acquisto",
        "buynum": "Volume d'acquisto",
        "sellPrice": "Prezzo di vendita",
        "sellnum": "Volume di vendita",
        "tdPrice": "Volume delle transazioni",
        "or": "O",
        "tdStart": "Inizia a fare trading",
        "pbPrice": "Inserisci il prezzo di acquisto",
        "pbNum": "Inserisci l'importo dell'acquisto",
        "psPrice": "Inserisci il prezzo di vendita",
        "psNum": "Inserisci l'importo della vendita",
        "fixPrice": "limitare il commercio",
        "Mtrans": "transazione a prezzo di mercato",
        "available": "Disponibile",
        "revoke": "Annulla",
        "loading": "caricamento",
        "confirmCancel": "Sei sicuro di voler annullare?",
        "confirm": "Sicuro",
        "cancel": "Annulla",
        "nomore": "Niente più dati",
        "evelPrice": "prezzo medio della transazione",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Vuoi confermare il trasferimento?",
        "assets": "risorse",
        "currency_account": "Conto in monete",
        "lever_account": "Conto contrattuale",
        "fince_account": "Conto Fiat",
        "change_account": "conto delle transazioni",
        "micro_account": "secondo conto contrattuale",
        "miscroAccount": "secondo conto contrattuale",
        "assets_hua": "Trasferimento di fondi",
        "all_assets": "Totale asset convertiti",
        "currency": "Valuta",
        "canuse": "Disponibile",
        "frezz": "congelare",
        "conversion": "convertito",
        "charging": "Deposita monete",
        "withdraw": "Ritirare le monete",
        "address_bind": "Associa l'indirizzo di ritiro",
        "address_withdraw": "Gestione indirizzo ritiro monete",
        "record": "Dettagli",
        "tbrecord": "Registro dei prelievi di monete",
        "address_charge": "Indirizzo di deposito",
        "address_width": "Indirizzo di ritiro",
        "copy": "copia",
        "ercode": "QR Code",
        "reminder": "Consigli gentili",
        "notcharge": "Si prega di non depositare fondi all'indirizzo sopra indicato.",
        "ornone": ", in caso contrario i beni non verranno recuperati",
        "support": "I depositi supportano solo il metodo di invio semplice. I depositi che utilizzano altri metodi (invia tutto) non possono essere accreditati temporaneamente.",
        "minnum": "Importo minimo di prelievo",
        "ratenum": "commissione di gestione",
        "havenum": "Quantità in arrivo",
        "from": "da",
        "to": "a",
        "transfer": "trasferimento",
        "tfnum": "Quantità di trasferimento",
        "ptfnum": "Inserisci il minimo",
        "dtfnum": "Il numero di trasferimenti",
        "canbalance": "saldo disponibile",
        "sureConfirm": "Conferma trasferimento",
        "moneyRecord": "record finanziari",
        "pNum": "Inserisci l'importo del bonifico",
        "leverUse": "Sono disponibili conti contrattuali",
        "legalUse": "Sono disponibili conti Fiat",
        "changeUselg": "È disponibile un conto in monete",
        "changeUse": "Conti di trading disponibili",
        "microUse": "Conto contratto secondi disponibile",
        "look": "Controllo",
        "chargeRecord": "Registro del deposito",
        "status": "stato del monitoraggio",
        "noopen": "Questa funzione non è ancora disponibile",
        "enterAdderses": "Inserisci l'indirizzo di ritiro",
        "enterNum": "Inserisci l'importo del prelievo",
        "lessMin": "L'importo di prelievo inserito è inferiore al valore minimo",
        "tips01": "Si prega di non depositare altri beni all'indirizzo sopra indicato, altrimenti i beni non verranno recuperati.",
        "tips02": "I depositi supportano solo il metodo di invio semplice. I depositi che utilizzano altri metodi (invia tutto) non possono essere accreditati temporaneamente.",
        "beizhu": "Osservazione",
        "pbeizhu": "Inserisci commenti",
        "add": "aggiungere a",
        "addressList": "elenco indirizzi",
        "delete": "eliminare",
        "norec": "Nessuna registrazione",
        "selectCurrency": "Seleziona la valuta",
        "chainType": "tipo di catena",
        "commissionLibrary": "biblioteca della commissione",
    },
    // 账户设置
    "set": {
        "mycode2": "il mio codice di autorizzazione",
        "copycode2": "Copia il codice di autorizzazione",
        "uaccont": "Livello di sicurezza del tuo account:",
        "strong": "Potente",
        "middle": "Mezzo",
        "weak": "Debole",
        "min": "Basso",
        "heigh": "Alto",
        "complete": "Completa ulteriori informazioni per garantire la sicurezza dell'account",
        "mycode": "il mio codice invito",
        "copycode": "Copia il codice di invito",
        "bindphone": "Legare il cellulare",
        "bindemail": "Associa l'e-mail",
        "loginpwd": "password per il login",
        "noBind": "Non vincolato",
        "binds": "legame",
        "bind": "Vai alla rilegatura",
        "binded": "Limite",
        "net": "Gli account Internet sono a rischio di furto. Si consiglia di modificare regolarmente la password per proteggere la sicurezza del proprio account.",
        "reset": "Rivedere",
        "setPsw": "Imposta una password per la transazione per garantire la sicurezza della transazione.",
        "goSet": "Vai alle impostazioni",
        "bindAccount": "Vincolare il conto di scambio",
        "duihuan": "Vincola l'account per riscattare i punti",
        "copysuccess": "Copiato con successo",
        "copyfail": "Copia non riuscita",
        "recopy": "Per favore copia di nuovo",
        "account": "Account",
        "detail": "Dettagli",
        "val": "Quantità",
        "forgetPsw": "dimenticare la password",
        "code": "Codice di verifica",
        "getCode": "ottenere il codice di verifica",
        "setPsw": "impostare la password",
        "enterPsw": "Per favore, inserisci la password",
        "pswFalse": "Le password inserite due volte non sono coerenti",
        "enterPswagain": "Inserisci nuovamente la password",
        "jsetPsw": "Imposta la password della transazione",
        "jenterPsw": "Inserisci la password della transazione",
        "jenterPswagain": "Inserisci nuovamente la password della transazione",
        "enterAccount": "Per favore inserisci il nome utente",
        "enterCode": "inserisci il codice di verifica",
        "accountFalse": "Il cellulare o l'account email che hai inserito non rispetta le regole!",
        "second": "secondi",
        "sendCode": "Invia il codice di verifica",
        "sendsucc": "Inviato con successo",
        "enterPswTwo": "Inserisci la tua password due volte",
        "rest": "Residuo",
        "yes": "SÌ",
        "no": "NO",
        "yorn": "Se impostare una password per la transazione",
        "authenticate": "Si prega di eseguire la verifica dell'identità",
    },
    // 身份认证
    "auth": {
        "identity": "Autenticazione",
        "name": "Nome",
        "idcard": "ID",
        "upimg": "Carica le foto del tuo documento d'identità: la prima è quella anteriore, la seconda è quella posteriore e la terza è la foto anteriore con il documento d'identità.",
        "submit": "invia",
        "auditing": "in corso di revisione...",
        "certified": "verificato!",
        "pname": "Per favore digita il tuo nome",
        "pidcard": "Inserisci il tuo numero identificativo",
        "pimg": "Per favore carica una foto del tuo documento d'identità!",
    },
    // 收款方式
    "seting": {
        "pmethod": "metodo di pagamento",
        "truename": "nome vero e proprio",
        "opening": "nome della banca",
        "bank": "Numero della carta bancaria",
        "alipay": "Conto Alipay",
        "wename": "Soprannome di WeChat",
        "wechat": "Conto WeChat",
        "pname": "per favore inserisci il tuo vero nome",
        "popen": "Inserisci il nome della banca presso la quale è stato aperto il conto",
        "pcard": "Inserisci il numero della carta bancaria",
        "palipay": "Inserisci il numero del tuo conto Alipay",
        "pwname": "Inserisci il tuo nickname WeChat",
        "pwaccount": "Inserisci il tuo account WeChat",
        "pimg1": "Codice di pagamento WeChat",
        "pimg2": "Codice di pagamento Alipay",
    },
    // 帮助中心
    "news": {
        "seeall": "mostra tutto",
        "helpcenter": "Centro annunci",
        "back": "ritorno",
    },
    //登录
    "login": {
        "welcome": "Accedi per favore",
        "phoneLogin": "Accedi con il telefono",
        "emailLogin": "E-mail di accesso",
        "account": "account",
        "psw": "parola d'ordine",
        "login": "Login",
        "nouser": "Non sei ancora un utente WealthNest?",
        "goRegister": "Registrati ora e inizia a fare trading sulla piattaforma di trading di asset digitali leader a livello mondiale.",
        "registerFree": "registrazione gratuita",
        "psw6": "La password non può contenere meno di sei caratteri",
    },
    "register": {
        "register": "Registrati",
        "phoneRegister": "Registra il tuo telefono",
        "emailRegister": "registrazione email",
        "country": "nazionalità",
        "enterPhone": "Inserisci il numero di telefono",
        "enterEmail": "per favore inserisci la tua email",
        "psw16": "La password deve contenere tra 6 e 16 caratteri",
        "inviteCode": "Codice invito",
        "sel": "Necessario",
        "sel2": "Opzionale",
        "read": "Ho letto e sono d'accordo",
        "xieyi": "\"Accordo per gli utenti\"",
        "tips01": "Le informazioni sulla nazionalità non possono essere modificate dopo la registrazione, quindi assicurati di sceglierle in modo veritiero.",
        "tips02": "Le email di verifica potrebbero essere erroneamente identificate come spam, quindi controllale attentamente.",
        "tips03": "Conserva correttamente il numero di conto WealthNest e la password di accesso.",
        "tips04": "Non utilizzare la stessa password di accesso di altri siti web.",
        "emailFalse": "L'email che hai inserito non rispetta le regole",
    },
    // 积分兑换
    "jc": {
        "title": "JC scambia risorse digitali sulla piattaforma BEAU",
        "balance": "Bilancia",
        "cannum": "Quantità JC disponibile",
        "new": "Ultimo prezzo",
        "exnum": "Quantità di scambio",
        "pnum": "Inserisci la quantità JC",
        "canex": "Quantità di BEAU che può essere riscattata",
        "exnow": "Riscattare ora",
        "record": "Registro di scambio",
        "exprice": "prezzo di cambio",
        "extime": "Tempo di riscatto",
        "not": "Punti disponibili insufficienti",
        "usenum": "Utilizza la quantità JC",
        "out": "esentato",
        "gotd": "andare a commerciare",
    },
    "coin": {
        "manage": "gestione finanziaria",
        "total": "deposito totale",
        "bao": "depositare il tesoro",
        "mybao": "Il mio deposito",
        "profit": "Entrate stimate",
        "cuntime": "Tempo di deposito delle monete",
        "endtine": "Data di scadenza",
    },
    "team": {
        "myteam": "la mia squadra",
        "ztnum": "Numero di referral diretti",
        "activenum": "Numero di membri attivi della squadra",
        "profit": "reddito",
        "back": "Sconto",
        "cblog": "Registro del deposito",
        "bblog": "registro delle monete",
    },
    // k线
    "kline": {
        "text1": "condivisione del tempo",
        "text2": "1 minuto",
        "text3": "5 minuti",
        "text4": "15 minuti",
        "text5": "30 minuti",
        "text6": "1 ora",
        "text7": "1 giorno",
        "text8": "1 settimana",
        "text9": "Gennaio",
    },
    // 秒合约
    "miscro": {
        "trade": "nella transazione",
        "buyPrice": "prezzo d'acquisto",
        "finshPrice": "prezzo finale",
        "loss": "Profitti e perdite stimati",
        "times": "Conto alla rovescia",
        "mode": "modello commerciale",
        "num": "Quantità di posizione aperta",
        "rate": "Redditività",
        "up": "Acquista",
        "down": "Compra o vendi",
        "openNum": "Inserisci la quantità di apertura",
        "success": "ordinato con successo!",
        "c2c": "Conto C2C",
        "complaint": "Suggerimenti",
        "reply": "Per favore scrivi la tua domanda e ti risponderemo il prima possibile...",
        "complaintList": "Elenco dei suggerimenti per i reclami",
        "complaintReply": "rispondere:",
        "complaintDescription": "Inserisci una descrizione",
        "resetpwd": "cambiare la password",
        "foundedOn": "Costruito dentro",
        "commonProblem": "problema comune",
        "statement": "spiegare",
        "about": "A proposito di WealthNest",
        "everyone": "Tutti sono amministratori delegati",
        "program": "Programma partner globale WealthNest",
        "program2": "Programma partner",
        "myMine": "La mia miniera:",
        "friend": "I miei amici minatori:",
        "myRank": "Il mio livello:",
        "accumulated": "Commissione cumulativa:",
        "copyLinks": "Copia il link della promozione",
        "moneyVein": "Trasformare le connessioni in denaro",
        "example": "Esempio della Commissione",
        "enterQuantity": "Inserisci la quantità",
        "contractBalance": "Saldo del conto del contratto",
        "submitRepeatedly": "L'invio è in corso, si prega di non inviare ripetutamente",
        "alipayCode": "Codice di pagamento Alipay",
        "wechatCode": "Codice di pagamento WeChat",
        "realPrice": "prezzo in tempo reale",
        "currencyExchange": "Riscattare la valuta",
        "currencyExchangeIn": "Riscattare la valuta",
        "cashableBalance": "Saldo disponibile per il riscatto:",
        "minimumCashable": "Importo minimo di rimborso:",
        "maximumCashable": "Incasso massimo:",
        "automaticallys": "Dopo aver fatto clic su Riscatta, verrà completato automaticamente e non potrà essere restituito.",
        "match": "scambio",
        "title": "Scambio di beni",
        "holdAssets": "detenere beni",
        "dangerousCurrency": "Tieni monete a rischio",
        "convertibleQuantity": "Quantità riscattabile",
        "currencyExchanges": "Tasso di cambio:",
        "insuranceCurrency": "Valuta assicurativa",
        "insuranceType": "tipo assicurativo",
        "contractAsset": "Importo dell'assicurazione del bene contrattuale",
        "warehouses": "Numero di magazzini assicurativi",
        "availableAssets": "Risorse disponibili:",
        "insuredAssets": "Beni assicurati:",
        "insuredAssets1": "Beni assicurati",
        "insuranceAssets": "Attività assicurative:",
        "purchaseInsurance": "Abbonamento per l'assicurazione",
        "insuranceClaims": "richieste di risarcimento assicurativo",
        "insuranceCancellation": "Cancellazione dell'assicurazione",
        "coinWallet": "Portamonete assicurativo",
        "bmbWallet": "Portafoglio HAP",
        "cumulativeCoins": "Monete accumulate:",
        "availableQuantity": "Quantità disponibile:",
        "rawCurrency": "Registro delle monete",
        "contractInsurance": "contratto assicurativo",
        "tenThousand": "Dieci mila",
        "runningLow": "Equilibrio insufficiente",
        "purchase": "La quantità inserita non è conforme alle regole e il limite di acquisto rientra",
        "reach": "a",
        "between": "fra",
        "onlyEnter": "Puoi solo entrare",
        "integersBetween": "intero tra",
        "notReturned": "Facendo clic per sottoscrivere un'assicurazione, si ritiene di aver compreso in dettaglio il contratto di assicurazione e di aver accettato che il deposito assicurativo entrerà in vigore automaticamente e non potrà essere restituito.",
        "settled": "Quando la perdita dei beni assicurati rende impossibile effettuare un ordine, verranno avanzate richieste di risarcimento assicurativo",
        "profitable": "volte in cui il bene realizza un profitto",
        "terminated": ", l'assicurazione verrà automaticamente disdetta.",
        "automatically": "Quando i beni assicurati perdono il 50%, è necessario richiedere un risarcimento assicurativo, altrimenti non sarà possibile effettuare operazioni. Quando il profitto è del 100%, l'assicurazione verrà automaticamente interrotta. Il magazzino assicurativo può pagare solo due risarcimenti a giorno Se superi due volte, l'indennità verrà pagata su t+1.",
        "termination": "Dopo la disdetta del contratto assicurativo gli averi assicurati possono essere riscattati. Dopo la disdetta del contratto viene considerata una violazione del contratto e il deposito assicurativo viene automaticamente svuotato.",
        "just": "Appena",
        "back": "opposto",
        "lowestNumber": "La quantità minima è",
        "confirmExchange": "Confermare il riscatto?",
        "contractValuation": "Valutazione patrimoniale del conto contrattuale",
        "secondValuation": "Valutazione patrimoniale del secondo conto contrattuale",
        "falshValuation": "Valutazione patrimoniale del conto di scambio istantaneo",
        "c2cValuation": "Valutazione patrimoniale del conto C2C",
        "recordWithdrawal": "Registri di deposito e prelievo",
        "category": "categoria",
        "safetyCenter": "Centro di sicurezza",
        "safeText1": "Utilizzato per ricevere messaggi SMS di verifica durante la registrazione, la modifica delle password e l'impostazione della sicurezza",
        "safeText2": "Gli account Internet sono a rischio di furto. Si consiglia di modificare regolarmente la password per proteggere la sicurezza del proprio account.",
        "flashTrading": "Transazione lampo",
        "assetCenter": "Centro risorse",
        "promotionCode": "Il mio codice promozionale",
        "loginAgain": "Login scaduto, effettua nuovamente l'accesso",
        "text10": "Prezzi in tempo reale basati su HAP",
        "text11": "Codice di autorizzazione",
    }
}
