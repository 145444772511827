export default {
    "language": "Krio",
    // 导航
    "zdy": {
        "tjsq": "sɛn aplikeshɔn",
        "czsl": "Richaj kwantiti",
        "enterczsl": "Duya, rayt di mɔni we yu go chaj",
        "qbdz": "adrɛs fɔ di walet",
        "enterqbdz": "Duya, rayt di walet adrɛs",
        "sftjmcj": "If fɔ sɛn di ɔda sɛl prays",
        "sftjmrj": "If yu fɔ sɛn di prayz we yu bay di ɔda",
        "proloss": "Tek prɔfit ɛn stɔp fɔ lɔs",
    },
    "header": {
        "home": "fɔs pej",
        "markets": "Tɔk dɛn",
        "fiat": "Fiat mɔni tredin",
        "c2c": "C2C transakshɔn",
        "exchange": "Kripto kɔrɛnsi tredin",
        "lever": "kɔntrakt tredin",
        "micro": "sɛkɔn kɔntrakt tredin",
        "myshop": "mi shɔp",
        "assets": "mi prɔpati dɛn",
        "help": "Ɛp Sɛnta",
        "login": "Log in fɔ go insay",
        "sign": "rɛjista",
        "accountSet": "di sɛtin dɛn fɔ di akɔn",
        "tradeLog": "transakshɔn lɔg",
        "receiveSet": "Di sɛtin dɛn fɔ pe",
        "identify": "Ɔthɛntishɔn",
        "jchange": "Kɔrɛnt ɛkshɛnj",
        "logout": "sayn aut",
        "themedark": "Nayt mod",
        "themeday": "de mod fɔ de",
    },
    "frame": {
        "a": "Yu gɛt nyu withdrawal ɔda stetɔs, duya klik fɔ si am",
        "b": "nɔ",
        "c": "Shɔ",
        "con": "Diya yuza dɛm, bikɔs bɔku mɛmba dɛn de we de tred na di tredin sɛnta. ",
    },
    // 登录注册
    "lg": {
        "login": "Log in fɔ go insay",
        "register": "rɛjista",
    },
    // 首页
    "home": {
        "vol": "24H kwantiti",
        "c01": "Di wɔl in lida dijital ɛset tredin pletfɔm",
        "c02": "Gi sef ɛn rili dijital ɛset tredin ɛn ɛset manejmɛnt savis to bɔku bɔku pipul dɛn we de yuz am na pas 130 kɔntri dɛn ɔlsay na di wɔl",
        "c03": "Sef ɛn pɔsin we pɔsin kin abop pan",
        "c04": "5 ia ɛkspiriɛns na dijital ɛset faynɛns savis",
        "c05": "Prɔfɛshɔnal distribyushɔn akitɛkɛt ɛn anti-DDOS atak sistɛm",
        "c06": "Globɛl ikɔlɔjik layout",
        "c07": "Dɛn dɔn mek lokaliz tredin savis sɛnta dɛn na bɔku kɔntri dɛn",
        "c08": "Krio wan blɔkchɛn ɛkosistim we de intagret bɔku biznɛs fɔm dɛn",
        "c09": "Yuz fɔs",
        "c10": "Establish wan advans kɔmpɛnshɔn mɛkanism",
        "c11": "Establish wan invashɔn protɛkshɔn fɔnd",
        "c12": "Multi-platform terminal tredin eni taim, eniwe",
        "c13": "I de kɔba bɔku bɔku pletfɔm dɛn we inklud iOS, Andrɔyd, ɛn Windows, we de sɔpɔt ful biznɛs fɛnshɔn dɛn",
        "sweep": "Skan kɔd fɔ dawnlod",
        "android": "Andrɔyd",
        "pinput": "Duya, rayt yu imel ɔ mobayl fon nɔmba",
        "atrade": "Rɛjista wan WealthNest Global Steshɔn akɔn ɛn stat yu tredin joyn",
        "download": "Daunlod di APP",
        "ios": "IOS dawnlod",
        "androidDownload": "Andrɔyd fɔ dawnlod",
        companyoverview1: "Sef",
        companyoverview2: "5 ia ɛkspiriɛns na dijital ɛset faynɛns savis Prɔfɛshɔnal distribyushɔn akitɛkɛt ɛn anti DDoS Atak Sistɛm.",
        companyoverview3: "Tru",
        companyoverview4: "Kwik"
    },
    // 底部
    "foo": {
        "about": "bɔt Wi",
        "support": "Yuz sɔpɔt",
        "contact": "kɔntakt wi",
        "qq": "Kastamɔ savis QQ",
        "email": "Kontakt imel",
    },
    // 行情
    "market": {
        "handicap": "Di wan dɛn we nɔ ebul fɔ waka",
        "exchange": "kɔyn dɛn",
        "selfmarket": "Pɔsibul",
        "allmarket": "Ɔl di kot dɛn",
        "symbol": "tredin pe fɔ di wan dɛn we de du biznɛs",
        "newprice": "Di las prayz",
        "change": "Mɔ",
        "highprice": "di prayz we pas ɔl",
        "lowprice": "di prayz we smɔl pas ɔl",
        "vol": "24H kwantiti",
    },
    // 公共交易
    "td": {
        "buy": "Bay",
        "sell": "sɛl",
        "currency": "Mɔni",
        "num": "ɔmɔs",
        "time": "tɛm",
        "limit": "limit",
        "price": "yunit prays",
        "method": "di we aw dɛn kin pe",
        "total": "wan wan mɔni",
        "inwant": "Duya, rayt wetin yu want",
        "do": "ɔpreshɔn",
        "more": "lod mɔ",
        "nomore": "nɔ go de igen",
        "nodata": "No data nɔ de",
        "trade": "bay ɛn sɛl",
        "buynum": "Kwantiti we yu bay",
        "sellout": "sɛl",
        "buyin": "Bay",
        "all": "ɔl",
        "allbuy": "bay ɔl",
        "allsell": "sɛl ɔltin",
        "buytotal": "Duya rayt di ɔl mɔni we yu want fɔ bay",
        "selltotal": "Duya, rayt di ɔl mɔni we yu want fɔ sɛl",
        "buyallnum": "Duya, rayt di kwantiti we yu want fɔ bay",
        "sellallnum": "Duya, rayt di kwantiti we yu want fɔ sɛl",
        "tradeTotal": "totɛl transakshɔn mɔnt",
        "doceil": "Kansel ɔtomɛtik wan afta sɛkɔn",
        "place": "Mek wan ɔda tin",
        "waitpay": "We dɛn de wet fɔ pe",
        "paycode": "di kɔd fɔ pe",
        "finished": "dɔn dɔn",
        "ceiled": "Dɛn dɔn kansel am",
        "payed": "Dɛn dɔn pe am ɔlrɛdi",
        "nofinish": "nɔ dɔn du am",
        "buyer": "pɔsin we de bay",
        "seller": "di pɔsin we de sɛl",
        "callWay": "Kɔntakt infɔmeshɔn",
        "placeTime": "ɔda tɛm",
        "renum": "Rifrɛns Nɔmba",
        "canceil": "nɔ",
        "confirm": "mek wi biliv",
        "pwd": "Duya, rayt di transakshɔn paswɔd",
        "coincode": "Yunit fɔ Kɔrɛnt",
        "pcoin": "Duya pik di kɔrɛnt yunit",
        "setTradePwd": "Set transakshɔn paswɔd?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Ɔda rɛkɔd",
        "fatdLog": "Ligal kɔrɛnt transakshɔn rɛkɔd dɛn",
        "shoper": "Biznɛsman",
        "faLog": "Ligal kɔrɛnt transakshɔn rɛkɔd dɛn",
        "tdType": "Di Tayp fɔ Transakshɔn",
        "odStatus": "Di Status fɔ Ɔda",
        "pwaitPay": "Duya wet fɔ di pɔsin we bay di pe",
        "odFinish": "Ɔda dɔn dɔn",
        "odCeil": "Ɔda we dɛn dɔn kansel",
        "odPay": "Dɛn dɔn pe, duya chɛk di infɔmeshɔn bɔt di pe gud gud wan",
        "tdCeil": "kansel di dil",
        "pCeil": "If yu dɔn pe di pɔsin we sɛl am, duya nɔ kansel di transakshɔn.",
        "paySure": "Di kɔnfɔmeshɔn fɔ pe",
        "pdopay": "Duya kɔnfirm se yu dɔn pe di pɔsin we sɛl am",
        "badClick": "If yu klik bad bad wan, i go friz di akɔn dairekt wan",
        "receivePay": "Di kɔnfɔmeshɔn fɔ pe",
        "payReceive": "Duya kɔnfirm se di pɔsin we bay di tin dɔn pe yu",
        "receivePays": "Kɔnfɛm di pe we dɛn dɔn gɛt",
        // LegalPay
        "payPlease": "duya una pe",
        "tdPrice": "Transakshɔn yunit prayz",
        "tdNum": "Nɔmba fɔ di transakshɔn dɛn",
        "payinfo": "pemɛnt info",
        "banknum": "Bank Akɔn",
        "realName": "rial nem",
        "shoperZname": "Branch we de sɛl biznɛs",
        "shoperNum": "di biznɛsman dɛn akɔn",
        "odCeil": "kansel di ɔda",
        "imPay": "A dɔn pe, klik fɔ kɔnfɔm",
        // LegalSeller
        "register_time": "Di tɛm fɔ rɛjista",
        "odtotal": "Asɛmbli ɔda",
        "odmonth": "Ɔda dɔn dɔn di 30 dez",
        "odfinish": "Kɔmplit ɔda",
        "odrate": "Di rit we dɛn dɔn kɔmplit",
        "submit": "fri",
        "phone": "mobayl fon",
        "mysell": "mi fɔ sɛl",
        "mybuy": "Mi bay bay",
        "realAuth": "Dɛn dɔn chɛk am",
        "advAuth": "Advans sɛtifiket",
        "linebuy": "bay na di intanɛt",
        "linesell": "fɔ sɛl na di intanɛt",
        "tipPay": "Duya kɔntak di biznɛsman insay 24 awa fɔ pe If i pas 24 awa, dɛn go kansel am ɔtomɛtik.",
        "notlow": "No kin go dɔŋ di minimum",
        "nothigh": "Nɔ ebul fɔ pas maksimal limit",
        "notnum": "Nɔ kin pas maksimal kwantiti",
        // LegalShopDetail
        "type": "kayn",
        "status": "stet",
        "seeOrder": "chɛk ɔda",
        "withdraw": "pul an pan",
        "abnormal": "abnɔmal tin",
        "lowershelf": "Dɛn kin pul am na di shelf dɛn",
        "protection": "Rayt protɛkshɔn de go bifo",
        "apply": "Aplay fɔ mek dɛn protɛkt yu rayt",
        "psType": "Duya pik di kayn we",
        "pselect": "duya una pik",
        "alipay": "Alipay we dɛn kɔl",
        "wechat": "WiChat wi de du",
        "bank": "bank kad we de na di bank",
        "minNum": "Minimum transakshɔn volyum",
        "maxNum": "maksimal tredin volyum",
        "wantBuy": "Want fɔ bay",
        "pnums": "Duya, rayt di kwantiti",
        "pmin": "Duya, rayt di minimum transakshɔn volyum",
        "pmax": "Duya, ɛnta di maksimam transakshɔn volyum",
        "pnot": "Di maksimam transakshɔn volyum nɔ go ebul fɔ smɔl pas di minim transakshɔn volyum",
        // MyLegalShops
        "myshops": "mi shɔp",
        "name": "nem",
        "fiatSub": "Ligal kɔrɛnt",
        "shoper_balance": "Di balans we di biznɛsman dɛn gɛt",
        "enterShop": "go insay wan shɔp",
        // shop_legal_pay_detail
        "payedWait": "Dɛn dɔn pe, dɛn de wet fɔ verify ɛn kɔnfɔm",
        "yes": "Yɛs",
        "no": "Nɔ",
        "sd": "Lɔk balans",
        "journal": "Balans lɔg",
        "business": "Biznɛsman",
        "user": "yuzman we de yuz am",
        "backdone": "Dɛn dɔn pul am kɔmɔt",
        "busbalance": "Di balans we di biznɛsman dɛn gɛt",
        "userbalance": "Yuzman balans",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C we na mi bin pablish",
        "myTrade": "C2C na mi transakshɔn",
        "and": "rayt",
        "balance": "Tink di rayt we",
        "buynum": "Bay volyum",
        "sellnum": "Volyum we de sɛl",
        "bankTransfer": "Bank Transfa we dɛn de du",
        "payim": "I fɔ pe insɛf",
        "pbuyNum": "Duya, rayt di kwantiti we yu bay",
        "pprice": "Duya, rayt di yunit prays",
        "payway": "Duya pik di we aw yu go pe",
        "psellNum": "Duya, rayt di kwantiti we yu de sɛl",
        "suerePlace": "Kɔnfɛm ɔda?",
        "detail": "Tin dɛn",
        "payinfo": "pemɛnt info",
        "Payee": "Payee we yu de pe am",
        "total": "wan wan mɔni",
        "name": "Nem",
        "cardnum": "kad nɔmba",
        "receivePay": "Kɔnfɛm di pe we dɛn dɔn gɛt",
        "ceilConfirm": "Kɔnfɛm fɔ kansel di transakshɔn?",
        "paySeller": "Duya kɔnfɔm se yu dɔn pe di pɔsin we sɛl am.",
        "payBuyer": "Duya kɔnfirm se yu dɔn gɛt pemɛnt infɔmeshɔn frɔm di pɔsin we bay",
        "account": "akawnt",
        "sellerPay": "Duya kɔnfɔm se di pɔsin we bay di tin dɔn pe yu?",
        "payattion": "Duya pe di pɔsin we sɛl am kwik kwik wan afta dɛn dɔn kɔnfyus di pemɛnt.",
        "log": "c2c komplit oda",
    },
    // 合约
    "lever": {
        "nowentrust": "Di ɔda we aw dɛn de du am naw",
        "hisentrust": "istri kɔmishɔn",
        "lvchi": "Kɔntrakt pozishɔn dɛn",
        "all": "ɔl",
        "or": "ɔ",
        "gotrade": "Start fɔ trade",
        "tdrecord": "Transakshɔn Rikɔd",
        "dealed": "Dil dɔn dɔn",
        "notdeal": "Nɔ transakshɔn nɔ de",
        "loading": "we yu de lod...",
        "entotal": "Tɔtɔl mɔni we dɛn dɔn trɔs",
        "canuse": "De",
        "std": "makit prayz transakshɔn",
        "xtd": "limit tred",
        "pprice": "Duya, rayt di prayz",
        "phand": "Duya, rayt di sayz fɔ di lɔt we yu bay",
        "phandsell": "Duya, rayt di sayz we yu de sɛl",
        "pwd": "paswɔd",
        "ppwd": "Duya, rayt di transakshɔn paswɔd",
        "psw": "transakshɔn paswɔd",
        "equal": "ikwal",
        "times": "tɛm",
        "timed": "bɔku bɔku wan",
        "hand": "an",
        "hands": "nɔmba fɔ di stɛp dɛn",
        "ptimes": "Duya pik bɔku bɔku wan",
        "phands": "Duya pik di sayz fɔ di lɔt",
        "contractVal": "Kontrakt makɛt valyu",
        "bail": "kɔna say",
        "canBail": "Margin we de",
        "charge": "Transakshɔn savis fi",
        "domore": "Bay (go lɔng) .",
        "doshort": "Sel (shɔt) .",
        "dmore": "Go lɔng",
        "dshort": "shɔt",
        "sureOd": "Kɔnfɛm ɔda tin dɛn",
        "noless": "Di lɔt saiz nɔ go ebul fɔ smɔl pas",
        "nomore": "Lot saiz nɔ kin ay pas",
        "tdnum": "Volyum fɔ tred",
        "risk": "risk rεt",
        "allloss": "Tɔtɔl prɔfit ɛn lɔs pan pozishɔn dɛn",
        "onehouse": "Klos wan pozishɔn wit wan klik",
        "type": "kayn",
        "entrustPrice": "Ɔda prayz",
        "openPrice": "prays fɔ opin",
        "nowPrice": "di prayz we de naw",
        "styPrice": "Tek prɔfit prayz",
        "stsPrice": "Stɔp fɔ lɔs prayz",
        "openTime": "Di tɛm we dɛn kin opin",
        "closeTime": "Di tɛm fɔ klos",
        "rate": "di fi fɔ handle",
        "nightFee": "Di fi fɔ wan nɛt",
        "loss": "Profit ɛn lɔs",
        "setloss": "Set tek profit ɛn stɔp lɔs",
        "expectProfit": "prɔfit we dɛn bin de op fɔ gɛt",
        "expectLoss": "we dɛn bin de op fɔ lɔs",
        "allClose": "Klos ɔl di pozishɔn dɛn",
        "moreClose": "Na klos lɔng ɔda dɛn nɔmɔ",
        "nullClose": "Na shɔt ɔda dɛn nɔmɔ kin lɔk",
        "sureClose": "Kɔnfɛm fɔ lɔk di pozishɔn?",
        "thanZearo": "Di valyu we dɛn sɛt fɔ pas 0",
        "listin": "we dɛn de wet fɔ ɔda",
        "tdin": "insay transakshɔn",
        "closein": "Pozishɔn we de klos",
        "closed": "Pozishɔn we dɛn dɔn lɔk",
        "revoked": "Dɛn dɔn pul am kɔmɔt",
        "revokeOrder": "Yu shɔ se yu want fɔ kansel di ɔda?",
        "ping": "Klos pozishɔn",
        "revoke": "Kansel di ɔda",
        "sureping": "Yu shɔ se yu go lɔk di pozishɔn?",
        "thanzone": "Di valyu we dɛn sɛt fɔ pas 0",
    },
    // 币币
    "cuy": {
        "direction": "we",
        "total": "ɔl",
        "price": "prays",
        "sell": "Sɛl",
        "buy": "bay",
        "allStation": "Whole sayt tredin we dɛn de du",
        "buyPrice": "prayz fɔ bay",
        "buynum": "Bay volyum",
        "sellPrice": "Prays fɔ sɛl",
        "sellnum": "Volyum we de sɛl",
        "tdPrice": "Transakshɔn volyum",
        "or": "ɔ",
        "tdStart": "Start fɔ trade",
        "pbPrice": "Duya, rayt di prayz we yu bay",
        "pbNum": "Duya, rayt di mɔni we yu bay",
        "psPrice": "Duya, rayt di prayz we yu de sɛl",
        "psNum": "Duya, rayt di mɔni we yu sɛl",
        "fixPrice": "limit tred",
        "Mtrans": "makit prayz transakshɔn",
        "available": "De",
        "revoke": "nɔ",
        "loading": "we dɛn de lod",
        "confirmCancel": "Yu shɔ se yu want fɔ kansel?",
        "confirm": "Shɔ",
        "cancel": "nɔ",
        "nomore": "No mɔ data nɔ de",
        "evelPrice": "avrej transakshɔn prays",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Yu wan konfam di transfa?",
        "assets": "valyu tin dɛn",
        "currency_account": "Kɔyn akɔn",
        "lever_account": "Kɔntrakt akɔn",
        "fince_account": "Fiat akɔn",
        "change_account": "transakshɔn akɔn",
        "micro_account": "sɛkɔn kɔntrakt akɔn",
        "miscroAccount": "sɛkɔn kɔntrakt akɔn",
        "assets_hua": "Fɔ transfa mɔni",
        "all_assets": "Tɔtɔl ɛset dɛn we dɛn dɔn kɔnvɔyt",
        "currency": "Mɔni",
        "canuse": "De",
        "frezz": "friz",
        "conversion": "we dɛn dɔn chenj",
        "charging": "Dipɔsit kɔyn dɛn",
        "withdraw": "Put di kɔyn dɛn",
        "address_bind": "Bind di adrɛs fɔ pul di mɔni",
        "address_withdraw": "Kɔyn witdraw adrɛs manejmɛnt",
        "record": "Tin dɛn",
        "tbrecord": "Rikɔd fɔ pul kɔyn",
        "address_charge": "Dipɔsit adrɛs",
        "address_width": "Adrɛs fɔ pul mɔni",
        "copy": "kɔpi",
        "ercode": "QR kod",
        "reminder": "Tips we gud fɔ yu",
        "notcharge": "Duya nɔ put ɛni mɔni na di adrɛs we de ɔp.",
        "ornone": ", if nɔto dat, dɛn nɔ go gɛt bak di prɔpati dɛn",
        "support": "Dipɔsit dɛn jɔs de sɔpɔt di simpul we fɔ sɛn Dipɔsit dɛn we de yuz ɔda we dɛn (sɛn ɔl) nɔ go ebul fɔ gi kredit fɔ sɔm tɛm.",
        "minnum": "Minimum di mɔni we yu fɔ pul",
        "ratenum": "di fi fɔ handle",
        "havenum": "Di kwantiti we pɔsin kin kam",
        "from": "frɔm",
        "to": "to",
        "transfer": "transfa",
        "tfnum": "Transfa kwantiti",
        "ptfnum": "Duya, rayt di minimum",
        "dtfnum": "Di nɔmba fɔ di wan dɛn we dɛn transfa",
        "canbalance": "Balans we De",
        "sureConfirm": "Kɔnfɛm fɔ transfa",
        "moneyRecord": "di faynɛns rɛkɔd dɛn",
        "pNum": "Duya, rayt di mɔni we yu go transfa",
        "leverUse": "Kɔntrakt akɔn dɛn de",
        "legalUse": "Fiat akaunt dɛn de fɔ yu",
        "changeUselg": "Coin akaunt de fɔ yu",
        "changeUse": "Trading akɔn dɛn we de",
        "microUse": "Sekɔnd kɔntrakt akɔn de",
        "look": "Chɛk",
        "chargeRecord": "Dipɔsit rɛkɔd",
        "status": "di stetɔs we dɛn de trak",
        "noopen": "Dis funkshɔn nɔ de yet",
        "enterAdderses": "Duya rayt di adrɛs fɔ pul di mɔni",
        "enterNum": "Duya, rayt di mɔni we yu go pul",
        "lessMin": "Di mɔni we dɛn put fɔ pul di mɔni we dɛn dɔn put insay, smɔl pas di valyu we smɔl pas ɔl",
        "tips01": "Duya nɔ put ɛni ɔda prɔpati na di adrɛs we de ɔp, ɔdasay dɛn nɔ go tek di prɔpati dɛn bak.",
        "tips02": "Dipɔsit dɛn jɔs de sɔpɔt di simpul we fɔ sɛn Dipɔsit dɛn we de yuz ɔda we dɛn (sɛn ɔl) nɔ go ebul fɔ gi kredit fɔ sɔm tɛm.",
        "beizhu": "Rimak",
        "pbeizhu": "Duya, rayt kɔmɛnt dɛn",
        "add": "Add to",
        "addressList": "adrɛs list",
        "delete": "dilit",
        "norec": "No rεkɔd nɔ de",
        "selectCurrency": "Duya pik di mɔni we yu de yuz",
        "chainType": "chen tayp",
        "commissionLibrary": "kɔmishɔn laybri",
    },
    // 账户设置
    "set": {
        "mycode2": "mi ɔtorizeshɔn kɔd",
        "copycode2": "Kɔpi ɔtorizeshɔn kɔd",
        "uaccont": "Yu akɔn sikyɔriti lɛvɛl:",
        "strong": "pawaful",
        "middle": "midul",
        "weak": "wik",
        "min": "Lo",
        "heigh": "ay",
        "complete": "Kɔmplit mɔ infɔmeshɔn fɔ mek shɔ se di akɔn sikyɔriti",
        "mycode": "mi inviteshɔn kɔd",
        "copycode": "Kɔpi di inviteshɔn kɔd",
        "bindphone": "Bind mobayl fon",
        "bindemail": "Bind imel fɔ yu",
        "loginpwd": "login paswɔd",
        "noBind": "Nɔto we dɛn tay am",
        "binds": "we dɛn kin tay",
        "bind": "Go to binding",
        "binded": "Tay",
        "net": "Intanɛt akɔn dɛn de pan denja fɔ mek dɛn tif dɛn I fayn fɔ chenj yu paswɔd ɔltɛm fɔ protɛkt di sikyɔriti na yu akɔn.",
        "reset": "Rivishɔn",
        "setPsw": "Sɛt wan transakshɔn paswɔd fɔ mek shɔ se di transakshɔn sikyɔriti.",
        "goSet": "Go na di say we se sɛtin dɛn",
        "bindAccount": "Bind ɛkshɛnj akɔn",
        "duihuan": "Bind akɔn fɔ ridim poɛnt dɛn",
        "copysuccess": "Dɛn kɔpi am fayn fayn wan",
        "copyfail": "Kɔpi nɔ bin wok",
        "recopy": "Duya kɔpi bak",
        "account": "Akawnt",
        "detail": "Tin dɛn",
        "val": "Ɔmɔs",
        "forgetPsw": "fɔgɛt di paswɔd",
        "code": "Verifikeshɔn kɔd",
        "getCode": "gɛt verifyeshɔn kɔd",
        "setPsw": "sɛt paswɔd",
        "enterPsw": "Duya, rayt paswɔd",
        "pswFalse": "Di paswɔd dɛn we yu put tu tɛm nɔ gri",
        "enterPswagain": "Duya, rayt paswɔd bak",
        "jsetPsw": "Set transakshɔn paswɔd",
        "jenterPsw": "Duya, rayt di transakshɔn paswɔd",
        "jenterPswagain": "Duya, rayt di transakshɔn paswɔd bak",
        "enterAccount": "Duya, put Yuz Nem",
        "enterCode": "duya ɛnta verifyeshɔn kɔd",
        "accountFalse": "Di mobayl fon ɔ imel akɔn we yu put nɔ de fala di lɔ dɛn!",
        "second": "sɛkɔn",
        "sendCode": "Send di verifyeshɔn kɔd",
        "sendsucc": "Dɛn sɛn am fayn fayn wan",
        "enterPswTwo": "Duya, rayt yu paswɔd tu tɛm",
        "rest": "De lɛf",
        "yes": "Yɛs",
        "no": "Nɔ",
        "yorn": "If yu fɔ sɛt transakshɔn paswɔd",
        "authenticate": "Duya du fɔ chɛk yu aydentiti",
    },
    // 身份认证
    "auth": {
        "identity": "Ɔthɛntishɔn",
        "name": "Nem",
        "idcard": "ID we gɛt fɔ du wit am",
        "upimg": "Duya, ɔplod ID foto dɛn, di fɔs wan na di fɔs, di sɛkɔn wan na di bak, ɛn di tɔd wan na di fɔs foto we ol di ID.",
        "submit": "rɛdi fɔ de ɔnda",
        "auditing": "ɔnda rivyu...",
        "certified": "verified!",
        "pname": "Duya, rayt yu nem",
        "pidcard": "Duya, rayt yu ID nɔmba",
        "pimg": "Duya, ɔplod wan pikchɔ fɔ yu ID!",
    },
    // 收款方式
    "seting": {
        "pmethod": "di we aw dɛn kin pe",
        "truename": "rial nem",
        "opening": "Bank nem",
        "bank": "Bank kad nɔmba",
        "alipay": "Alipay akɔn",
        "wename": "WeChat niknem we wi de yuz",
        "wechat": "WeChat akɔn",
        "pname": "duya rayt yu rial nem",
        "popen": "Duya rayt di nem fɔ di bank usay dɛn opin di akɔn",
        "pcard": "Duya, rayt bank kad nɔmba",
        "palipay": "Duya, rayt yu Alipay akɔn nɔmba",
        "pwname": "Duya, rayt yu WeChat niknem",
        "pwaccount": "Duya, ɛnta yu WeChat akɔn",
        "pimg1": "WeChat pemɛnt kɔd",
        "pimg2": "Alipay pemɛnt kɔd",
    },
    // 帮助中心
    "news": {
        "seeall": "luk ɔl",
        "helpcenter": "Anɔnsmɛnt Sɛnta",
        "back": "go bak",
    },
    //登录
    "login": {
        "welcome": "Login duya",
        "phoneLogin": "Log in wit fon",
        "emailLogin": "Imel fɔ Lɔgin",
        "account": "akawnt",
        "psw": "paswɔd",
        "login": "Log in fɔ go insay",
        "nouser": "Nɔto WealthNest yuza yet?",
        "goRegister": "Rɛjista naw ɛn bigin fɔ tred na di wɔl in lida dijital ɛset tredin pletfɔm.",
        "registerFree": "fri fɔ rɛjista",
        "psw6": "Paswɔd nɔ go ebul fɔ smɔl pas siks aks",
    },
    "register": {
        "register": "rɛjista",
        "phoneRegister": "Rɛjista yu fon",
        "emailRegister": "imel rɛjista",
        "country": "us kɔntri yu kɔmɔt",
        "enterPhone": "Duya, rayt di fon nɔmba",
        "enterEmail": "duya put yu imel",
        "psw16": "Paswɔd fɔ de bitwin 6-16 aks dɛn",
        "inviteCode": "Inviteshɔn kɔd",
        "sel": "Fɔ du",
        "sel2": "Pɔsibul",
        "read": "A dɔn rid ɛn gri",
        "xieyi": "\"Yuz Agremɛnt\"",
        "tips01": "Yu nɔ go ebul fɔ chenj di infɔmeshɔn bɔt di kɔntri we yu kɔmɔt afta yu dɔn rɛjista, so mek shɔ se yu pik am tru tru.",
        "tips02": "Dɛn kin mistek no se di imel dɛn we dɛn kin yuz fɔ chɛk di tin dɛn we dɛn dɔn rayt, na spam, so duya tek tɛm chɛk dɛn.",
        "tips03": "Duya kip yu WealthNest akɔn nɔmba ɛn login paswɔd fayn fayn wan.",
        "tips04": "Nɔ yuz di sem paswɔd fɔ login lɛk ɔda wɛbsayt dɛn.",
        "emailFalse": "Di imel we yu put nɔ de fala di lɔ dɛn",
    },
    // 积分兑换
    "jc": {
        "title": "JC exchenj dijital ɛtɛt BEAU pletfɔm",
        "balance": "Tink di rayt we",
        "cannum": "JC kwantiti we de",
        "new": "Di las prayz",
        "exnum": "Ekshɛnj kwantiti",
        "pnum": "Duya, ɛnta di JC kwantiti",
        "canex": "Kwantiti fɔ BEAU we dɛn kin ridim",
        "exnow": "Ridim naw",
        "record": "Ekshɛnj rɛkɔd",
        "exprice": "ɛkshɛnj prays",
        "extime": "Di tɛm fɔ ridɛm",
        "not": "Nɔto inɔf pɔynt dɛn we de",
        "usenum": "Yuz JC kwantiti",
        "out": "lɛf",
        "gotd": "go tred",
    },
    "coin": {
        "manage": "faynɛns manejmɛnt",
        "total": "totɛl dipɔsit",
        "bao": "dipɔsit trɔs",
        "mybao": "Mi dipɔsit",
        "profit": "Di mɔni we dɛn tink se dɛn go gɛt",
        "cuntime": "Kɔyn dipɔsit tɛm",
        "endtine": "Di de we i fɔ dɔn",
    },
    "team": {
        "myteam": "mi tim",
        "ztnum": "Nɔmba fɔ di dairekt riferal dɛn",
        "activenum": "Nɔmba fɔ di aktif mɛmba dɛn na di tim",
        "profit": "mɔni",
        "back": "Ribɛt fɔ di mɔni",
        "cblog": "Dipɔsit rɛkɔd",
        "bblog": "kɔyn rɛkɔd",
    },
    // k线
    "kline": {
        "text1": "fɔ sheb tɛm",
        "text2": "1 minit",
        "text3": "5 minit fɔ du am",
        "text4": "15 minit",
        "text5": "30 minit",
        "text6": "1 awa fɔ wok",
        "text7": "1 dez",
        "text8": "1 wik",
        "text9": "Janwari",
    },
    // 秒合约
    "miscro": {
        "trade": "insay transakshɔn",
        "buyPrice": "di prayz we dɛn bay",
        "finshPrice": "di las prayz",
        "loss": "Di prɔfit ɛn lɔs we dɛn dɔn ɛstimat",
        "times": "Kɔntdɔwn",
        "mode": "tredin mɔdel",
        "num": "Opin pozishɔn kwantiti",
        "rate": "Di we aw pɔsin kin gɛt prɔfit",
        "up": "Bay ɔp",
        "down": "Bay ɔ sɛl",
        "openNum": "Duya, rayt di kwantiti we yu go opin",
        "success": "saksesful wan ɔda!",
        "c2c": "C2C akɔn",
        "complaint": "Sɔgzhɛshɔn dɛn",
        "reply": "Duya rayt yu kwɛstyɔn ɛn wi go kam bak to yu kwik kwik wan...",
        "complaintList": "List fɔ advays dɛn fɔ kɔmplen",
        "complaintReply": "ansa:",
        "complaintDescription": "Duya, rayt wan diskripshɔn",
        "resetpwd": "chenj Paswɔd",
        "foundedOn": "Dɛn bil am insay",
        "commonProblem": "kɔmɔn prɔblɛm",
        "statement": "ɛksplen",
        "about": "Na bɔt WealthNest",
        "everyone": "Ɔlman na CEO",
        "program": "WealthNest Globɛl Patna Program",
        "program2": "Patna Program",
        "myMine": "Mi yon:",
        "friend": "Mi padi dɛn we de wok na di mayning:",
        "myRank": "Mi lɛvɛl: .",
        "accumulated": "Kumulativ kɔmishɔn:",
        "copyLinks": "Kɔpi di link fɔ di promoshɔn",
        "moneyVein": "Fɔ tɔn kɔnɛkshɔn to mɔni",
        "example": "Kɔmishɔn ɛgzampul",
        "enterQuantity": "Duya, rayt di kwantiti",
        "contractBalance": "Kontrakt akaunt balans",
        "submitRepeatedly": "Sɔbmishɔn de go bifo, duya nɔ sɛn am bɔku tɛm",
        "alipayCode": "Alipay pemɛnt kɔd",
        "wechatCode": "WeChat pemɛnt kɔd",
        "realPrice": "rial tɛm prayz",
        "currencyExchange": "Ridim di mɔni we yu de yuz",
        "currencyExchangeIn": "Ridim di mɔni we yu de yuz",
        "cashableBalance": "Balans we de fɔ ridɛm:",
        "minimumCashable": "Minimum ridɛmshɔn mɔnt:",
        "maximumCashable": "Maksimal kesh ɔut:",
        "automaticallys": "Afta yu klik Ridim, i go dɔn ɔtomɛtik wan ɛn dɛn nɔ go ebul fɔ gi am bak.",
        "match": "gi",
        "title": "Asset exchange",
        "holdAssets": "fɔ ol prɔpati dɛn",
        "dangerousCurrency": "Hol risk kɔyn dɛn",
        "convertibleQuantity": "Kwantiti we pɔsin kin ridim",
        "currencyExchanges": "Di mɔni we dɛn de chenj di mɔni:",
        "insuranceCurrency": "Inshɔrans mɔni",
        "insuranceType": "inshɔrans tayp",
        "contractAsset": "Kontrakt ɛtɛt inshɔrans mɔnt",
        "warehouses": "Nɔmba fɔ di inshɔrans westɛm dɛn",
        "availableAssets": "Di prɔpati dɛn we de:",
        "insuredAssets": "Asɛt dɛn we gɛt inshɔrans:",
        "insuredAssets1": "Asɛt dɛn we gɛt inshɔrans",
        "insuranceAssets": "Inshɔrans prɔpati dɛn:",
        "purchaseInsurance": "Sabskripshɔn fɔ inshɔrans",
        "insuranceClaims": "inshɔrans klem dɛn",
        "insuranceCancellation": "Inshɔrans we dɛn kin kansel",
        "coinWallet": "Inshɔrans kɔyn walet",
        "bmbWallet": "HAP walet",
        "cumulativeCoins": "Kɔyn dɛn we dɛn dɔn gɛda:",
        "availableQuantity": "Kwantiti we De:",
        "rawCurrency": "Kɔyn rɛkɔd",
        "contractInsurance": "kɔntrakt inshɔrans",
        "tenThousand": "Tɛn tawzin",
        "runningLow": "Nɔ balans we go du fɔ yu",
        "purchase": "Di kwantiti we yu put nɔ de fala di lɔ dɛn, ɛn di limit we yu fɔ bay de insay",
        "reach": "to",
        "between": "bitwin",
        "onlyEnter": "Yu kin jɔs ebul fɔ go insay",
        "integersBetween": "intaj bitwin",
        "notReturned": "We yu klik fɔ sabskripshɔn fɔ inshɔrans, dɛn go si se yu ɔndastand di inshɔrans kɔntrakt gud gud wan ɛn yu gri se di inshɔrans westɛm go bigin fɔ wok ɔtomɛtik wan ɛn dɛn nɔ go ebul fɔ gi am bak.",
        "settled": "We di inshɔrans prɔpati dɛn we dɛn lɔs mek i nɔ pɔsibul fɔ mek ɔda, dɛn go mek inshɔrans klem",
        "profitable": "tɛm dɛn, we di prɔpati de mek prɔfit",
        "terminated": ", di inshɔrans go dɔn ɔtomɛtik wan.",
        "automatically": "We di inshɔrans prɔpati dɛn lɔs 50%, yu fɔ aplay fɔ inshɔrans klem, ɔdasay yu nɔ go ebul fɔ tred We di prɔfit na 100%, di inshɔrans go dɔn ɔtomɛtik wan Di inshɔrans westɛm fɔ pe tu kɔmpɛnshɔn a de If yu pas tu tɛm, dɛn go pe di kɔmpɛnshɔn pan t+1.",
        "termination": "Afta dɛn dɔn dɔn di inshɔrans kɔntrakt, dɛn kin ridim di inshɔrans prɔpati dɛn Afta dɛn dɔn dɔn di kɔntrakt, dɛn kin tek am se i nɔ gri wit di kɔntrakt ɛn dɛn kin klia di inshɔrans westɛm ɔtomɛtik wan.",
        "just": "jɔs",
        "back": "difrɛn",
        "lowestNumber": "Di minimum kwantiti na",
        "confirmExchange": "Kɔnfɛm se yu dɔn fri?",
        "contractValuation": "Kontrakt akaunt ɛtɛt valyueshɔn",
        "secondValuation": "Sɛkɔn kɔntrakt akɔn ɛtɛt valyueshɔn",
        "falshValuation": "Instant ɛkshɛnj akɔn ɛtɛt valyueshɔn",
        "c2cValuation": "C2C akaunt ɛtɛt valyueshɔn",
        "recordWithdrawal": "Dipɔsit ɛn witdraw rɛkɔd dɛn",
        "category": "kayn",
        "safetyCenter": "Sekyuriti sɛnta",
        "safeText1": "Dɛn kin yuz am fɔ gɛt verifyeshɔn SMS mɛsej dɛn we yu de rɛjista, chenj paswɔd, ɛn sɛt ap sikyɔriti",
        "safeText2": "Intanɛt akɔn dɛn de pan denja fɔ mek dɛn tif dɛn I fayn fɔ chenj yu paswɔd ɔltɛm fɔ protɛkt di sikyɔriti na yu akɔn.",
        "flashTrading": "Flash transakshɔn we yu de du",
        "assetCenter": "Asset Sɛntral",
        "promotionCode": "Mi promoshɔn kɔd",
        "loginAgain": "Login dɔn dɔn, duya log in bak",
        "text10": "HAP-based rial-taym prayz dɛn",
        "text11": "Kɔd fɔ ɔtorizeshɔn",
    }
}
