export default {
    "language": "Français",
    // 导航
    "zdy": {
        "tjsq": "présenter une demande",
        "czsl": "Quantité de recharge",
        "enterczsl": "Veuillez saisir le montant de la recharge",
        "qbdz": "adresse du portefeuille",
        "enterqbdz": "Veuillez saisir l'adresse du portefeuille",
        "sftjmcj": "S'il faut soumettre le prix de vente de la commande",
        "sftjmrj": "S'il faut soumettre le prix d'achat de la commande",
        "proloss": "Prenez des bénéfices et stoppez les pertes",
    },
    "header": {
        "home": "page de garde",
        "markets": "Citations",
        "fiat": "Trading de devises fiduciaires",
        "c2c": "Transaction C2C",
        "exchange": "Trading de crypto-monnaie",
        "lever": "négociation de contrats",
        "micro": "négociation du deuxième contrat",
        "myshop": "mon magasin",
        "assets": "mes atouts",
        "help": "Centre d'aide",
        "login": "Se connecter",
        "sign": "registre",
        "accountSet": "paramètres du compte",
        "tradeLog": "journal des transactions",
        "receiveSet": "Paramètres de paiement",
        "identify": "Authentification",
        "jchange": "Échange de devises",
        "logout": "se déconnecter",
        "themedark": "Mode nuit",
        "themeday": "mode jour",
    },
    "frame": {
        "a": "Vous avez un nouveau statut de commande de retrait, veuillez cliquer pour le visualiser",
        "b": "Annuler",
        "c": "Bien sûr",
        "con": "Chers utilisateurs, car il y a de nombreux membres qui négocient dans le centre commercial. ",
    },
    // 登录注册
    "lg": {
        "login": "Se connecter",
        "register": "registre",
    },
    // 首页
    "home": {
        "vol": "quantité 24H",
        "c01": "La première plateforme mondiale de trading d'actifs numériques",
        "c02": "Fournir des services de négociation et de gestion d'actifs sûrs et fiables à des millions d'utilisateurs dans plus de 130 pays à travers le monde",
        "c03": "Sûr et digne de confiance",
        "c04": "5 ans d'expérience dans les services financiers d'actifs numériques",
        "c05": "Architecture distribuée professionnelle et système d'attaque anti-DDOS",
        "c06": "Disposition écologique globale",
        "c07": "Des centres de services commerciaux localisés ont été créés dans de nombreux pays",
        "c08": "Créer un écosystème blockchain qui intègre plusieurs formes commerciales",
        "c09": "L'utilisateur d'abord",
        "c10": "Mettre en place un mécanisme de compensation avancé",
        "c11": "Créer un fonds de protection des investisseurs",
        "c12": "Trading sur terminaux multiplateformes à tout moment et en tout lieu",
        "c13": "Couvre plusieurs plates-formes, notamment iOS, Android et Windows, prenant en charge toutes les fonctions commerciales",
        "sweep": "Scannez le code pour télécharger",
        "android": "Android",
        "pinput": "Veuillez entrer votre email ou votre numéro de téléphone portable",
        "atrade": "Créez un compte WealthNest Global Station et commencez votre parcours commercial",
        "download": "Télécharger l'application",
        "ios": "Téléchargement IOS",
        "androidDownload": "Téléchargement Android",
        companyoverview1: "Sûr",
        companyoverview2: "5 ans d'expérience dans les services financiers d'actifs numériques. Architecture distribuée professionnelle et système anti-attaque DDoS.",
        companyoverview3: "Fiable",
        companyoverview4: "Rapide"
    },
    // 底部
    "foo": {
        "about": "à propos de nous",
        "support": "Assistance aux utilisateurs",
        "contact": "Contactez-nous",
        "qq": "Service client QQ",
        "email": "Email du contact",
    },
    // 行情
    "market": {
        "handicap": "Handicap",
        "exchange": "pièces de monnaie",
        "selfmarket": "Facultatif",
        "allmarket": "Toutes les citations",
        "symbol": "paire de trading",
        "newprice": "Dernier prix",
        "change": "Augmenter",
        "highprice": "le prix le plus élevé",
        "lowprice": "prix le plus bas",
        "vol": "quantité 24H",
    },
    // 公共交易
    "td": {
        "buy": "Acheter",
        "sell": "vendre",
        "currency": "Devise",
        "num": "quantité",
        "time": "temps",
        "limit": "limite",
        "price": "prix unitaire",
        "method": "mode de paiement",
        "total": "montant forfaitaire",
        "inwant": "Veuillez entrer votre souhait",
        "do": "fonctionner",
        "more": "charger plus",
        "nomore": "pas plus",
        "nodata": "Pas de données",
        "trade": "commerce",
        "buynum": "Quantité d'achat",
        "sellout": "vendre",
        "buyin": "Acheter",
        "all": "tous",
        "allbuy": "tout acheter",
        "allsell": "tout vendre",
        "buytotal": "Veuillez entrer le montant total que vous souhaitez acheter",
        "selltotal": "Veuillez saisir le montant total que vous souhaitez vendre",
        "buyallnum": "Veuillez entrer la quantité que vous souhaitez acheter",
        "sellallnum": "Veuillez entrer la quantité que vous souhaitez vendre",
        "tradeTotal": "montant total de la transaction",
        "doceil": "Annuler automatiquement après quelques secondes",
        "place": "Commander",
        "waitpay": "En attente de paiement",
        "paycode": "code de paiement",
        "finished": "complété",
        "ceiled": "Annulé",
        "payed": "Déjà payé",
        "nofinish": "défait",
        "buyer": "acheteur",
        "seller": "vendeur",
        "callWay": "Coordonnées",
        "placeTime": "temps de commande",
        "renum": "Numéro de référence",
        "canceil": "Annuler",
        "confirm": "confirmer",
        "pwd": "Veuillez saisir le mot de passe de la transaction",
        "coincode": "Unité monétaire",
        "pcoin": "Veuillez sélectionner l'unité monétaire",
        "setTradePwd": "Définir le mot de passe de transaction ?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Dossier de commande",
        "fatdLog": "Enregistrements de transactions en monnaie légale",
        "shoper": "Marchand",
        "faLog": "Enregistrements de transactions en monnaie légale",
        "tdType": "Type de transaction",
        "odStatus": "Statut de la commande",
        "pwaitPay": "Veuillez attendre le paiement de l'acheteur",
        "odFinish": "Commande terminée",
        "odCeil": "Commande annulée",
        "odPay": "Le paiement a été effectué, veuillez vérifier attentivement les informations de paiement",
        "tdCeil": "annuler l'accord",
        "pCeil": "Si vous avez déjà payé le vendeur, veuillez ne pas annuler la transaction.",
        "paySure": "Confirmation de paiement",
        "pdopay": "Veuillez confirmer que vous avez payé le vendeur",
        "badClick": "Les clics malveillants gèleront directement le compte",
        "receivePay": "Confirmation de paiement",
        "payReceive": "Veuillez confirmer que l'acheteur vous a payé",
        "receivePays": "Confirmer le paiement reçu",
        // LegalPay
        "payPlease": "payez s'il vous plait",
        "tdPrice": "Prix ​​unitaire de la transaction",
        "tdNum": "Nombre de transactions",
        "payinfo": "information de paiement",
        "banknum": "Compte bancaire",
        "realName": "nom réel",
        "shoperZname": "Branche marchande",
        "shoperNum": "compte marchand",
        "odCeil": "annuler la commande",
        "imPay": "J'ai payé, cliquez pour confirmer",
        // LegalSeller
        "register_time": "Heure d'inscription",
        "odtotal": "Ordre de montage",
        "odmonth": "Commande finalisée le 30",
        "odfinish": "Complétez la commande",
        "odrate": "Taux d'achèvement",
        "submit": "libérer",
        "phone": "téléphone portable",
        "mysell": "mon à vendre",
        "mybuy": "Mon achat",
        "realAuth": "Vérifié",
        "advAuth": "Certification avancée",
        "linebuy": "acheter en ligne",
        "linesell": "à vendre en ligne",
        "tipPay": "Merci de contacter le commerçant dans les 24 heures pour payer. Si celui-ci dépasse 24 heures, il sera automatiquement annulé.",
        "notlow": "Je ne peux pas descendre en dessous du minimum",
        "nothigh": "Ne peut pas dépasser la limite maximale",
        "notnum": "Ne peut pas dépasser la quantité maximale",
        // LegalShopDetail
        "type": "taper",
        "status": "État",
        "seeOrder": "vérifier la commande",
        "withdraw": "retirer",
        "abnormal": "anormal",
        "lowershelf": "Retiré des étagères",
        "protection": "Protection des droits en cours",
        "apply": "Demander la protection des droits",
        "psType": "Veuillez choisir le type",
        "pselect": "Choisissez s'il vous plaît",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "carte bancaire",
        "minNum": "Volume minimum de transactions",
        "maxNum": "volume maximum de trading",
        "wantBuy": "Vouloir acheter",
        "pnums": "Veuillez entrer la quantité",
        "pmin": "Veuillez saisir le volume minimum de transactions",
        "pmax": "Veuillez saisir le volume maximum de transactions",
        "pnot": "Le volume maximum de transactions ne peut pas être inférieur au volume minimum de transactions",
        // MyLegalShops
        "myshops": "mon magasin",
        "name": "nom",
        "fiatSub": "Monnaie légale",
        "shoper_balance": "Solde marchand",
        "enterShop": "entrer dans un magasin",
        // shop_legal_pay_detail
        "payedWait": "Le paiement a été effectué, en attente de vérification et de confirmation",
        "yes": "Oui",
        "no": "Non",
        "sd": "Verrouiller le solde",
        "journal": "Journal de solde",
        "business": "Marchand",
        "user": "utilisateur",
        "backdone": "Retiré",
        "busbalance": "Solde marchand",
        "userbalance": "Solde utilisateur",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C publié par moi",
        "myTrade": "C2C de ma transaction",
        "and": "droite",
        "balance": "Équilibre",
        "buynum": "Volume d'achat",
        "sellnum": "Volume de vente",
        "bankTransfer": "Virement",
        "payim": "Doit payer en personne",
        "pbuyNum": "Veuillez saisir la quantité achetée",
        "pprice": "Veuillez entrer le prix unitaire",
        "payway": "Veuillez sélectionner le mode de paiement",
        "psellNum": "Veuillez entrer la quantité vendue",
        "suerePlace": "Confirmer la commande?",
        "detail": "Détails",
        "payinfo": "information de paiement",
        "Payee": "Bénéficiaire",
        "total": "montant forfaitaire",
        "name": "Nom",
        "cardnum": "numéro de carte",
        "receivePay": "Confirmer le paiement reçu",
        "ceilConfirm": "Confirmer pour annuler la transaction ?",
        "paySeller": "Veuillez confirmer que vous avez payé le vendeur. Les clics malveillants verront votre compte gelé.",
        "payBuyer": "Veuillez confirmer que vous avez reçu les informations de paiement de l'acheteur",
        "account": "compte",
        "sellerPay": "Veuillez confirmer que l'acheteur vous a payé ?",
        "payattion": "Veuillez payer le vendeur dès que possible après la confirmation du paiement. Les clics malveillants gèleront directement le compte.",
        "log": "c2c a terminé la commande",
    },
    // 合约
    "lever": {
        "nowentrust": "Commande en cours",
        "hisentrust": "commande historique",
        "lvchi": "Postes contractuels",
        "all": "tous",
        "or": "ou",
        "gotrade": "Commencer à trader",
        "tdrecord": "Relevé d'opération",
        "dealed": "Affaire conclue",
        "notdeal": "Aucune transaction",
        "loading": "chargement...",
        "entotal": "Montant total confié",
        "canuse": "Disponible",
        "std": "transaction au prix du marché",
        "xtd": "limiter le commerce",
        "pprice": "Veuillez entrer le prix",
        "phand": "Veuillez entrer la taille du lot d'achat",
        "phandsell": "Veuillez entrer la taille du lot de vente",
        "pwd": "mot de passe",
        "ppwd": "Veuillez saisir le mot de passe de la transaction",
        "psw": "mot de passe de transaction",
        "equal": "égal",
        "times": "fois",
        "timed": "plusieurs",
        "hand": "main",
        "hands": "nombre d'étapes",
        "ptimes": "Veuillez en sélectionner plusieurs",
        "phands": "Veuillez sélectionner la taille du lot",
        "contractVal": "Valeur marchande du contrat",
        "bail": "marge",
        "canBail": "Marge disponible",
        "charge": "Frais de service de transaction",
        "domore": "Acheter (aller longtemps)",
        "doshort": "Vendre (à découvert)",
        "dmore": "Allez-y longtemps",
        "dshort": "court",
        "sureOd": "Confirmer la commande",
        "noless": "La taille du lot ne peut être inférieure à",
        "nomore": "La taille du lot ne peut pas être supérieure à",
        "tdnum": "Volume d'échanges",
        "risk": "taux de risque",
        "allloss": "Total des profits et pertes de positions",
        "onehouse": "Clôture d'une position en un clic",
        "type": "taper",
        "entrustPrice": "Prix ​​de la commande",
        "openPrice": "prix d'ouverture",
        "nowPrice": "prix actuel",
        "styPrice": "Prendre le prix du profit",
        "stsPrice": "Prix ​​​​stop loss",
        "openTime": "Horaire d'ouverture",
        "closeTime": "Heure de fermeture",
        "rate": "frais de gestion",
        "nightFee": "Frais de nuit",
        "loss": "Profit et perte",
        "setloss": "Définir le profit et le stop loss",
        "expectProfit": "bénéfice attendu",
        "expectLoss": "perte attendue",
        "allClose": "Fermer tous les postes",
        "moreClose": "Clôturez uniquement les commandes longues",
        "nullClose": "Ne clôturez que les commandes courtes",
        "sureClose": "Confirmer pour clôturer la position ?",
        "thanZearo": "La valeur définie doit être supérieure à 0",
        "listin": "commande en cours",
        "tdin": "en transaction",
        "closein": "Clôture du poste",
        "closed": "Poste fermé",
        "revoked": "Révoqué",
        "revokeOrder": "Etes-vous sûr de vouloir annuler la commande ?",
        "ping": "Position fermée",
        "revoke": "Annuler la commande",
        "sureping": "Etes-vous sûr de clôturer la position ?",
        "thanzone": "La valeur définie doit être supérieure à 0",
    },
    // 币币
    "cuy": {
        "direction": "direction",
        "total": "total",
        "price": "prix",
        "sell": "Vendre",
        "buy": "achat",
        "allStation": "Commerce sur tout le site",
        "buyPrice": "prix d'achat",
        "buynum": "Volume d'achat",
        "sellPrice": "Prix ​​de vente",
        "sellnum": "Volume de vente",
        "tdPrice": "Volume des transactions",
        "or": "ou",
        "tdStart": "Commencer à trader",
        "pbPrice": "Veuillez entrer le prix d'achat",
        "pbNum": "Veuillez saisir le montant de l'achat",
        "psPrice": "Veuillez saisir le prix de vente",
        "psNum": "Veuillez saisir le montant de la vente",
        "fixPrice": "limiter le commerce",
        "Mtrans": "transaction au prix du marché",
        "available": "Disponible",
        "revoke": "Annuler",
        "loading": "chargement",
        "confirmCancel": "Es-tu sûre de vouloir annuler?",
        "confirm": "Bien sûr",
        "cancel": "Annuler",
        "nomore": "Plus de données",
        "evelPrice": "prix moyen des transactions",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Voulez-vous confirmer le transfert ?",
        "assets": "actifs",
        "currency_account": "Compte pièces",
        "lever_account": "Compte de contrat",
        "fince_account": "Compte Fiat",
        "change_account": "compte de transaction",
        "micro_account": "deuxième compte de contrat",
        "miscroAccount": "deuxième compte de contrat",
        "assets_hua": "Transfert de fonds",
        "all_assets": "Total des actifs convertis",
        "currency": "Devise",
        "canuse": "Disponible",
        "frezz": "geler",
        "conversion": "converti",
        "charging": "Déposer des pièces",
        "withdraw": "Retirer des pièces",
        "address_bind": "Lier l'adresse de retrait",
        "address_withdraw": "Gestion des adresses de retrait de pièces",
        "record": "Détails",
        "tbrecord": "Registre de retrait de pièces",
        "address_charge": "Adresse de dépôt",
        "address_width": "Adresse de retrait",
        "copy": "copie",
        "ercode": "QR Code",
        "reminder": "Conseils aimables",
        "notcharge": "Veuillez ne déposer aucun fonds à l’adresse ci-dessus.",
        "ornone": ", sinon les actifs ne seront pas récupérés",
        "support": "Les dépôts ne prennent en charge que la méthode d'envoi simple. Les dépôts utilisant d'autres méthodes (envoyer tout) ne peuvent pas être crédités temporairement.",
        "minnum": "Montant minimum de retrait",
        "ratenum": "frais de gestion",
        "havenum": "Quantité d'arrivée",
        "from": "de",
        "to": "à",
        "transfer": "transfert",
        "tfnum": "Quantité transférée",
        "ptfnum": "Veuillez entrer le minimum",
        "dtfnum": "Le nombre de transferts",
        "canbalance": "Solde disponible",
        "sureConfirm": "Confirmer le transfert",
        "moneyRecord": "dossiers financiers",
        "pNum": "Veuillez saisir le montant du transfert",
        "leverUse": "Les comptes contractuels sont disponibles",
        "legalUse": "Les comptes Fiat sont disponibles",
        "changeUselg": "Le compte Coin est disponible",
        "changeUse": "Comptes de trading disponibles",
        "microUse": "Compte de contrat Seconds disponible",
        "look": "Vérifier",
        "chargeRecord": "Dossier de dépôt",
        "status": "statut de suivi",
        "noopen": "Cette fonction n'est pas encore disponible",
        "enterAdderses": "Veuillez saisir l'adresse de retrait",
        "enterNum": "Veuillez saisir le montant du retrait",
        "lessMin": "Le montant du retrait saisi est inférieur à la valeur minimale",
        "tips01": "Veuillez ne déposer aucun autre actif à l'adresse ci-dessus, sinon les actifs ne seront pas récupérés.",
        "tips02": "Les dépôts ne prennent en charge que la méthode d'envoi simple. Les dépôts utilisant d'autres méthodes (envoyer tout) ne peuvent pas être crédités temporairement.",
        "beizhu": "Remarque",
        "pbeizhu": "Veuillez entrer des remarques",
        "add": "Ajouter à",
        "addressList": "liste d'adresses",
        "delete": "supprimer",
        "norec": "Pas d'enregistrements",
        "selectCurrency": "Veuillez sélectionner la devise",
        "chainType": "type de chaîne",
        "commissionLibrary": "bibliothèque de commissions",
    },
    // 账户设置
    "set": {
        "mycode2": "mon code d'autorisation",
        "copycode2": "Copier le code d'autorisation",
        "uaccont": "Niveau de sécurité de votre compte :",
        "strong": "puissant",
        "middle": "milieu",
        "weak": "faible",
        "min": "Faible",
        "heigh": "haut",
        "complete": "Complétez plus d’informations pour garantir la sécurité du compte",
        "mycode": "mon code d'invitation",
        "copycode": "Copier le code d'invitation",
        "bindphone": "Lier un téléphone portable",
        "bindemail": "Lier un e-mail",
        "loginpwd": "mot de passe",
        "noBind": "Pas liée",
        "binds": "obligatoire",
        "bind": "Aller à la liaison",
        "binded": "Lié",
        "net": "Les comptes Internet risquent d'être volés. Il est recommandé de changer régulièrement votre mot de passe pour protéger la sécurité de votre compte.",
        "reset": "Réviser",
        "setPsw": "Définissez un mot de passe de transaction pour garantir la sécurité des transactions.",
        "goSet": "Aller aux paramètres",
        "bindAccount": "Lier le compte d'échange",
        "duihuan": "Lier un compte pour échanger des points",
        "copysuccess": "Copié avec succès",
        "copyfail": "Échec de la copie",
        "recopy": "Veuillez copier à nouveau",
        "account": "Compte",
        "detail": "Détails",
        "val": "Montant",
        "forgetPsw": "oublier le mot de passe",
        "code": "Le code de vérification",
        "getCode": "obtenir le code de vérification",
        "setPsw": "définir le mot de passe",
        "enterPsw": "Veuillez entrer le mot de passe",
        "pswFalse": "Les mots de passe saisis deux fois sont incohérents",
        "enterPswagain": "Veuillez saisir à nouveau le mot de passe",
        "jsetPsw": "Définir le mot de passe de transaction",
        "jenterPsw": "Veuillez saisir le mot de passe de la transaction",
        "jenterPswagain": "Veuillez saisir à nouveau le mot de passe de la transaction",
        "enterAccount": "Veuillez saisir votre nom d'utilisateur",
        "enterCode": "veuillez entrer le code de vérification",
        "accountFalse": "Le téléphone portable ou le compte email que vous avez renseigné n'est pas conforme aux règles !",
        "second": "secondes",
        "sendCode": "Envoyer le code de vérification",
        "sendsucc": "Bien envoyé",
        "enterPswTwo": "Veuillez entrer votre mot de passe deux fois",
        "rest": "Restant",
        "yes": "Oui",
        "no": "Non",
        "yorn": "S'il faut définir un mot de passe de transaction",
        "authenticate": "Veuillez effectuer une vérification d'identité",
    },
    // 身份认证
    "auth": {
        "identity": "Authentification",
        "name": "Nom",
        "idcard": "IDENTIFIANT",
        "upimg": "Veuillez télécharger des photos d'identité, la première est la photo de face, la seconde est la photo de dos et la troisième est la photo de face contenant la pièce d'identité.",
        "submit": "soumettre",
        "auditing": "à l'étude...",
        "certified": "vérifié!",
        "pname": "Veuillez saisir votre nom",
        "pidcard": "Veuillez entrer votre numéro d'identification",
        "pimg": "Veuillez télécharger une photo de votre pièce d'identité !",
    },
    // 收款方式
    "seting": {
        "pmethod": "mode de paiement",
        "truename": "nom réel",
        "opening": "Nom de banque",
        "bank": "Numéro de carte bancaire",
        "alipay": "Compte Alipay",
        "wename": "Pseudo WeChat",
        "wechat": "Compte WeChat",
        "pname": "veuillez entrer votre vrai nom",
        "popen": "Veuillez saisir le nom de la banque où le compte est ouvert",
        "pcard": "Veuillez saisir le numéro de votre carte bancaire",
        "palipay": "Veuillez saisir votre numéro de compte Alipay",
        "pwname": "Veuillez entrer votre pseudo WeChat",
        "pwaccount": "Veuillez entrer votre compte WeChat",
        "pimg1": "Code de paiement WeChat",
        "pimg2": "Code de paiement Alipay",
    },
    // 帮助中心
    "news": {
        "seeall": "Voir tout",
        "helpcenter": "Centre d'annonces",
        "back": "retour",
    },
    //登录
    "login": {
        "welcome": "Connectez-vous s'il vous plaît",
        "phoneLogin": "Connectez-vous avec le téléphone",
        "emailLogin": "Connexion par e-mail",
        "account": "compte",
        "psw": "mot de passe",
        "login": "Se connecter",
        "nouser": "Vous n'êtes pas encore utilisateur de WealthNest ?",
        "goRegister": "Inscrivez-vous maintenant et commencez à trader sur la première plateforme mondiale de trading d'actifs numériques.",
        "registerFree": "inscription gratuite",
        "psw6": "Le mot de passe ne peut pas contenir moins de six caractères",
    },
    "register": {
        "register": "registre",
        "phoneRegister": "Enregistrez votre téléphone",
        "emailRegister": "inscription par courrier électronique",
        "country": "nationalité",
        "enterPhone": "Veuillez entrer le numéro de téléphone",
        "enterEmail": "veuillez saisir votre email",
        "psw16": "Le mot de passe doit contenir entre 6 et 16 caractères",
        "inviteCode": "Code d'invitation",
        "sel": "Requis",
        "sel2": "Facultatif",
        "read": "J'ai lu et accepté",
        "xieyi": "\"Accord de l'utilisateur\"",
        "tips01": "Les informations sur la nationalité ne peuvent pas être modifiées après l'inscription, alors assurez-vous de les choisir véridiquement.",
        "tips02": "Les e-mails de vérification peuvent être identifiés par erreur comme du spam, veuillez donc les vérifier attentivement.",
        "tips03": "Veuillez conserver correctement votre numéro de compte WealthNest et votre mot de passe de connexion.",
        "tips04": "N'utilisez pas le même mot de passe de connexion que sur d'autres sites Web.",
        "emailFalse": "L'email que vous avez renseigné n'est pas conforme aux règles",
    },
    // 积分兑换
    "jc": {
        "title": "Plateforme BEAU d'échange d'actifs numériques JC",
        "balance": "Équilibre",
        "cannum": "Quantité JC disponible",
        "new": "Dernier prix",
        "exnum": "Quantité d'échange",
        "pnum": "Veuillez saisir la quantité JC",
        "canex": "Quantité de BEAU pouvant être échangée",
        "exnow": "Échangez maintenant",
        "record": "Dossier d'échange",
        "exprice": "prix d'échange",
        "extime": "Heure de rachat",
        "not": "Pas assez de points disponibles",
        "usenum": "Utiliser la quantité de JC",
        "out": "quitter",
        "gotd": "aller négocier",
    },
    "coin": {
        "manage": "direction financière",
        "total": "dépôt total",
        "bao": "déposer un trésor",
        "mybao": "Mon dépôt",
        "profit": "Revenu estimé",
        "cuntime": "Heure de dépôt des pièces",
        "endtine": "Date d'expiration",
    },
    "team": {
        "myteam": "mon équipe",
        "ztnum": "Nombre de références directes",
        "activenum": "Nombre de membres actifs de l'équipe",
        "profit": "revenu",
        "back": "Rabais",
        "cblog": "Dossier de dépôt",
        "bblog": "record de pièces de monnaie",
    },
    // k线
    "kline": {
        "text1": "partage de temps",
        "text2": "1 minute",
        "text3": "5 minutes",
        "text4": "15 minutes",
        "text5": "30 minutes",
        "text6": "1 heure",
        "text7": "Un jour",
        "text8": "1 semaine",
        "text9": "Janvier",
    },
    // 秒合约
    "miscro": {
        "trade": "en transaction",
        "buyPrice": "prix d'achat",
        "finshPrice": "prix final",
        "loss": "Profits et pertes estimés",
        "times": "Compte à rebours",
        "mode": "modèle commercial",
        "num": "Quantité de position ouverte",
        "rate": "Rentabilité",
        "up": "Acheter",
        "down": "Acheter ou vendre",
        "openNum": "Veuillez entrer la quantité d'ouverture",
        "success": "commandé avec succès !",
        "c2c": "Compte C2C",
        "complaint": "Suggestions",
        "reply": "Veuillez écrire votre question et nous vous répondrons dans les plus brefs délais...",
        "complaintList": "Liste de suggestions de plainte",
        "complaintReply": "répondre:",
        "complaintDescription": "Veuillez saisir une description",
        "resetpwd": "changer le mot de passe",
        "foundedOn": "Intégré",
        "commonProblem": "Problème commun",
        "statement": "expliquer",
        "about": "À propos de WealthNest",
        "everyone": "Tout le monde est PDG",
        "program": "Programme de partenariat mondial WealthNest",
        "program2": "Programme de partenariat",
        "myMine": "Mon mien :",
        "friend": "Mes amis mineurs :",
        "myRank": "Mon niveau:",
        "accumulated": "Commission cumulée :",
        "copyLinks": "Copier le lien de promotion",
        "moneyVein": "Transformer les connexions en argent",
        "example": "Exemple de commission",
        "enterQuantity": "Veuillez entrer la quantité",
        "contractBalance": "Solde du compte de contrats",
        "submitRepeatedly": "La soumission est en cours, veuillez ne pas soumettre à plusieurs reprises",
        "alipayCode": "Code de paiement Alipay",
        "wechatCode": "Code de paiement WeChat",
        "realPrice": "prix en temps réel",
        "currencyExchange": "Échanger de la devise",
        "currencyExchangeIn": "Échanger de la devise",
        "cashableBalance": "Solde disponible pour le rachat :",
        "minimumCashable": "Montant minimum de rachat :",
        "maximumCashable": "Encaissement maximum :",
        "automaticallys": "Après avoir cliqué sur Utiliser, il sera complété automatiquement et ne pourra pas être retourné.",
        "match": "échange",
        "title": "Échange d'actifs",
        "holdAssets": "détenir des actifs",
        "dangerousCurrency": "Détenir des pièces à risque",
        "convertibleQuantity": "Quantité échangeable",
        "currencyExchanges": "Taux de change:",
        "insuranceCurrency": "Devise d'assurance",
        "insuranceType": "type d'assurance",
        "contractAsset": "Montant d’assurance des actifs du contrat",
        "warehouses": "Nombre d'entrepôts d'assurance",
        "availableAssets": "Actifs disponibles :",
        "insuredAssets": "Biens assurés :",
        "insuredAssets1": "Actifs assurés",
        "insuranceAssets": "Actifs d’assurance :",
        "purchaseInsurance": "Souscription à l'assurance",
        "insuranceClaims": "les réclamations d'assurance",
        "insuranceCancellation": "Assurance annulation",
        "coinWallet": "Porte-monnaie d'assurance",
        "bmbWallet": "Portefeuille HAP",
        "cumulativeCoins": "Pièces accumulées :",
        "availableQuantity": "Quantité disponible:",
        "rawCurrency": "Enregistrement de pièces",
        "contractInsurance": "contrat d'assurance",
        "tenThousand": "Dix mille",
        "runningLow": "Solde insuffisant",
        "purchase": "La quantité que vous avez saisie n'est pas conforme aux règles et votre limite d'achat est dans les limites",
        "reach": "à",
        "between": "entre",
        "onlyEnter": "Vous ne pouvez entrer que",
        "integersBetween": "entier entre",
        "notReturned": "En cliquant pour souscrire à l'assurance, vous êtes réputé avoir compris le contrat d'assurance en détail et accepté que l'entrepôt d'assurance prendra automatiquement effet et ne pourra être restitué.",
        "settled": "Lorsque la perte des biens assurés rend impossible la passation de commande, des réclamations d'assurance seront faites",
        "profitable": "moments où l'actif réalise un profit",
        "terminated": ", l'assurance sera automatiquement résiliée.",
        "automatically": "Lorsque les actifs assurés perdent 50 %, vous devez demander une réclamation d'assurance, sinon vous ne pourrez pas négocier. Lorsque le bénéfice atteint 100 %, l'assurance sera automatiquement résiliée. L'entrepôt d'assurance n'est autorisé à payer que deux indemnités par an. jour. Si vous dépassez deux fois, l'indemnisation sera versée à t+1.",
        "termination": "Après la résiliation du contrat d'assurance, les actifs assurés peuvent être rachetés. Après la résiliation du contrat, cela est considéré comme une rupture de contrat et l'entrepôt d'assurance est automatiquement vidé.",
        "just": "juste",
        "back": "opposé",
        "lowestNumber": "La quantité minimale est",
        "confirmExchange": "Confirmer le rachat ?",
        "contractValuation": "Valorisation des actifs du compte de contrats",
        "secondValuation": "Valorisation des actifs du deuxième compte de contrats",
        "falshValuation": "Évaluation instantanée des actifs du compte d'échange",
        "c2cValuation": "Valorisation des actifs du compte C2C",
        "recordWithdrawal": "Dossiers de dépôt et de retrait",
        "category": "catégorie",
        "safetyCenter": "Centre de sécurité",
        "safeText1": "Utilisé pour recevoir des messages SMS de vérification lors de l'inscription, de la modification des mots de passe et de la configuration de la sécurité",
        "safeText2": "Les comptes Internet risquent d'être volés. Il est recommandé de changer régulièrement votre mot de passe pour protéger la sécurité de votre compte.",
        "flashTrading": "Transaction flash",
        "assetCenter": "Centre d'actifs",
        "promotionCode": "Mon code promotionnel",
        "loginAgain": "Connexion expirée, veuillez vous reconnecter",
        "text10": "Prix ​​en temps réel basés sur HAP",
        "text11": "Code d'autorisation",
    }
}
