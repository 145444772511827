export default {
    "language": "Deutsch",
    // 导航
    "zdy": {
        "tjsq": "Bewerbung einreichen",
        "czsl": "Auflademenge",
        "enterczsl": "Bitte geben Sie den Aufladebetrag ein",
        "qbdz": "Wallet-Adresse",
        "enterqbdz": "Bitte geben Sie die Wallet-Adresse ein",
        "sftjmcj": "Ob der Verkaufspreis der Bestellung übermittelt werden soll",
        "sftjmrj": "Ob der Kaufpreis der Bestellung übermittelt werden soll",
        "proloss": "Gewinn mitnehmen und Verlust stoppen",
    },
    "header": {
        "home": "Titelseite",
        "markets": "Zitate",
        "fiat": "Fiat-Währungshandel",
        "c2c": "C2C-Transaktion",
        "exchange": "Handel mit Kryptowährungen",
        "lever": "Vertragshandel",
        "micro": "zweiter Kontrakthandel",
        "myshop": "mein Geschäft",
        "assets": "mein Vermögen",
        "help": "Hilfezentrum",
        "login": "Anmeldung",
        "sign": "registrieren",
        "accountSet": "Account Einstellungen",
        "tradeLog": "Transaktionsprotokoll",
        "receiveSet": "Zahlungseinstellungen",
        "identify": "Authentifizierung",
        "jchange": "Geldwechsel",
        "logout": "Abmelden",
        "themedark": "Nacht-Modus",
        "themeday": "Tagmodus",
    },
    "frame": {
        "a": "Sie haben einen neuen Auszahlungsauftragsstatus. Bitte klicken Sie, um ihn anzuzeigen",
        "b": "Stornieren",
        "c": "Sicher",
        "con": "Liebe Nutzer, denn auf dem Handelsplatz handeln viele Mitglieder. ",
    },
    // 登录注册
    "lg": {
        "login": "Anmeldung",
        "register": "registrieren",
    },
    // 首页
    "home": {
        "vol": "24-Stunden-Menge",
        "c01": "Die weltweit führende Handelsplattform für digitale Vermögenswerte",
        "c02": "Bieten Sie Millionen von Benutzern in mehr als 130 Ländern auf der ganzen Welt sichere und zuverlässige Dienste für den Handel mit digitalen Vermögenswerten und die Vermögensverwaltung",
        "c03": "Sicher und vertrauenswürdig",
        "c04": "5 Jahre Erfahrung im Bereich der Finanzdienstleistungen für digitale Vermögenswerte",
        "c05": "Professionelle verteilte Architektur und Anti-DDOS-Angriffssystem",
        "c06": "Globales ökologisches Layout",
        "c07": "In vielen Ländern wurden lokale Handelsdienstleistungszentren eingerichtet",
        "c08": "Erstellen Sie ein Blockchain-Ökosystem, das mehrere Geschäftsformen integriert",
        "c09": "Benutzer zuerst",
        "c10": "Richten Sie einen fortschrittlichen Vergütungsmechanismus ein",
        "c11": "Richten Sie einen Anlegerschutzfonds ein",
        "c12": "Multiplattform-Terminalhandel jederzeit und überall",
        "c13": "Deckt mehrere Plattformen ab, darunter iOS, Android und Windows, und unterstützt vollständige Geschäftsfunktionen",
        "sweep": "Zum Herunterladen den Code scannen",
        "android": "Android",
        "pinput": "Bitte geben Sie Ihre E-Mail-Adresse oder Mobiltelefonnummer ein",
        "atrade": "Registrieren Sie ein WealthNest Global Station-Konto und beginnen Sie Ihre Handelsreise",
        "download": "Lade App herunter",
        "ios": "IOS-Download",
        "androidDownload": "Android-Download",
        companyoverview1: "Sicher",
        companyoverview2: "5 Jahre Erfahrung in der Finanzdienstleistung für digitale Vermögenswerte. Professionelle verteilte Architektur und Anti-DDoS-Angriffssystem.",
        companyoverview3: "Zuverlässig",
        companyoverview4: "Schnell"
    },
    // 底部
    "foo": {
        "about": "über uns",
        "support": "Benutzer-Support",
        "contact": "kontaktiere uns",
        "qq": "Kundenservice QQ",
        "email": "Kontakt E-mail",
    },
    // 行情
    "market": {
        "handicap": "Behinderung",
        "exchange": "Münzen",
        "selfmarket": "Optional",
        "allmarket": "Alle Zitate",
        "symbol": "Handelspaar",
        "newprice": "Letzter Preis",
        "change": "Zunahme",
        "highprice": "höchster Preis",
        "lowprice": "geringster Preis",
        "vol": "24-Stunden-Menge",
    },
    // 公共交易
    "td": {
        "buy": "Kaufen",
        "sell": "verkaufen",
        "currency": "Währung",
        "num": "Menge",
        "time": "Zeit",
        "limit": "Grenze",
        "price": "Einzelpreis",
        "method": "Bezahlverfahren",
        "total": "Pauschalbetrag",
        "inwant": "Bitte geben Sie Ihren Wunsch ein",
        "do": "arbeiten",
        "more": "Mehr laden",
        "nomore": "nicht mehr",
        "nodata": "Keine Daten",
        "trade": "Handel",
        "buynum": "Kaufmenge",
        "sellout": "verkaufen",
        "buyin": "Kaufen",
        "all": "alle",
        "allbuy": "alles kaufen",
        "allsell": "alles verkaufen",
        "buytotal": "Bitte geben Sie den Gesamtbetrag ein, den Sie kaufen möchten",
        "selltotal": "Bitte geben Sie den Gesamtbetrag ein, den Sie verkaufen möchten",
        "buyallnum": "Bitte geben Sie die Menge ein, die Sie kaufen möchten",
        "sellallnum": "Bitte geben Sie die Menge ein, die Sie verkaufen möchten",
        "tradeTotal": "Gesamttransaktionsbetrag",
        "doceil": "Automatischer Abbruch nach Sekunden",
        "place": "Eine Bestellung aufgeben",
        "waitpay": "Ausstehende Zahlung",
        "paycode": "Zahlungscode",
        "finished": "vollendet",
        "ceiled": "Abgesagt",
        "payed": "Bereits bezahlt",
        "nofinish": "rückgängig gemacht",
        "buyer": "Käufer",
        "seller": "Verkäufer",
        "callWay": "Kontaktinformationen",
        "placeTime": "Bestellzeitpunkt",
        "renum": "Referenznummer",
        "canceil": "Stornieren",
        "confirm": "bestätigen",
        "pwd": "Bitte geben Sie das Transaktionspasswort ein",
        "coincode": "Währungseinheit",
        "pcoin": "Bitte wählen Sie die Währungseinheit aus",
        "setTradePwd": "Transaktionspasswort festlegen?",
    },
    // 法币交易
    "fat": {
        // LegalPayDetail
        "orderLog": "Bestelldatensatz",
        "fatdLog": "Aufzeichnungen über legale Währungstransaktionen",
        "shoper": "Händler",
        "faLog": "Aufzeichnungen über legale Währungstransaktionen",
        "tdType": "Art der Transaktion",
        "odStatus": "Bestellstatus",
        "pwaitPay": "Bitte warten Sie auf die Zahlung des Käufers",
        "odFinish": "Bestellung abgeschlossen",
        "odCeil": "Bestellung storniert",
        "odPay": "Die Zahlung wurde durchgeführt. Bitte überprüfen Sie die Zahlungsinformationen sorgfältig",
        "tdCeil": "den Deal kündigen",
        "pCeil": "Wenn Sie den Verkäufer bereits bezahlt haben, brechen Sie die Transaktion bitte nicht ab.",
        "paySure": "Zahlungsbestätigung",
        "pdopay": "Bitte bestätigen Sie, dass Sie den Verkäufer bezahlt haben",
        "badClick": "Bei böswilligen Klicks wird das Konto direkt gesperrt",
        "receivePay": "Zahlungsbestätigung",
        "payReceive": "Bitte bestätigen Sie, dass der Käufer Sie bezahlt hat",
        "receivePays": "Bestätigen Sie den Zahlungseingang",
        // LegalPay
        "payPlease": "Bitte zahlen Sie",
        "tdPrice": "Preis pro Transaktionseinheit",
        "tdNum": "Anzahl der Transaktionen",
        "payinfo": "Zahlungsinformationen",
        "banknum": "Bankkonto",
        "realName": "tatsächlicher Name",
        "shoperZname": "Handelsfiliale",
        "shoperNum": "Händler-Konto",
        "odCeil": "Bestellung stornieren",
        "imPay": "Ich habe bezahlt, klicken Sie zum Bestätigen",
        // LegalSeller
        "register_time": "Anmeldezeit",
        "odtotal": "Montagereihenfolge",
        "odmonth": "Bestellung abgeschlossen am 30",
        "odfinish": "Bestellung abschließen",
        "odrate": "Abschlussquote",
        "submit": "freigeben",
        "phone": "Handy",
        "mysell": "mein zu verkaufen",
        "mybuy": "Mein Einkauf",
        "realAuth": "Verifiziert",
        "advAuth": "Erweiterte Zertifizierung",
        "linebuy": "Online kaufen",
        "linesell": "zum Online-Verkauf",
        "tipPay": "Bitte kontaktieren Sie den Händler innerhalb von 24 Stunden, um zu bezahlen. Wenn die Frist 24 Stunden überschreitet, wird die Zahlung automatisch storniert.",
        "notlow": "Das Minimum kann nicht unterschritten werden",
        "nothigh": "Der Höchstwert darf nicht überschritten werden",
        "notnum": "Die Höchstmenge darf nicht überschritten werden",
        // LegalShopDetail
        "type": "Typ",
        "status": "Zustand",
        "seeOrder": "prüfe die Reihenfolge",
        "withdraw": "zurückziehen",
        "abnormal": "abnormal",
        "lowershelf": "Aus den Regalen entfernt",
        "protection": "Rechteschutz im Gange",
        "apply": "Beantragen Sie Rechtsschutz",
        "psType": "Bitte wählen Sie den Typ aus",
        "pselect": "bitte auswählen",
        "alipay": "Alipay",
        "wechat": "WeChat",
        "bank": "Bankkarte",
        "minNum": "Mindesttransaktionsvolumen",
        "maxNum": "maximales Handelsvolumen",
        "wantBuy": "Will kaufen",
        "pnums": "Bitte Menge eingeben",
        "pmin": "Bitte geben Sie das Mindesttransaktionsvolumen ein",
        "pmax": "Bitte geben Sie das maximale Transaktionsvolumen ein",
        "pnot": "Das maximale Transaktionsvolumen darf nicht kleiner sein als das minimale Transaktionsvolumen",
        // MyLegalShops
        "myshops": "mein Geschäft",
        "name": "Name",
        "fiatSub": "Gesetzliche Währung",
        "shoper_balance": "Händlersaldo",
        "enterShop": "einen Laden betreten",
        // shop_legal_pay_detail
        "payedWait": "Die Zahlung wurde getätigt und wartet auf die Überprüfung und Bestätigung",
        "yes": "Ja",
        "no": "NEIN",
        "sd": "Guthaben sperren",
        "journal": "Bilanzprotokoll",
        "business": "Händler",
        "user": "Benutzer",
        "backdone": "Zurückgezogen",
        "busbalance": "Händlersaldo",
        "userbalance": "Benutzerguthaben",
    },
    "ctc": {
        "ctc": "C2C",
        "myRelease": "C2C von mir veröffentlicht",
        "myTrade": "C2C meiner Transaktion",
        "and": "Rechts",
        "balance": "Gleichgewicht",
        "buynum": "Kaufvolumen",
        "sellnum": "Verkaufsvolumen",
        "bankTransfer": "Banküberweisung",
        "payim": "Muss persönlich bezahlen",
        "pbuyNum": "Bitte geben Sie die Abnahmemenge ein",
        "pprice": "Bitte geben Sie den Stückpreis ein",
        "payway": "Bitte wählen Sie die Zahlungsart aus",
        "psellNum": "Bitte geben Sie die Verkaufsmenge ein",
        "suerePlace": "Bestellung bestätigen?",
        "detail": "Einzelheiten",
        "payinfo": "Zahlungsinformationen",
        "Payee": "Zahlungsempfänger",
        "total": "Pauschalbetrag",
        "name": "Name",
        "cardnum": "Kartennummer",
        "receivePay": "Bestätigen Sie den Zahlungseingang",
        "ceilConfirm": "Bestätigen, um die Transaktion abzubrechen?",
        "paySeller": "Bitte bestätigen Sie, dass Sie den Verkäufer bezahlt haben. Bei böswilligen Klicks wird Ihr Konto gesperrt.",
        "payBuyer": "Bitte bestätigen Sie, dass Sie vom Käufer Zahlungsinformationen erhalten haben",
        "account": "Konto",
        "sellerPay": "Bitte bestätigen Sie, dass der Käufer Sie bezahlt hat.",
        "payattion": "Bitte zahlen Sie den Verkäufer so schnell wie möglich, nachdem die Zahlung bestätigt wurde. Bei böswilligen Klicks wird das Konto direkt gesperrt.",
        "log": "c2c hat die Bestellung abgeschlossen",
    },
    // 合约
    "lever": {
        "nowentrust": "Aktueller Auftrag",
        "hisentrust": "Historische Kommission",
        "lvchi": "Vertragspositionen",
        "all": "alle",
        "or": "oder",
        "gotrade": "Mit dem handel beginnen",
        "tdrecord": "Transaktionen Aufzeichnung",
        "dealed": "Geschäft abgeschlossen",
        "notdeal": "Keine Transaktion",
        "loading": "Wird geladen...",
        "entotal": "Gesamtbetrag der anvertrauten Summe",
        "canuse": "Verfügbar",
        "std": "Marktpreistransaktion",
        "xtd": "den Handel einschränken",
        "pprice": "Bitte geben Sie den Preis ein",
        "phand": "Bitte geben Sie die Kauflosgröße ein",
        "phandsell": "Bitte geben Sie die zu verkaufende Losgröße ein",
        "pwd": "Passwort",
        "ppwd": "Bitte geben Sie das Transaktionspasswort ein",
        "psw": "Transaktions Passwort",
        "equal": "gleich",
        "times": "mal",
        "timed": "mehrere",
        "hand": "Hand",
        "hands": "Anzahl der Schritte",
        "ptimes": "Bitte mehrere auswählen",
        "phands": "Bitte Losgröße auswählen",
        "contractVal": "Vertragsmarktwert",
        "bail": "Marge",
        "canBail": "Verfügbare Spanne",
        "charge": "Gebühr für den Transaktionsservice",
        "domore": "Kaufen (long gehen)",
        "doshort": "Verkaufen (Short)",
        "dmore": "Geh lang",
        "dshort": "kurz",
        "sureOd": "Bestellung bestätigen",
        "noless": "Die Losgröße darf nicht kleiner sein als",
        "nomore": "Die Losgröße darf nicht größer sein als",
        "tdnum": "Handelsvolumen",
        "risk": "Risikorate",
        "allloss": "Gesamtgewinn und -verlust der Positionen",
        "onehouse": "Schließen Sie eine Position mit einem Klick",
        "type": "Typ",
        "entrustPrice": "Bestellpreis",
        "openPrice": "Eröffnungskurs",
        "nowPrice": "derzeitiger Preis",
        "styPrice": "Nehmen Sie den Gewinnpreis",
        "stsPrice": "Stop-Loss-Preis",
        "openTime": "Öffnungszeit",
        "closeTime": "Geschäftsschluss",
        "rate": "Bearbeitungsgebühr",
        "nightFee": "Übernachtungsgebühr",
        "loss": "Gewinn-und Verlust",
        "setloss": "Legen Sie Take-Profit und Stop-Loss fest",
        "expectProfit": "erwarteter Gewinn",
        "expectLoss": "erwarteter Verlust",
        "allClose": "Schließen Sie alle Positionen",
        "moreClose": "Schließen Sie nur Langbestellungen ab",
        "nullClose": "Schließen Sie nur kurzfristige Bestellungen ab",
        "sureClose": "Bestätigen, um die Position zu schließen?",
        "thanZearo": "Der eingestellte Wert muss größer als 0 sein",
        "listin": "Ausstehende Bestellung",
        "tdin": "in der Transaktion",
        "closein": "Positionsschließung",
        "closed": "Position geschlossen",
        "revoked": "Widerrufen",
        "revokeOrder": "Sind Sie sicher, dass Sie die Bestellung stornieren möchten?",
        "ping": "Geschlossene Position",
        "revoke": "Bestellung stornieren",
        "sureping": "Sind Sie sicher, die Position zu schließen?",
        "thanzone": "Der eingestellte Wert muss größer als 0 sein",
    },
    // 币币
    "cuy": {
        "direction": "Richtung",
        "total": "gesamt",
        "price": "Preis",
        "sell": "Verkaufen",
        "buy": "kaufen",
        "allStation": "Handel mit der gesamten Website",
        "buyPrice": "Kaufpreis",
        "buynum": "Kaufvolumen",
        "sellPrice": "Verkaufspreis",
        "sellnum": "Verkaufsvolumen",
        "tdPrice": "Transaktionsvolumen",
        "or": "oder",
        "tdStart": "Mit dem handel beginnen",
        "pbPrice": "Bitte geben Sie den Kaufpreis ein",
        "pbNum": "Bitte geben Sie den Kaufbetrag ein",
        "psPrice": "Bitte geben Sie den Verkaufspreis ein",
        "psNum": "Bitte geben Sie den Verkaufsbetrag ein",
        "fixPrice": "den Handel einschränken",
        "Mtrans": "Marktpreistransaktion",
        "available": "Verfügbar",
        "revoke": "Stornieren",
        "loading": "Wird geladen",
        "confirmCancel": "Bist du sicher, dass du abbrechen möchtest?",
        "confirm": "Sicher",
        "cancel": "Stornieren",
        "nomore": "Keine Daten mehr",
        "evelPrice": "durchschnittlicher Transaktionspreis",
    },
    // 资产
    "asset": {
        "confirmhuazhuan": "Möchten Sie die Übertragung bestätigen?",
        "assets": "Vermögenswerte",
        "currency_account": "Münzkonto",
        "lever_account": "Vertragskonto",
        "fince_account": "Fiat-Konto",
        "change_account": "Transaktionskonto",
        "micro_account": "zweites Vertragskonto",
        "miscroAccount": "zweites Vertragskonto",
        "assets_hua": "Geldtransfer",
        "all_assets": "Gesamtvermögen umgerechnet",
        "currency": "Währung",
        "canuse": "Verfügbar",
        "frezz": "einfrieren",
        "conversion": "umgewandelt",
        "charging": "Zahlen Sie Münzen ein",
        "withdraw": "Münzen abheben",
        "address_bind": "Verknüpfen Sie die Auszahlungsadresse",
        "address_withdraw": "Verwaltung der Münzabhebungsadresse",

        "record": "Einzelheiten",
        "tbrecord": "Aufzeichnung der Münzabhebung",
        "address_charge": "Einzahlungsadresse",
        "address_width": "Auszahlungsadresse",
        "copy": "Kopieren",
        "ercode": "QR-Code",
        "reminder": "Freundliche Tipps",
        "notcharge": "Bitte überweisen Sie kein Geld an die oben genannte Adresse.",
        "ornone": "andernfalls wird das Vermögen nicht zurückerlangt",
        "support": "Einzahlungen unterstützen nur die einfache Sendemethode (Alles senden) können vorübergehend nicht gutgeschrieben werden.",
        "minnum": "Mindestauszahlungsbetrag",
        "ratenum": "Bearbeitungsgebühr",
        "havenum": "Ankunftsmenge",
        "from": "von",
        "to": "auf",
        "transfer": "überweisen",
        "tfnum": "Menge übertragen",
        "ptfnum": "Bitte geben Sie das Minimum ein",
        "dtfnum": "Die Anzahl der Überweisungen",
        "canbalance": "Verfügbares Guthaben",
        "sureConfirm": "Bestätigen Sie die Übertragung",
        "moneyRecord": "Finanzunterlagen",
        "pNum": "Bitte geben Sie den Überweisungsbetrag ein",
        "leverUse": "Vertragskonten sind verfügbar",
        "legalUse": "Fiat-Konten sind verfügbar",
        "changeUselg": "Münzkonto ist vorhanden",
        "changeUse": "Handelskonten verfügbar",
        "microUse": "Sekundenvertragskonto verfügbar",
        "look": "Überprüfen",
        "chargeRecord": "Einzahlungsprotokoll",
        "status": "Tracking-Status",
        "noopen": "Diese Funktion ist noch nicht verfügbar",
        "enterAdderses": "Bitte geben Sie die Auszahlungsadresse ein",
        "enterNum": "Bitte geben Sie den Auszahlungsbetrag ein",
        "lessMin": "Der eingegebene Auszahlungsbetrag liegt unter dem Mindestwert",
        "tips01": "Bitte hinterlegen Sie keine weiteren Vermögenswerte an der oben genannten Adresse, da die Vermögenswerte sonst nicht abgerufen werden können.",
        "tips02": "Einzahlungen unterstützen nur die einfache Sendemethode (Alles senden) können vorübergehend nicht gutgeschrieben werden.",
        "beizhu": "Anmerkung",
        "pbeizhu": "Bitte geben Sie Anmerkungen ein",
        "add": "Hinzufügen zu",
        "addressList": "Adressliste",
        "delete": "löschen",
        "norec": "Keine Aufzeichnungen",
        "selectCurrency": "Bitte wählen Sie die Währung aus",
        "chainType": "Kettentyp",
        "commissionLibrary": "Kommissionsbibliothek",
    },
    // 账户设置
    "set": {
        "mycode2": "mein Autorisierungscode",
        "copycode2": "Autorisierungscode kopieren",
        "uaccont": "Sicherheitsstufe Ihres Kontos:",
        "strong": "mächtig",
        "middle": "Mitte",
        "weak": "schwach",
        "min": "Niedrig",
        "heigh": "hoch",
        "complete": "Füllen Sie weitere Informationen aus, um die Kontosicherheit zu gewährleisten",
        "mycode": "mein Einladungscode",
        "copycode": "Einladungscode kopieren",
        "bindphone": "Handy binden",
        "bindemail": "E-Mail binden",
        "loginpwd": "Passwort",
        "noBind": "Nicht gebunden",
        "binds": "Bindung",
        "bind": "Gehen Sie zum Binden",
        "binded": "Gebunden",
        "net": "Es besteht die Gefahr, dass Internetkonten gestohlen werden. Es wird empfohlen, Ihr Passwort regelmäßig zu ändern, um die Sicherheit Ihres Kontos zu gewährleisten.",
        "reset": "Überarbeiten",
        "setPsw": "Legen Sie ein Transaktionskennwort fest, um die Transaktionssicherheit zu gewährleisten.",
        "goSet": "Gehe zu den Einstellungen",
        "bindAccount": "Tauschkonto binden",
        "duihuan": "Konto verknüpfen, um Punkte einzulösen",
        "copysuccess": "Erfolgreich kopiert",
        "copyfail": "Der Kopiervorgang ist fehlgeschlagen",
        "recopy": "Bitte noch einmal kopieren",
        "account": "Konto",
        "detail": "Einzelheiten",
        "val": "Menge",
        "forgetPsw": "vergessen Sie das Passwort",
        "code": "Bestätigungscode",
        "getCode": "Holen Sie sich den Bestätigungscode",
        "setPsw": "Passwort festlegen",
        "enterPsw": "Bitte Passwort eingeben",
        "pswFalse": "Die doppelt eingegebenen Passwörter sind inkonsistent",
        "enterPswagain": "Bitte geben Sie das Passwort erneut ein",
        "jsetPsw": "Transaktionspasswort festlegen",
        "jenterPsw": "Bitte geben Sie das Transaktionspasswort ein",
        "jenterPswagain": "Bitte geben Sie das Transaktionspasswort erneut ein",
        "enterAccount": "Bitte geben Sie den Benutzernamen ein",
        "enterCode": "Bitte geben Sie den Bestätigungscode ein",
        "accountFalse": "Das von Ihnen eingegebene Mobiltelefon oder E-Mail-Konto entspricht nicht den Regeln!",
        "second": "Sekunden",
        "sendCode": "Senden Sie den Bestätigungscode",
        "sendsucc": "Erfolgreich gesendet",
        "enterPswTwo": "Bitte geben Sie Ihr Passwort zweimal ein",
        "rest": "Übrig",
        "yes": "Ja",
        "no": "NEIN",
        "yorn": "Ob ein Transaktionskennwort festgelegt werden soll",
        "authenticate": "Bitte führen Sie eine Identitätsprüfung durch",
    },
    // 身份认证
    "auth": {
        "identity": "Authentifizierung",
        "name": "Name",
        "idcard": "AUSWEIS",
        "upimg": "Bitte laden Sie Ausweisfotos hoch. Das erste ist die Vorderseite, das zweite die Rückseite und das dritte ist das vordere Foto mit dem Ausweis.",
        "submit": "einreichen",
        "auditing": "in Prüfung...",
        "certified": "verifiziert!",
        "pname": "Bitte geben Sie Ihren Namen ein",
        "pidcard": "Bitte geben Sie Ihre ID-Nummer ein",
        "pimg": "Bitte laden Sie ein Bild Ihres Ausweises hoch!",
    },
    // 收款方式
    "seting": {
        "pmethod": "Bezahlverfahren",
        "truename": "tatsächlicher Name",
        "opening": "Bank Name",
        "bank": "Bankkartennummer",
        "alipay": "Alipay-Konto",
        "wename": "WeChat-Spitzname",
        "wechat": "WeChat-Konto",
        "pname": "Bitte geben Sie Ihren richtigen Namen ein",
        "popen": "Bitte geben Sie den Namen der Bank ein, bei der das Konto eröffnet wird",
        "pcard": "Bitte geben Sie die Bankkartennummer ein",
        "palipay": "Bitte geben Sie Ihre Alipay-Kontonummer ein",
        "pwname": "Bitte geben Sie Ihren WeChat-Spitznamen ein",
        "pwaccount": "Bitte geben Sie Ihr WeChat-Konto ein",
        "pimg1": "WeChat-Zahlungscode",
        "pimg2": "Alipay-Zahlungscode",
    },
    // 帮助中心
    "news": {
        "seeall": "Alle ansehen",
        "helpcenter": "Ankündigungszentrum",
        "back": "zurückkehren",
    },
    //登录
    "login": {
        "welcome": "Bitte einloggen",
        "phoneLogin": "Melden Sie sich mit dem Telefon an",
        "emailLogin": "E-Mail-Login",
        "account": "Konto",
        "psw": "Passwort",
        "login": "Anmeldung",
        "nouser": "Noch kein WealthNest-Benutzer?",
        "goRegister": "Registrieren Sie sich jetzt und beginnen Sie mit dem Handel auf der weltweit führenden Handelsplattform für digitale Vermögenswerte.",
        "registerFree": "Kostenlose Anmeldung",
        "psw6": "Das Passwort darf nicht weniger als sechs Zeichen lang sein",
    },
    "register": {
        "register": "registrieren",
        "phoneRegister": "Registrieren Sie Ihr Telefon",
        "emailRegister": "E-Mail Registrierung",
        "country": "Staatsangehörigkeit",
        "enterPhone": "Bitte geben Sie die Telefonnummer ein",
        "enterEmail": "Bitte geben Sie Ihre E-Mail-Adresse ein",
        "psw16": "Das Passwort muss zwischen 6 und 16 Zeichen lang sein",
        "inviteCode": "Einladungscode",
        "sel": "Erforderlich",
        "sel2": "Optional",
        "read": "Ich habe gelesen und stimme zu",
        "xieyi": "\"Nutzungsbedingungen\"",
        "tips01": "Die Angaben zur Staatsangehörigkeit können nach der Registrierung nicht mehr geändert werden. Geben Sie diese daher unbedingt wahrheitsgetreu an.",
        "tips02": "Bestätigungs-E-Mails können fälschlicherweise als Spam identifiziert werden. Überprüfen Sie sie daher sorgfältig.",
        "tips03": "Bitte bewahren Sie Ihre WealthNest-Kontonummer und Ihr Login-Passwort ordnungsgemäß auf.",
        "tips04": "Verwenden Sie nicht dasselbe Login-Passwort wie auf anderen Websites.",
        "emailFalse": "Die von Ihnen eingegebene E-Mail entspricht nicht den Regeln",
    },
    // 积分兑换
    "jc": {
        "title": "JC Exchange Digital Assets BEAU-Plattform",
        "balance": "Gleichgewicht",
        "cannum": "Verfügbare JC-Menge",
        "new": "Letzter Preis",
        "exnum": "Tauschmenge",
        "pnum": "Bitte geben Sie die JC-Menge ein",
        "canex": "Menge an BEAU, die eingelöst werden kann",
        "exnow": "Jetzt einlösen",
        "record": "Austauschdatensatz",
        "exprice": "Tauschpreis",
        "extime": "Zeit der Erlösung",
        "not": "Nicht genügend Punkte vorhanden",
        "usenum": "Verwenden Sie die JC-Menge",
        "out": "aufhören",
        "gotd": "geh handeln",
    },
    "coin": {
        "manage": "Finanzverwaltung",
        "total": "Gesamteinzahlung",
        "bao": "Schatz deponieren",
        "mybao": "Meine Anzahlung",
        "profit": "Geschätzter Umsatz",
        "cuntime": "Zeit für die Münzeinzahlung",
        "endtine": "Ablaufdatum",
    },
    "team": {
        "myteam": "mein Team",
        "ztnum": "Anzahl der direkten Empfehlungen",
        "activenum": "Anzahl der aktiven Mitglieder des Teams",
        "profit": "Einkommen",
        "back": "Abzug",
        "cblog": "Einzahlungsprotokoll",
        "bblog": "Münzaufzeichnung",
    },
    // k线
    "kline": {
        "text1": "Zeit teilen",
        "text2": "1 Minute",
        "text3": "5 Minuten",
        "text4": "15 Minuten",
        "text5": "30 Minuten",
        "text6": "1 Stunde",
        "text7": "1 Tag",
        "text8": "1 Woche",
        "text9": "Januar",
    },
    // 秒合约
    "miscro": {
        "trade": "in der Transaktion",
        "buyPrice": "Kaufpreis",
        "finshPrice": "Endgültiger Preis",
        "loss": "Geschätzter Gewinn und Verlust",
        "times": "Countdown",
        "mode": "Handelsmodell",
        "num": "Offene Positionsmenge",
        "rate": "Rentabilität",
        "up": "Aufkaufen",
        "down": "Kaufen oder verkaufen",
        "openNum": "Bitte geben Sie die Öffnungsmenge ein",
        "success": "erfolgreich bestellt!",
        "c2c": "C2C-Konto",
        "complaint": "Vorschläge",
        "reply": "Bitte schreiben Sie Ihre Frage auf und wir werden uns so schnell wie möglich bei Ihnen melden...",
        "complaintList": "Beschwerdevorschlagsliste",
        "complaintReply": "Antwort:",
        "complaintDescription": "Bitte geben Sie eine Beschreibung ein",
        "resetpwd": "Kennwort ändern",
        "foundedOn": "Eingebaut",
        "commonProblem": "häufiges Problem",
        "statement": "erklären",
        "about": "Über WealthNest",
        "everyone": "Jeder ist ein CEO",
        "program": "WealthNest Globales Partnerprogramm",
        "program2": "Partnerprogramm",
        "myMine": "Meine Mine:",
        "friend": "Meine Bergbaufreunde:",
        "myRank": "Mein Level:",
        "accumulated": "Kumulierte Provision:",
        "copyLinks": "Werbelink kopieren",
        "moneyVein": "Verbindungen in Geld verwandeln",
        "example": "Beispiel einer Kommission",
        "enterQuantity": "Bitte Menge eingeben",
        "contractBalance": "Vertragskontostand",
        "submitRepeatedly": "Die Übermittlung läuft, bitte nicht wiederholt einreichen",
        "alipayCode": "Alipay-Zahlungscode",
        "wechatCode": "WeChat-Zahlungscode",
        "realPrice": "Echtzeitpreis",
        "currencyExchange": "Währung einlösen",
        "currencyExchangeIn": "Währung einlösen",
        "cashableBalance": "Zur Einlösung verfügbarer Restbetrag:",
        "minimumCashable": "Mindestrückzahlungsbetrag:",
        "maximumCashable": "Maximale Auszahlung:",
        "automaticallys": "Nachdem Sie auf „Einlösen“ geklickt haben, wird der Vorgang automatisch abgeschlossen und kann nicht zurückgegeben werden.",
        "match": "Austausch",
        "title": "Vermögensaustausch",
        "holdAssets": "Vermögenswerte halten",
        "dangerousCurrency": "Halten Sie Risikomünzen",
        "convertibleQuantity": "Einlösbare Menge",
        "currencyExchanges": "Wechselkurs:",
        "insuranceCurrency": "Versicherungswährung",
        "insuranceType": "Versicherungsart",
        "contractAsset": "Vertragsvermögensversicherungsbetrag",
        "warehouses": "Anzahl der Versicherungslager",
        "availableAssets": "Verfügbare Vermögenswerte:",
        "insuredAssets": "Versicherte Vermögenswerte:",
        "insuredAssets1": "Versicherte Vermögenswerte",
        "insuranceAssets": "Versicherungsvermögen:",
        "purchaseInsurance": "Abonnement für Versicherung",
        "insuranceClaims": "Versicherungsansprüche",
        "insuranceCancellation": "Versicherungskündigung",
        "coinWallet": "Geldbörse für Versicherungsmünzen",
        "bmbWallet": "HAP-Geldbörse",
        "cumulativeCoins": "Angesammelte Münzen:",
        "availableQuantity": "Verfügbare Menge:",
        "rawCurrency": "Münzaufzeichnung",
        "contractInsurance": "Vertragsversicherung",
        "tenThousand": "Zehntausend",
        "runningLow": "Mangelhaftes Gleichgewicht",
        "purchase": "Die von Ihnen eingegebene Menge entspricht nicht den Regeln und Ihr Einkaufslimit liegt innerhalb dieser Grenzen",
        "reach": "zu",
        "between": "zwischen",
        "onlyEnter": "Sie können nur eintreten",
        "integersBetween": "Ganzzahl zwischen",
        "notReturned": "Indem Sie auf „Abschließen einer Versicherung“ klicken, wird davon ausgegangen, dass Sie den Versicherungsvertrag im Detail verstanden haben und zustimmen, dass die Versicherung automatisch in Kraft tritt und nicht zurückgegeben werden kann.",
        "settled": "Wenn der Verlust des versicherten Vermögens die Auftragserteilung unmöglich macht, werden Versicherungsansprüche geltend gemacht",
        "profitable": "Zeiten, in denen der Vermögenswert einen Gewinn erwirtschaftet",
        "terminated": ", wird die Versicherung automatisch gekündigt.",
        "automatically": "Wenn das versicherte Vermögen 50 % verliert, müssen Sie einen Versicherungsanspruch beantragen, andernfalls können Sie nicht handeln. Wenn der Gewinn 100 % beträgt, wird die Versicherung automatisch gekündigt. Das Versicherungslager darf nur zwei Entschädigungen zahlen Bei mehr als zwei Tagen wird die Entschädigung am t+1 ausgezahlt.",
        "termination": "Nach Beendigung des Versicherungsvertrages kann das versicherte Vermögen abgelöst werden. Nach Beendigung des Vertrages gilt dies als Vertragsbruch und das Versicherungslager wird automatisch geräumt.",
        "just": "Nur",
        "back": "Gegenteil",
        "lowestNumber": "Die Mindestmenge beträgt",
        "confirmExchange": "Einlösung bestätigen?",
        "contractValuation": "Vermögensbewertung des Vertragskontos",
        "secondValuation": "Zweite Vermögensbewertung des Vertragskontos",
        "falshValuation": "Sofortige Vermögenswertbewertung des Börsenkontos",
        "c2cValuation": "Bewertung des C2C-Kontovermögens",
        "recordWithdrawal": "Ein- und Auszahlungsaufzeichnungen",
        "category": "Kategorie",
        "safetyCenter": "Sicherheitscenter",
        "safeText1": "Wird verwendet, um Bestätigungs-SMS-Nachrichten bei der Registrierung, beim Ändern von Passwörtern und beim Einrichten der Sicherheit zu erhalten",
        "safeText2": "Es besteht die Gefahr, dass Internetkonten gestohlen werden. Es wird empfohlen, Ihr Passwort regelmäßig zu ändern, um die Sicherheit Ihres Kontos zu gewährleisten.",
        "flashTrading": "Flash-Transaktion",
        "assetCenter": "Asset Center",
        "promotionCode": "Mein Aktionscode",
        "loginAgain": "Login abgelaufen, bitte loggen Sie sich erneut ein",
        "text10": "HAP-basierte Echtzeitpreise",
        "text11": "Autorisierungscode",
    }
}
