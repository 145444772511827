<template>
  <div class>
    <div class="content fColor1">
      <div class="totalcount">{{$t('asset.commissionLibrary')}}：{{amount}}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "commissionlibrary",
  filters: {
    toFixeds: function(value) {
      value = Number(value);
      return value.toFixed(2);
    }
  },
  data() {
    return {
        amount:0
    };
  },
  components: {
  },
  methods: {
    getAccount(){
      this.$ajax({
        url:"/api/user/info",
        method:"get",
        data:{},
        headers: { Authorization: localStorage.getItem("token") },      
      })
      .then(res => {        
          if(res.data.type=='ok'){
           this.amount = res.data.message?.capital_account;
          }
        })
        .catch(error => {
          console.log('error',error)
        });
    }
  },
  created() {
    this.token = localStorage.getItem("token") || "";
  },
  mounted() {
    this.getAccount();
  }
};
</script>
<style scoped lang="scss">
.content {
  width: 100%;
  padding: 50px 20px 0 50px;
  // background: #1a1a1e;
  position: relative;
}
.totalcount{
  
}
</style>
