import { render, staticRenderFns } from "./dealAccount.vue?vue&type=template&id=55c513ae&scoped=true&"
import script from "./dealAccount.vue?vue&type=script&lang=js&"
export * from "./dealAccount.vue?vue&type=script&lang=js&"
import style0 from "./dealAccount.vue?vue&type=style&index=0&id=55c513ae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55c513ae",
  null
  
)

export default component.exports